import IMask from 'imask';

const phoneMask = IMask.createMask({
  mask: '(000) 000-00-00',
});

const rusPhoneMask = IMask.createMask({
  mask: '{+}7 (000) 000-00-00',
});

const emailMask = IMask.createMask({
  mask: /^\S*@?\S*$/,
});
const dateMask = IMask.createMask({
  mask: Date,
  min: new Date(1860, 0, 1),
  max: new Date(),
});
const countMask = IMask.createMask({
  mask: Number,
  min: 0,
});
const countWithoutZeroMask = IMask.createMask({
  mask: Number,
  min: 1,
});
const numberFormatMask = IMask.createMask({
  mask: Number,
  thousandsSeparator: ' ',
});

const combinedPhoneMask = IMask.createMask({
  mask: [
    {
      mask: '+{7} (000) 000-00-00', // Russia, Kazakhstan
      startsWith: '7',
    },
    {
      mask: '+{374} 00-000-000', // Armenia
      startsWith: '374',
      lazy: false,
    },
    {
      mask: '+{375} 00 000-00-00', // Belarus
      startsWith: '375',
      lazy: false,
    },
    {
      mask: '+{86} 000-0000-0000', // China
      startsWith: '86',
      lazy: false,
    },
    // Add more masks for other countries as needed
    {
      mask: '+{00} 000000000000', // Fallback for any country
      startsWith: '',
    },
  ],
  dispatch: function (appended, dynamicMasked) {
    const number = (dynamicMasked.value + appended).replace(/\D/g, '');

    return dynamicMasked.compiledMasks.find(m => number.startsWith(m.startsWith)) || dynamicMasked.compiledMasks[4];
  },
});

// eslint-disable-next-line
const masks: any = {
  phone: phoneMask,
  rusPhone: rusPhoneMask,
  combinedPhone: combinedPhoneMask,
  email: emailMask,
  birth_date: dateMask,
  count: countMask,
  count_without_zero: countWithoutZeroMask,
  number_format: numberFormatMask,
  default: { resolve: (value: string): string => value },
};
export function getMask(type: string) {
  // eslint-disable-next-line
  return masks.hasOwnProperty(type) ? masks[type] : masks.default;
}
