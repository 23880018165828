import React, { useEffect, useRef, useState } from 'react';
import { UseTableRowProps } from 'react-table';

import { AmountInput } from '../components';
import { operationItemType } from '../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { useGetOperationData } from '../hooks';
import { useAppDispatch } from '../../../../../../store/hooks';
import { STARTED_AMOUNT } from '../../../constants';
import { rowWithDeletedOperations, rowWithNewOperation } from './utils';
import { toast } from 'react-hot-toast';
import { useCheckAdvancePayment } from './useCheckAdvancePayment';

interface PaidCellProps {
  row: UseTableRowProps<operationItemType>;
  updatePaymentState: (rows: operationItemType[]) => void;
}

export const Paid = ({ row, updatePaymentState }: PaidCellProps) => {
  const dispatch = useAppDispatch();
  const operationItems = useSelector((state: RootState) => state.PaymentAccountSlice.operationItems);

  const { amountForPay, currentRowId, payment, operationId, payment_type, isNew } = useGetOperationData(row);
  const { advanceAmount, payByAdvance, setAdvanceAmount } = useCheckAdvancePayment(payment_type);

  const [inputValue, setInputValue] = useState<number>(payment);

  const amountError = inputValue > amountForPay;
  const advanceError =
    payByAdvance &&
    // чтобы ошибка не показывалась после списания суммы с аванса
    inputValue !== payment &&
    inputValue > advanceAmount;
  const errorMessage = amountError ? 'Сумма больше необходимой' : 'Сумма больше чем в авансе';

  const amountInputRef = useRef<{ blur: () => void }>(null);

  const updateOperationPaidValue = (rowId: number, operationId: string | number, paidAmount: number) => {
    const updatedRowsState = operationItems.map((row) => {
      if (row.invoice_row !== rowId) return row;

      const currentOperation = row.operations.find((operation) => operation.id === operationId);

      if (currentOperation?.payment > STARTED_AMOUNT && currentOperation?.payment !== paidAmount) {
        return rowWithDeletedOperations(row);
      }

      return rowWithNewOperation(row, currentOperation, paidAmount);
    });

    updatePaymentState(updatedRowsState);
  };

  const getNewAdvanceAmount = () => {
    // если уменьшили ранее внесенную сумму аванса
    if (payment > inputValue) {
      return advanceAmount + (payment - inputValue);
    }
    // если увеличили ранее внесенную сумму аванса или оставили прежней
    if (inputValue >= payment) {
      return advanceAmount - inputValue;
    }
    return advanceAmount;
  };

  const onBlur = () => {
    if (!amountError && !advanceError && inputValue !== payment) {
      if (payByAdvance && advanceAmount >= inputValue) {
        const newAdvanceAmount = getNewAdvanceAmount();
        dispatch(setAdvanceAmount(newAdvanceAmount));
        updateOperationPaidValue(currentRowId, operationId, inputValue);
        return;
      } else {
        updateOperationPaidValue(currentRowId, operationId, inputValue);
      }
    }
  };

  const onChange = (value: number) => {
    setInputValue(payment ? 0 : value);
  };

  useEffect(() => {
    if (payment && inputValue !== payment) {
      amountInputRef.current.blur();
      toast('Список операций сброшен к первоначальному. Необходимо ввести данные повторно.');
    }
  }, [inputValue, payment]);

  if (!isNew) {
    return <>{payment}</> || null;
  }

  return (
    <AmountInput
      ref={amountInputRef}
      value={inputValue}
      onChange={onChange}
      error={amountError || advanceError}
      errorMessage={errorMessage}
      onBlur={onBlur}
    />
  );
};
