import React from 'react';
import styled from 'styled-components';
import { TextEllipsis } from './TextEllipsis';
import { MAX_SYMBOLS } from '../Jornal/JornalTextItem';

const TextLongField = styled.div<{ width?: number; focused: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: ${({ focused }) => `1px solid ${focused ? '#4555e4' : '#D3D6DA'}`};
  border-radius: 4px;
  padding: 8px;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  min-height: 40px;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #515d6b;
  word-break: break-word;

  &:hover {
    border: 1px solid #577bf9;
  }
`;

type Props = {
  text: string;
  focused: boolean;
  width?: number;
  setEditMode: (mode: boolean) => void;
};

export function TextLong({ text, width, focused, setEditMode }: Props) {
  return (
    <TextLongField className="textLong" width={width} focused={focused} onClick={() => setEditMode(true)}>
      <TextEllipsis text={text} max={MAX_SYMBOLS} />
    </TextLongField>
  );
}
