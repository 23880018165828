import React, { useState } from 'react';
import _ from 'lodash';
import {
  FilterLayout,
  JobLabel,
  JobTitle,
  SearchField,
  TextCutOFF,
} from '../../../../../../components/DoctorFilter/styles';
import SearchInput from '../../../../../../components/DoctorFilter/SearchInput';
import { OnTopTooltip } from '../../../../../../components/DoctorFilter/OnTopTooltip';
import EmptyList from '../../../../../../components/DoctorFilter/EmptyList';
import { UserRole } from '../../../../../../services/users';

function DoctorFilter({ doctors, pickedDoctorId, togglePopper, type = 'default' }) {
  const [searchValue, searchDoctor] = useState('');

  const staffersByJob = _(doctors)
    .filter((doctor) => doctor.is_active)
    .filter((doctor) => (type === 'all' ? true : doctor.role === UserRole.Doctor))
    .filter((doctor) => (type === 'all' ? true : doctor.job !== 'Администратор'))
    .filter(
      (doctor) =>
        _.startsWith(_.toLower(doctor.first_name), _.toLower(searchValue)) ||
        _.startsWith(_.toLower(doctor.second_name), _.toLower(searchValue)) ||
        _.startsWith(_.toLower(doctor.last_name), _.toLower(searchValue)) ||
        _.startsWith(_.toLower(doctor.job), _.toLower(searchValue)),
    )
    .groupBy('job')
    .value();

  const jobs = _.keys(staffersByJob);
  const isEmptyList =
    _(doctors)
      .filter((doctor) => (type === 'all' ? true : doctor.role === UserRole.Doctor))
      .value().length === 0;

  const handleLabelClick = (doctor) => {
    togglePopper(doctor.id);
  };

  const handleInput = (e) => {
    searchDoctor(e.target.value);
  };

  return !isEmptyList ? (
    <FilterLayout>
      <SearchField>
        <SearchInput onChange={handleInput} />
      </SearchField>
      {jobs.map((job, index) => (
        <div id={'area-' + index} key={`area_${index}`}>
          <JobTitle
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            noSelect
          >
            {job}
          </JobTitle>
          {staffersByJob[job].map((doctor) => (
            <OnTopTooltip
              key={`topTooltip_${index}`}
              title={`${doctor.last_name} ${doctor.first_name} ${doctor.second_name}`}
            >
              <JobLabel
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                picked={pickedDoctorId === doctor.id}
                onClick={() => handleLabelClick(doctor)}
              >
                <TextCutOFF>{doctor.fullname}</TextCutOFF>
              </JobLabel>
            </OnTopTooltip>
          ))}
        </div>
      ))}
    </FilterLayout>
  ) : (
    <FilterLayout>
      <EmptyList isWorking={isEmptyList} deskription={'Чтобы создать сотрудников перейдите на страницу'} />
    </FilterLayout>
  );
}

export default DoctorFilter;
