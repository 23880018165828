import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';

import Header from '../../components/Header/TasksHeader';
import Drawer from '../../components/Drawer';
import { StaffTable } from '../../features/staff';
import { routerConfig } from '../../navigation/routerConfig';

import { initStaffPage } from './operations';
import StaffPageState from './state';
import { PAGE_PADDING } from './style';
import Switch from '../../uikit/Switch';
import { useGetPermissionsQuery } from '../../services/users';
import { RootState } from '../../store';

const Page = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #f9f9f9;
  padding-right: 45px;
  height: 100vh;
  overflow: hidden;
  left: 2.5%;
  position: relative;
`;
const Layout = styled.div`
  width: 100%;
  min-width: 1024px;
  max-width: 1444px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 50px;
`;
const Box = styled.div`
  width: 100%;
  min-width: 1154px;
  max-width: 1444px;
  margin-top: 16px;
  background: #ffffff;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.08), 0 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  height: calc(100vh - 96px);
`;

type StaffPageProps = {
  common: RootState['common'];
  initStaffPage: () => Promise<void>;
  dropState: () => void;
};

const StaffPage: React.FC<StaffPageProps> = ({ common, initStaffPage, dropState }) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { data: permissions } = useGetPermissionsQuery(common.user.id);

  useEffect(() => {
    initStaffPage();
    return () => {
      dropState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <div
        style={{
          width: '100vw',
          paddingLeft: PAGE_PADDING,
          paddingRight: PAGE_PADDING,
        }}
      >
        <Header text={routerConfig.STAFF.title} />
        <Drawer />
      </div>
      <Layout>
        <Scrollbars
          style={{
            width: '100%',
            height: '100vh',
          }}
        >
          <Box>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: 40,
              }}
            >
              {permissions['edit_staff'] && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: 10,
                    marginRight: 40,
                    marginBottom: 16,
                  }}
                >
                  <div style={{ fontSize: '14px', color: 'rgb(168, 174, 181)' }}>Режим редактирования</div>
                  <Switch
                    checked={isEditMode}
                    // bug material ui switch: not working onChange
                    onClick={() => {
                      setIsEditMode(!isEditMode);
                    }}
                  />
                </div>
              )}

              <StaffTable isEditMode={isEditMode} />
            </div>
          </Box>
        </Scrollbars>
      </Layout>
    </Page>
  );
};

export default connect(
  (state: RootState) => ({
    common: state.common,
  }),
  {
    initStaffPage,
    dropState: StaffPageState.actions.dropState,
  },
)(StaffPage);
