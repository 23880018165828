import React, { ChangeEvent, MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { pipe } from 'ramda';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useAutocomplete } from '@material-ui/lab';
import { ClickAwayListener } from '@material-ui/core';

import TextInput from '../../../../../uikit/TextInput';
import { Modal } from '../../../../../uikit/Modal';
import { ValidationMessage } from '../../../../../uikit/ValidationMessage';
import { ListBox } from './ListBox';
import { DictionaryType, PayerCodes, PaymentTypeCodes } from '../../../../../services/dictionaries';
import { EMPTY } from '../../constants';
import { ModalWrapper } from '../ModalWrapper';

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      color: '#515D6B',
      height: 16,
      width: 16,
      right: 4,
      top: 4,
      position: 'absolute',
      userSelect: 'none',
    },
    moreIcon: {
      pointerEvents: 'none',
    },
  }),
);

export type SelectItem = {
  id: number;
  code?: string;
  title: string;
};

type Props = {
  placeholder?: string;
  baseValue?: number | string;
  editable?: boolean;
  onSetValue?: (value) => void;
  items: DictionaryType[];
  onAddItem?: (data: Partial<DictionaryType>) => void;
  onEditItem?: (value) => void;
  onDeleteItem?: (id) => void;
  rowId?: number;
  property?: string;
  listBoxWidth?: number;
  paidAmount?: number;
  maxLength?: number;
  isPayerInput?: boolean;
  rowPaymentType?: string;
  errorCondition?: boolean;
  setErrorCondition?: (value: boolean) => void;
  onSetEmptyPaymentTypeRow?: (value: number) => void;
  paymentTypeId?: number | string;
  operationId?: string | number;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
};

export const BorderAutocomplete = ({
  items,
  onSetValue,
  baseValue,
  editable = false,
  maxLength = 256,
  placeholder = EMPTY,
  onAddItem,
  onEditItem,
  onDeleteItem,
  rowId,
  listBoxWidth,
  isPayerInput,
  rowPaymentType,
  errorCondition,
  setErrorCondition,
  onSetEmptyPaymentTypeRow,
  operationId,
  containerRef,
}: Props) => {
  const classes = useStyles();

  const [itemsList, setItemsList] = useState<DictionaryType[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [newInputValue, setNewInputValue] = useState<string>(EMPTY);
  const [anchorModalError, setAnchorModalError] = useState<null | HTMLElement>(null);

  const setErrorModal = useCallback(() => {
    if (errorCondition && operationId) {
      const anchorElement = document.getElementById(String(operationId));
      setAnchorModalError(anchorElement);
    }
  }, [errorCondition, setAnchorModalError, operationId]);

  const getCurrentPayerType = () => {
    const individualTypes = new Set([
      `${PaymentTypeCodes.BANK}`,
      `${PaymentTypeCodes.CARD}`,
      `${PaymentTypeCodes.CASH}`,
    ]);
    const insuranceTypes = new Set([`${PaymentTypeCodes.INS_REQ}`, `${PaymentTypeCodes.INS_VOL}`]);
    if (!rowPaymentType) return '';
    if (rowPaymentType === PaymentTypeCodes.PREPAID) return PayerCodes.PATIENT;
    if (individualTypes.has(rowPaymentType)) return PayerCodes.INDIVIDUAL;
    if (insuranceTypes.has(rowPaymentType)) return PayerCodes.INSURANCE;
  };

  const addNewItem = (itemTitle: string) => {
    const payerData = { title: itemTitle, payer_type: getCurrentPayerType() };
    const paymentTypeData = { title: itemTitle };
    const newItem = isPayerInput ? payerData : paymentTypeData;
    onAddItem?.(newItem)
      .unwrap()
      .then((value) => onSetValue(value.id));
  };

  const handleAddItem = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const existedItem = items.find((i) => i.title.toLowerCase().startsWith(value?.toLowerCase()));
    if (value && !existedItem) {
      addNewItem(value);
    }
  };

  const deleteItem = (id: number) => {
    onDeleteItem?.(id)
      .unwrap()
      .then(() => id === baseValue && onSetValue(null));
  };

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
    setErrorModal();
  }, [setAnchorEl, setErrorModal]);

  const handleChangeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    if (!rowPaymentType && isPayerInput && onSetEmptyPaymentTypeRow) {
      setAnchorEl(null);
      onSetEmptyPaymentTypeRow(rowId);
      return;
    }
    if (event?.type === 'change') {
      const value = event.target.value;
      setNewInputValue(value);
      const filteredItems = items.filter((i) => i.title.toLowerCase().startsWith(value?.toLowerCase()));
      setItemsList(filteredItems);
      setAnchorModalError(null);
    }
  };

  const checkCurrentPayer = (payerType: string) => {
    if (payerType) {
      const currentPayer = items.find((item) => item.id === baseValue);
      if (!currentPayer || payerType === currentPayer?.payer_type) return;
      if (payerType === PayerCodes.INDIVIDUAL) {
        if (currentPayer?.payer_type === payerType || currentPayer?.payer_type === PayerCodes.PATIENT) {
          return;
        }
      }
      onSetValue(null);
    }
  };

  const getFilteredItems = (currentPayerType: string) => {
    if (!currentPayerType) return items;
    return items.filter((item) => {
      if (currentPayerType === PayerCodes.INDIVIDUAL) {
        if (item.payer_type === currentPayerType || item.payer_type === PayerCodes.PATIENT) {
          return item;
        }
      }
      return item.payer_type === currentPayerType;
    });
  };

  const disablePayerInput = () => {
    const defaultPayer = items.find((item) => item.code === PayerCodes.PATIENT);
    return rowPaymentType === PaymentTypeCodes.PREPAID && defaultPayer?.id === baseValue;
  };

  // убираем комбинированный тип оплаты из списка к выбору
  const listOptions = useMemo(() => itemsList.filter(({ code }) => code !== PaymentTypeCodes.COMBINED), [itemsList]);

  const { getInputProps, getOptionProps, groupedOptions } = useAutocomplete({
    disableCloseOnSelect: true,
    options: listOptions,
    open: Boolean(anchorEl),
    getOptionLabel: (option) => option.title,
    onOpen: (event: React.MouseEvent<HTMLElement>) => {
      setItemsList(!isPayerInput ? items : pipe(getCurrentPayerType, getFilteredItems));
      setAnchorEl(event.currentTarget);
      if (anchorModalError) {
        setAnchorModalError(null);
        onSetEmptyPaymentTypeRow && onSetEmptyPaymentTypeRow(null);
      }
    },
    onChange: (_, value) => {
      onSetValue(value.id);
      if (setErrorCondition) {
        setAnchorEl(null);
        setErrorCondition(false);
        return;
      }
      handleCloseMenu();
    },
  });

  useEffect(() => {
    const baseValueTitle = items.find((item) => item.id === baseValue)?.title;
    if (baseValueTitle) setNewInputValue(baseValueTitle);
  }, [baseValue, items]);

  useEffect(() => {
    if (!newInputValue && anchorEl) return;
    setErrorModal();
  }, [newInputValue, anchorEl, setErrorModal]);

  useEffect(() => {
    if (isPayerInput && rowPaymentType) {
      const currentPayerType = getCurrentPayerType();
      const filteredItemsByPayerType = getFilteredItems(currentPayerType);
      setItemsList(filteredItemsByPayerType);
      checkCurrentPayer(currentPayerType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowPaymentType, isPayerInput]);

  return (
    <ClickAwayListener onClickAway={handleCloseMenu}>
      <div>
        <div style={{ position: 'relative' }} id={`${operationId}`}>
          <TextInput
            className="autocomplete-input"
            {...getInputProps()}
            style={{
              height: 24,
              fontSize: 12,
              paddingRight: 16,
              paddingLeft: 4,
              maxLength: maxLength,
              cursor: 'pointer',
              textOverflow: 'ellipsis',
            }}
            disabled={disablePayerInput()}
            error={errorCondition}
            placeholder={placeholder}
            value={newInputValue}
            onChange={handleChangeInputValue}
            onBlur={handleAddItem}
          />
          {Boolean(anchorEl) ? (
            <ExpandLessIcon className={classes.icon} onClick={handleCloseMenu} />
          ) : (
            <ExpandMoreIcon className={`${classes.icon} ${classes.moreIcon}`} />
          )}
        </div>
        <Modal anchor={anchorModalError} placementModal={'bottom-start'} unSetContainer={true} zIndex={1400}>
          <ValidationMessage arrowMarginLeft={15} arrowMarginRight={137}>
            Обязательное поле
          </ValidationMessage>
        </Modal>
        <ModalWrapper anchor={anchorEl} container={containerRef?.current} placement={'bottom-start'}>
          <ListBox
            getOptionProps={getOptionProps}
            groupedOptions={groupedOptions}
            editable={editable}
            listBoxWidth={listBoxWidth}
            maxLength={maxLength}
            onEditItem={onEditItem}
            onDeleteItem={deleteItem}
          />
        </ModalWrapper>
      </div>
    </ClickAwayListener>
  );
};
