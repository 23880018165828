import React, { useEffect } from 'react';
import BlueButton from '../../../../../uikit/Button/BlueButton';
import styled from 'styled-components';
import { getMask } from '../../../../../utils/masks';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useGetAdvanceTotalQuery } from '../../../../../services/advance';
import { useAppDispatch } from '../../../../../store/hooks';
import PaymentAccountSlice from '../../state';

type AdvanceValueType = {
  positiveBalance: boolean;
};

const PrepaymentContainer = styled.div`
  background: #ffffff;
  border: 1px solid rgba(239, 240, 243, 0.7);
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 144px;
  padding: 8px 14px;
  margin-right: 16px;
  font-weight: 400;
`;

const PrepaymentTitle = styled.div`
  font-size: 12px;
  line-height: 120%;
  color: #515d6b;
`;

const PrepaymentValue = styled.div<AdvanceValueType>`
  font-size: 16px;
  line-height: 120%;
  color: ${({ positiveBalance }) => (positiveBalance ? '#0D853D' : '#a8aeb5')};
`;
type PrepaymentProps = {
  onSetIsBalanceChanging: (condition: boolean) => void;
  patientId: number;
  editMode?: boolean;
};
export function Prepayment({ editMode = false, patientId, onSetIsBalanceChanging }: PrepaymentProps) {
  const dispatch = useAppDispatch();
  const advanceAmount = useSelector((state: RootState) => state.PaymentAccountSlice.advanceAmount);
  const { data: advanceTotal } = useGetAdvanceTotalQuery(patientId, { refetchOnMountOrArgChange: true });
  const { setAdvanceAmount } = PaymentAccountSlice.actions;

  const handleAddPrepay = () => {
    onSetIsBalanceChanging(true);
  };

  useEffect(() => {
    dispatch(setAdvanceAmount(advanceTotal));
  }, [advanceTotal, dispatch, setAdvanceAmount]);

  return (
    <PrepaymentContainer>
      <BlueButton width={22} height={22} style={{ marginTop: 0 }} disabled={editMode} onClick={handleAddPrepay}>
        <img src="./img/white/plus.svg" alt="Добавить аванс" />
      </BlueButton>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 14 }}>
        <PrepaymentTitle>Аванс</PrepaymentTitle>
        <PrepaymentValue positiveBalance={advanceTotal > 0}>
          {getMask('number_format').resolve(`${advanceAmount}`)} ₽
        </PrepaymentValue>
      </div>
    </PrepaymentContainer>
  );
}
