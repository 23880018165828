import * as yup from 'yup';

import { parse, isValid } from 'date-fns';

type ValidationSchema = { phone?: RegExp; birth_date?: RegExp}

const validation = {
    phone: /^\(\d{3}\) \d{3}-\d{2}-\d{2}$/,
    birth_date: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
}

export const patientFormSchema = (pattern: ValidationSchema = validation) => yup.object().shape({
  first_name: yup.string().required('Введите имя'),
  last_name: yup.string().required('Введите фамилию'),
  phone: yup
    .string()
    .required('Введите номер телефона')
    .matches(pattern.phone, 'Некорректный номер телефона'),
  birth_date: yup
    .string()
    .required('Введите дату рождения')
    .matches(pattern.birth_date, 'Некорректная дата рождения')
    .test('valid-date', 'Invalid birth date', function (value) {
      const parsedDate = parse(value, 'dd.MM.yyyy', new Date());
      return isValid(parsedDate);
    }),
    email: yup.string().email('Некорректный e-mail'),
});

