import { useMemo } from 'react';
import { groupBy } from 'lodash';
import { InvoiceRowType, useGetInvoiceRowsQuery } from '../../../../../services/invoice';
import { operationItemType } from '../../types';

export const useGetTableRows = (currentSubInvoiceId: number, operationItems: operationItemType[]) => {
  const { data: invoiceRows = [] } = useGetInvoiceRowsQuery(currentSubInvoiceId, { refetchOnMountOrArgChange: true });

  return useMemo(() => {
    const rowsWithSubRows = operationItems
      .filter(({ sub_invoice_id }) => currentSubInvoiceId === sub_invoice_id)
      .map((row) => {
        const rowComments = invoiceRows.find((item) => row.id === item.id)?.comments ?? [];

        return {
          ...row,
          comments: rowComments,
          subRows: row?.operations?.slice(1),
          ...(row?.operations?.length < 2 && { subRows: row?.operations?.slice(1) }),
        };
      });

    const groupedRows = groupBy(rowsWithSubRows, 'type');
    const rowsByPlan = groupBy(groupedRows[InvoiceRowType.TREATMENT_PLAN], 'externalEntry.id');

    return Object.assign(rowsByPlan, { rowsByPrice: groupedRows[InvoiceRowType.PRICE] });
  }, [operationItems, invoiceRows, currentSubInvoiceId]);
};
