import React, { useCallback, useEffect, useState } from 'react';
import { groupBy, orderBy } from 'lodash';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars-2';
import TextInput from '../../../../../uikit/TextInput';
import { getMask } from '../../../../../utils/masks';
import { JournalAccountFieldItem } from './JournalAccountFieldItem';
import Column from '../../../../PatientPage/components/Column';
import styled from 'styled-components';
import { SearchHeader } from '../../../Template/SearchHeader';
import { BorderSelect } from '../../../Template/Select';
import { InvoiceRowType } from '../../../../../services/invoice';
import {
  ColumnWidth,
  DiscountTypeItems,
  DiscountTypeValues,
  TreatmentPlanItem,
  useStyles,
} from '../../../Template/TreatmentPlan/TreatmentPlanJournalField';
import { useLatestRef } from '../../../../../hooks/useLatestRef';
import { __, any, filter, gt, pipe } from 'ramda';
import { getDiscountSum, getItemDiscount, overDiscount, readOnlyDiscount, toNumberFormat } from './utils';
import { toast } from 'react-hot-toast';

export type InvoiceHistory = {
  code: string;
  count: number;
  paid: number;
};

type Props = {
  data: TreatmentPlanItem[];
  invoiceHistory: InvoiceHistory[];
  readOnly?: boolean;
  selected?: boolean;
  isAddAccount?: boolean;
  totalDiscount?: number;
  onRowDelete: (template) => void;
  onRowUpdate?: (template) => void;
  hasTotalTitle?: boolean;
  canFocused?: boolean;
  setFocus?: VoidFunction;
};

const TreatmentPlanJournalFieldTitle = styled.div`
  background: #f9f9f9;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #515d6b;
  padding: 8px 16px;
  border-bottom: 1px solid #d9d9d9;
`;

export function JournalAccountField({
  data,
  invoiceHistory,
  readOnly = false,
  selected = false,
  onRowDelete,
  onRowUpdate,
  hasTotalTitle = false,
  canFocused = false,
  setFocus,
}: Props) {
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [totalDiscountType, setTotalDiscountType] = useState<keyof typeof DiscountTypeValues>(DiscountTypeValues.RUB);
  const [totalDisabled, setTotalDisabled] = useState<boolean>(false);

  const [dataDiscountFieldsDisabled, setDataDiscountFieldsDisabled] = useState<boolean>(false);

  const groupedData = groupBy(data, 'type');
  groupedData[InvoiceRowType.TREATMENT_PLAN] = groupBy(groupedData[InvoiceRowType.TREATMENT_PLAN], 'externalEntry.id');

  const classes = useStyles({ selected: canFocused && selected });

  const totalCount = data.reduce((prev, current) => prev + current.count, 0);
  const totalPrice = data.reduce((prev, current) => prev + current.count * current.price, 0);
  const totalPayment = data.reduce((prev, current) => prev + (current.payment - current.paid), 0);

  // суммарная скидка по позициям в рублях
  const discountSum = getDiscountSum(data);
  const discountSumRef = useLatestRef(discountSum);

  // обновляем discount при первом рендере или при добавлении/удалении позиций
  useEffect(() => {
    if (data.length) {
      setTotalDiscount(discountSumRef.current);
      setDataDiscountFieldsDisabled(Boolean(discountSumRef.current));
    }
  }, [data.length, discountSumRef]);

  const getItemsDiscount = useCallback(
    (totalDiscount: number) => {
      const totalPrice = data.reduce((prev, current) => prev + current.count * current.price, 0);
      if (!totalPrice) {
        return;
      }
      const percent = (totalDiscount * 100) / totalPrice;
      const discounts = orderBy(data, (d) => d.price * d.count).reduce(
        (prev, current) => ({ ...prev, [current.id]: Math.trunc(current.price * current.count * percent * 0.01) }),
        {},
      );
      const sumDiscounts = Object.values(discounts).reduce((prev, value) => prev + value, 0);
      const discountKeys = Object.keys(discounts);
      for (let i = 0; i < totalDiscount - sumDiscounts; i++) {
        const index = i < discountKeys.length - 1 ? i : i - discountKeys.length;
        discounts[discountKeys[index]] = discounts[discountKeys[index]] + 1;
      }
      return discounts;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalDiscount, data.length],
  );

  const getDiscount = (itemId, totalDiscount, totalDiscountType) => {
    return totalDiscountType === DiscountTypeValues.PERCENT ? totalDiscount : getItemsDiscount(totalDiscount)[itemId];
  };

  const textColumnWidth = readOnly ? ColumnWidth.text + 40 : ColumnWidth.text;

  // функция обновления позиции, передается в JournalAccountFieldItem
  const setItem = (item: TreatmentPlanItem) => {
    if (!readOnly) {
      onRowUpdate?.(item);

      // сумма скидки без редактируемой позиции
      const restItemsDiscount = pipe(
        filter(({ id }) => id !== item.id),
        getDiscountSum,
      )(data);

      const currentDiscount = getItemDiscount(item);

      // проверяем, что в редактируемой позиции или в оставшихся есть значение скидки
      const disabledTotal = any(gt(__, 0))([restItemsDiscount, currentDiscount]) && !dataDiscountFieldsDisabled;

      setTotalDiscount(restItemsDiscount + currentDiscount);
      setTotalDisabled(disabledTotal);
      if (disabledTotal) {
        setTotalDiscountType(DiscountTypeValues.RUB);
      }
    }
  };

  // обновляет скидку по всем позициям в счете при изменении в итоговом поле
  const updateData = (totalDiscount: number, totalDiscountType: string) => {
    data.forEach((item) => {
      const discount = totalDiscount > 0 ? getDiscount(item.id, totalDiscount, totalDiscountType) : 0;
      onRowUpdate?.({
        ...item,
        discount: discount,
        discountType: totalDiscountType,
      });
    });
  };

  const onChangeTotalDiscount = ({ discountValue, totalDiscountType }) => {
    const discountValueExceeded = overDiscount(discountValue, totalPrice, totalDiscountType);

    if (discountValueExceeded) {
      toast.error('Сумма скидки не может быть больше 100%');
    } else {
      updateData(discountValue, totalDiscountType);
      setTotalDiscount(discountValue);
      setDataDiscountFieldsDisabled(Boolean(discountValue));
    }
  };

  const onChangeTotalDiscountType = (selectedDiscountType: keyof typeof DiscountTypeValues) => {
    // запускаем update только если тип изменился
    if (selectedDiscountType !== totalDiscountType) {
      const percentType = selectedDiscountType === DiscountTypeValues.PERCENT;

      if (percentType && totalDiscount > 100) {
        toast.error('Необходимо указать корректную сумму в процентах');
      } else {
        const totalDiscountValue = percentType ? totalDiscount : discountSumRef.current;

        updateData(totalDiscountValue, selectedDiscountType);
        setTotalDiscountType(selectedDiscountType);

        // если выбрали рубли, пересчитываем текущую сумму в процентах в поле ввода
        if (!percentType) {
          setTotalDiscount(discountSumRef.current);
        }
      }
    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        onClick={() => canFocused && setFocus()}
        title={
          <div className={classes.headerContent}>
            <SearchHeader
              type="text"
              placeholder="Код"
              name="code"
              width={94}
              fontSize={12}
              text="Код"
              noBorder
              searchable={false}
            />
            <SearchHeader
              type="text"
              placeholder="Наименование"
              fontSize={12}
              name="text"
              width={textColumnWidth}
              text="Наименование"
              noBorder
              searchable={false}
            />
            <SearchHeader
              type="text"
              placeholder="Кол-во"
              fontSize={12}
              name="count"
              width={55}
              text="Кол-во"
              noBorder
              searchable={false}
            />
            <SearchHeader
              type="text"
              placeholder="Цена"
              fontSize={12}
              name="price"
              width={66}
              text="Цена"
              noBorder
              searchable={false}
            />
            <SearchHeader
              type="text"
              placeholder="Скидка"
              fontSize={12}
              name="discount"
              width={108}
              text="Скидка"
              noBorder
              searchable={false}
            />
            <SearchHeader
              type="text"
              placeholder="К оплате"
              fontSize={12}
              name="payment"
              width={72}
              text="К оплате"
              noBorder
              searchable={false}
            />
          </div>
        }
      />
      <Scrollbars
        autoHeight
        autoHeightMax={600}
        style={{
          width: '100%',
        }}
      >
        <CardContent className={classes.content}>
          {Object.entries(groupedData[InvoiceRowType.TREATMENT_PLAN])?.map(([, items]) => (
            <>
              <TreatmentPlanJournalFieldTitle>
                {`План лечения: ${items[0].externalEntry.doctor.last_name} ${
                  items[0].externalEntry.doctor.first_name[0]
                }. ${items[0].externalEntry.doctor.second_name[0]}.
                ${
                  items[0].externalEntry.tooth_index
                    ? `${items[0].externalEntry.tooth_index} ${items[0].externalEntry.tooth_state.title_russian}`
                    : items[0].externalEntry.tooth_state_label
                }`}
              </TreatmentPlanJournalFieldTitle>
              {items?.map((item) => (
                <JournalAccountFieldItem
                  key={item.id}
                  item={item}
                  invoiceHistoryItem={invoiceHistory.find((h) => h.code === item.code)}
                  setItem={setItem}
                  textColumnWidth={textColumnWidth}
                  readOnly={readOnly}
                  onRowDelete={onRowDelete}
                  discountDisabled={dataDiscountFieldsDisabled}
                />
              ))}
            </>
          ))}
          {groupedData[InvoiceRowType.PRICE]?.length && (
            <>
              <TreatmentPlanJournalFieldTitle>Прейскурант</TreatmentPlanJournalFieldTitle>
              {groupedData[InvoiceRowType.PRICE].map((item) => (
                <JournalAccountFieldItem
                  key={item.id}
                  item={item}
                  setItem={setItem}
                  textColumnWidth={textColumnWidth}
                  readOnly={readOnly}
                  onRowDelete={onRowDelete}
                  discountDisabled={dataDiscountFieldsDisabled}
                />
              ))}
            </>
          )}
          <div className={classes.footerContent}>
            <Column
              name="text"
              defaultValue=""
              fontSize={12}
              width={94}
              marginRight={textColumnWidth}
              isEditing={false}
              textAlign={hasTotalTitle ? 'right' : 'left'}
              hasAction
              fontWeightBold
            >
              <>{hasTotalTitle ? 'Итого:' : 'Стоимость:'}</>
            </Column>
            <Column
              name="count"
              defaultValue=""
              width={55}
              fontSize={12}
              paddingLeft={readOnly ? 16 : 24}
              isEditing={false}
              hasAction
              fontWeightBold
            >
              {toNumberFormat(totalCount)}
            </Column>
            <Column name="price" defaultValue="" width={66} fontSize={12} isEditing={false} hasAction fontWeightBold>
              {toNumberFormat(totalPrice)}
            </Column>
            <Column
              name="discount"
              defaultValue=""
              width={108}
              fontSize={12}
              isEditing={false}
              hasAction
              fontWeightBold
            >
              <>
                {readOnly ? (
                    readOnlyDiscount(totalDiscount, totalDiscountType)
                ) : (
                  <div style={{ display: 'flex' }}>
                    <TextInput
                      className={classes.discountSum}
                      value={totalDisabled ? toNumberFormat(discountSum) : totalDiscount}
                      style={{ height: 22, width: 47, fontSize: 12 }}
                      disabled={totalDisabled}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        onChangeTotalDiscount({
                          discountValue: +getMask('count').resolve(`${+e.target.value}`),
                          totalDiscountType,
                        })
                      }
                    />
                    <BorderSelect
                      className={classes.discountType}
                      value={totalDiscountType}
                      disabled={totalDisabled}
                      setValue={onChangeTotalDiscountType}
                      items={DiscountTypeItems}
                    />
                  </div>
                )}
              </>
            </Column>
            <Column
              name="payment"
              defaultValue=""
              width={72}
              fontSize={12}
              isEditing={false}
              hasAction
              fontWeightBold
              color={readOnly ? '#515d6b' : '#E54770'}
            >
              {toNumberFormat(totalPayment)}
            </Column>
          </div>
        </CardContent>
      </Scrollbars>
    </Card>
  );
}
