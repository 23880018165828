import React, { useState } from 'react';
import { InputBlock, InputBlockContent, InputContainer, Label } from '../style';
import IMask from 'imask';

const phoneMask = IMask.createMask({
  mask: '{+}7 (000) 000-00-00',
});
const emailMask = IMask.createMask({
  mask: /^\S*@?\S*$/,
});
const dateMask = IMask.createMask({
  mask: Date,
  min: new Date(1860, 0, 1),
  max: new Date(),
});
const masks = {
  phone: phoneMask,
  email: emailMask,
  date: dateMask,
  default: { resolve: (value: string): string => value },
};
function TextBlock({
  type = 'default',
  width,
  label,
  placeholder,
  initialValue,
  handleBlur,
  name,
  maxLength,
  InputComponent,
  disabled = false,
}) {
  const [value, setValue] = useState(initialValue);
  const inputMask = masks[type];
  function handleChange(e) {
    setValue(inputMask.resolve(e.target.value));
  }
  return (
    <InputBlock width={width}>
      <InputBlockContent>
        <Label>{label}</Label>
        <InputContainer>
          <InputComponent
            onBlur={(e) => handleBlur({ [name]: e.target.value })}
            onChange={handleChange}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
          />
        </InputContainer>
      </InputBlockContent>
    </InputBlock>
  );
}
export default TextBlock;
