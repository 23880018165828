import { PaymentTypeCodes, useGetPaymentTypesQuery } from '../../../../../../services/dictionaries';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import PaymentAccountSlice from '../../../state';

export const useCheckAdvancePayment = (paymentType: string | number) => {
  const { data: paymentTypes = [] } = useGetPaymentTypesQuery();
  const rowPaymentType = paymentTypes.find((payType) => payType.id === paymentType);

  // сумма аванса при текущих рассчетах в таблице
  const advanceAmount = useSelector((state: RootState) => state.PaymentAccountSlice.advanceAmount);
  const { setAdvanceAmount } = PaymentAccountSlice.actions;
  const payByAdvance = rowPaymentType?.code === PaymentTypeCodes.PREPAID;

  return {
    advanceAmount,
    payByAdvance,
    setAdvanceAmount,
  };
};
