import React, { useState } from 'react';

import TextInput from '../../../uikit/TextInput';
import { getMask } from '../../../utils/masks';
import styled from 'styled-components';

type HeaderColProps = {
  fontSize: number;
  width?: number;
  paddingLeft?: number;
  paddingRight?: number;
  noBorder?: boolean;
  color: string;
  flexGrow: number;
};
export const HeaderColumn = styled.div<HeaderColProps>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  flex-grow: ${({ flexGrow }) => flexGrow};
  height: 54px;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: 22px;
  color: ${({ color }) => color};
  background: #f9f9f9;
  padding-left: ${({ paddingLeft }) => paddingLeft || 8}px;
  padding-right: ${({ paddingRight }) => paddingRight || 0}px;
  padding-top: 16px;
  border-bottom: ${({ noBorder = false }) => (noBorder ? 'none' : '2px solid #7c8590')};
  border-top: ${({ noBorder = false }) => (noBorder ? 'none' : '2px solid #7c8590')};
`;

export function SearchHeader({
  width,
  type,
  text,
  name,
  searchQuery = null,
  multiline = false,
  paddingLeft,
  paddingRight,
  noBorder,
  searchable = true,
  fontSize = 16,
  color = '#253446',
  flexGrow = 0,
  setSearchQuery,
}) {
  const [isMouseOver, setMouseOver] = useState(false);
  const input_mask = getMask(type);
  const queryIsEmpty = !(searchQuery && searchQuery[name] && searchQuery[name].trim());

  return (
    <HeaderColumn
      style={{ paddingTop: isMouseOver ? 8 : multiline ? 4 : 16 }}
      onClick={() => {
        searchable && setMouseOver(true);
      }}
      onMouseOver={() => {
        searchable && setMouseOver(true);
      }}
      onMouseEnter={() => {
        searchable && setMouseOver(true);
      }}
      onMouseLeave={() => {
        if (queryIsEmpty) {
          setMouseOver(false);
          setSearchQuery && setSearchQuery(null);
        }
      }}
      width={width}
      fontSize={fontSize}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      noBorder={noBorder}
      color={color}
      flexGrow={flexGrow}
    >
      {isMouseOver ? (
        <div style={{ height: 32, marginLeft: 0, width: '100%' }}>
          <TextInput
            onBlur={(e) => {
              const value = input_mask.resolve(e.target.value);
              if (value.trim().length < 1) {
                setMouseOver(false);
              }
            }}
            placeholder={'Поиск'}
            onChange={(e) => {
              const value = input_mask.resolve(e.target.value);
              setSearchQuery && setSearchQuery({ ...searchQuery, [name]: value });
            }}
            underlined={true}
            value={searchQuery?.[name] ?? ''}
          />
        </div>
      ) : (
        <div style={{ paddingLeft: 8 }}>{text}</div>
      )}
    </HeaderColumn>
  );
}
