import { MutableRefObject, useRef } from 'react';

/**
 * Сохраняет значение по ссылке на каждый пересчет компонента
 *
 * Используем, если необходимо иметь доступ к актуальному значению без подписки на состояние
 *
 * @param value
 */

export function useLatestRef<T>(value: T) {
  const ref = useRef<T | null>(null);
  ref.current = value;

  return ref as MutableRefObject<T>;
}
