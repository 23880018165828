import React, {CSSProperties, memo} from "react";
import styled from "styled-components";
import {DiagnosisMKBTemplate} from "./DiagnosisMKBTemplates";
import {DiagnosisMKBItem} from "./DiagnosisMKBItem";
import {DotsIcon} from "../../../../uikit/Icons";

const ContentTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #515D6B;
  line-height: 22px;
  padding: 16px;
  border-bottom: 2px solid #D9D9D9;
`

const ContentSubTitle = styled.span`
  font-size: 14px;
  font-weight: normal;
  margin-left: 8px;
`

const EmptyText = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #A8AEB5;
  line-height: 16px;
  padding: 19px 16px 0 16px;
`

type Props = {
  style?: CSSProperties;
  title: string;
  subTitle?: string;
  templates: DiagnosisMKBTemplate[];
  isDictionary?: boolean;
  onClickMenu: Function;
  onAddTemplate: Function;
}

export const DiagnosisMKBList = memo(({
      style,
      title,
      subTitle,
      templates,
      isDictionary = false,
      onClickMenu,
      onAddTemplate
}: Props) => (
    <div style={style}>
      <ContentTitle>
        {title}. {subTitle && <ContentSubTitle>{subTitle}</ContentSubTitle>}
      </ContentTitle>
      {templates.length ?
        templates.map(template => (
          <DiagnosisMKBItem
            key={template.id}
            template={template}
            isDictionary={isDictionary}
            onClickMenu={onClickMenu}
            onAddTemplate={onAddTemplate}
          />
        ))
        : (!isDictionary && <EmptyText>Чтобы добавить в шаблон нажмите на &nbsp;“<DotsIcon/>“</EmptyText>)
      }
    </div>
  )
);

DiagnosisMKBList.displayName = 'DiagnosisMKBList';
