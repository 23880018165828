/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import AppointmentFormState from './state';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@material-ui/core';
import Switch from '../../../../uikit/Switch';
import cn from 'classnames';
import format from 'date-fns/format';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import { PatientTable } from './components/PatientTable/PatientTable';
import TextArea from '../../../../uikit/TextArea';
import BlueButton from '../../../../uikit/Button/BlueButton';
import OutlinedButton from '../../../../uikit/Button/OutlinedButton';
import DoctorSelect from './components/DoctorSelect/DoctorSelect';
import { FormProvider, useForm } from 'react-hook-form';
import ruLocale from 'date-fns/locale/ru';
import { parseDate } from '../../../PatientPage/operations';
import { useAppSelector } from '../../../../store/hooks';
import { useAddDateMutation, useAddEntryMutation, useLazyGetDatesQuery } from '../../../../services/medcard';
import { HeaderContainer, Footer, FooterContainer, Info, InfoTable, useStyles } from './styles';
import { useAddInvoiceMutation, useAddSubInvoiceMutation } from '../../../../services/invoice';
import { useCreateAppointmentMutation, useGetAppointmentsQuery } from '../../../../services/appointments';
import { useGetStaffByClinicQuery } from '../../../../services/users';
import { patientsApi, useAddPatientMutation, useUpdatePatientMutation } from '../../../../services/patients';
import useToggle from '../../../../hooks/useToggle';
import { setSelectedZoneData } from '../../reducer';
import { checkForTimeMatches, checkForActionInPast, filterBackendAppointments } from '../../ScheduleTable/functions';
import { toast } from 'react-hot-toast';
import { isDateStringValid, isEmailStringValid } from './components/PatientTable/PatientTable/helpers';

import { TEditiableFieldsValues, initialEditiableFieldsValues } from './components/PatientTable/Patient/types';
import { EAccountStatus } from '../../../MedcardPage/Account/AccountStatus';
import { FormulaType } from '../../../MedcardPage/Formula';
import FormulaState from '../../../MedcardPage/Formula/state';

const { changePatientDetails } = AppointmentFormState.actions;

export const FormErrorsContext = React.createContext(null);

const AppointmentForm = ({ modalView, selectedZoneData, handleStartEdit, handleStopEdit }) => {
  const dispatch = useDispatch();

  //Очистка данных о выделенной области
  const clearSelectedZoneData = () => dispatch(setSelectedZoneData(null));

  //Флаг состояния "показать все поля", false по умолчанию
  const [areAllFieldsShown, setAreAllFieldsShown] = useToggle();

  //Стили
  const classes = useStyles({ isFullView: areAllFieldsShown });

  //Состояние для врача, пациента и комментария (хранятся локально для валидации)
  const [patient, setPatient] = useState<any>({});
  const [comment, setComment] = useState<string>('');
  const [editiableFieldsValues, setEditiableFieldsValues] =
    useState<TEditiableFieldsValues>(initialEditiableFieldsValues);

  const [firstLoad, setFirstLoad] = useState(false);
  const user = useSelector((state: any) => state.common.user);
  const clinicID = useSelector((state: any) => state.common.user.clinic.id);
  const { pickDate, addCurrentDate } = FormulaState.actions;

  const { data: staffers = [], isLoading: isStaffLoading } = useGetStaffByClinicQuery(clinicID);
  const doctors = staffers.filter((s) => s.role === 'DOC').filter((d) => d.is_active);

  const [doctorId, setDoctorId] = useState<number>(0);

  const schedulerAPI = useAppSelector((state) => state.reworkedSchedule.schedulerAPI);
  const currentPatient = useAppSelector((state) => state.medCardPageSlice.patientDetails);
  const methods = useForm();
  const { data: appointments = [] } = useGetAppointmentsQuery(clinicID);
  const [getDates] = useLazyGetDatesQuery();
  const [addDate] = useAddDateMutation();
  const [addEntry] = useAddEntryMutation();
  const [addInvoice] = useAddInvoiceMutation();
  const [addSubInvoice] = useAddSubInvoiceMutation();
  const [createNewAppointment] = useCreateAppointmentMutation();
  const [createAddNewPatient] = useAddPatientMutation();
  const [updateAddNewPatient] = useUpdatePatientMutation();

  const editableFields = { commentary: true };

  useEffect(() => {
    dispatch(changePatientDetails(currentPatient));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //При изменении врача из выделенного участка таблицы, сетается id нового выделенного врача
  useEffect(() => {
    !isStaffLoading &&
      setDoctorId(
        doctors.some((d) => d.id === selectedZoneData?.selectedZoneDoctorId)
          ? selectedZoneData?.selectedZoneDoctorId
          : 0,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedZoneData?.selectedZoneDoctorId, isStaffLoading]);

  const [formErrors, setFormErrors] = useState({
    doctor: false,
    patient: {
      first_name: false,
      second_name: false,
      phone: false,
      not_unique: {
        first_name: false,
        second_name: false,
        last_name: false,
        birth_date: false,
        phone: false,
      },
      invalid: {
        phone: false,
        birth_date: false,
        email: false,
      },
    },
  });

  const handleSubmit = async () => {
    let appointment = {
      status: 'SCH',
      cabinet: selectedZoneData?.selectedZoneCabinet.id,
      starts_at: new Date(selectedZoneData.selectedZoneTime.start),
      ends_at: new Date(selectedZoneData.selectedZoneTime.end),
      clinic: user.clinic.id,
      doctor: doctorId,
      patient: patient.id,
      comment: comment,
    };

    if (!doctorId) {
      setFormErrors({ ...formErrors, doctor: true });
      return;
    }

    if (patient.id) {
      if (!patient.first_name && !patient.second_name && !patient.phone) {
        return;
      }
    } else {
      let validPatient = true;

      if (!patient.first_name) {
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          patient: { ...prevFormErrors.patient, first_name: true },
        }));
        validPatient = false;
      }

      if (!patient.last_name) {
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          patient: { ...prevFormErrors.patient, last_name: true },
        }));
        validPatient = false;
      }

      if (patient.birth_date) {
        if (!isDateStringValid(patient.birth_date)) {
          setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            patient: {
              ...prevFormErrors.patient,
              invalid: { ...prevFormErrors.patient.invalid, birth_date: true },
            },
          }));
          validPatient = false;
        }
      }

      if (!patient.phone || !patient.phone.length) {
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          patient: {
            ...prevFormErrors.patient,
            phone: true,
          },
        }));
        validPatient = false;
      }

      const phoneRegExp = /^\+\d{1,3} \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
      if (!phoneRegExp.test(patient.phone)) {
        setFormErrors((prevFormErrors) => {
          return {
            ...prevFormErrors,
            patient: {
              ...prevFormErrors.patient,
              invalid: { ...prevFormErrors.patient.invalid, phone: true },
            },
          };
        });
        validPatient = false;
      }

      if (patient.email) {
        if (!isEmailStringValid(patient.email)) {
          setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            patient: {
              ...prevFormErrors.patient,
              invalid: { ...prevFormErrors.patient.invalid, email: true },
            },
          }));
          validPatient = false;
        }
      }

      if (!validPatient) {
        return;
      }
    }

    // Создание нового пациента (если id пациента отсутствует, т.е. пациента ещё не существует)
    if (!patient.id) {
      let newPatient = {
        ...patient,
        clinic: user.clinic.id,
        balance: 0,
        birth_date: patient.birth_date && parseDate(patient.birth_date),
      };
      createAddNewPatient(newPatient)
        .unwrap()
        // В случае успешного создания, в данные о создающемся визите передаётся id только что созданного пациента
        .then((payload) => {
          appointment = { ...appointment, patient: payload.id };
          newPatient = { ...newPatient, payload };
          toast.success('Новый пациент успешно создан');
          dispatch(patientsApi.util.invalidateTags(['Patients']));

          // Создание визита
          createNewAppointment({ id: user.clinic.id, data: appointment })
            .unwrap()
            .then((payload) => {
              schedulerAPI.addEvent({
                id: payload.id,
                resourceId: appointment.cabinet,
                start: appointment.starts_at,
                end: appointment.ends_at,
                doctor: doctors.find((d) => d.id === appointment.doctor),
                status: appointment.status,
                cabinet: appointment.cabinet,
                patient: newPatient,
                comment,
              });
              // Проверить и дать предупреждения в случае если есть визиты на совпадающее время в других кабинетах
              checkForActionInPast(appointment.starts_at.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
              checkForTimeMatches(payload.id, schedulerAPI);
              handleStartEdit(payload.id);
              handleStopEdit(payload.id);
              addDate({
                patientId: appointment.patient,
                date: format(new Date(selectedZoneData.selectedZoneTime.start), 'yyyy-MM-dd'),
                appointment: payload.id,
              })
                .unwrap()
                .then(({ id }) => {
                  getDates(appointment.patient);
                  addEntry({
                    entry: {
                      doctor: user.id,
                      date_id: id,
                      formula_type: FormulaType.Adult,
                      tooth_index: '',
                      tooth_state_mnemonic: '',
                      tooth_treatment_mnemonic: '',
                      tooth_state_label: 'Не указано',
                    },
                    patientId: appointment.patient,
                  })
                    .unwrap()
                    .then(({ id, date_id }) => {
                      dispatch(addCurrentDate(date_id));
                      dispatch(pickDate(date_id));

                      addInvoice({ date_id })
                        .unwrap()
                        .then(({ id: invoiceId }) => {
                          addSubInvoice({ invoice: invoiceId, formula_id: id, status: EAccountStatus.NOT_FILLED });
                        });
                    });
                });
            })
            .catch(() => {
              toast.error(`Визит не был создан по техническим причинам`);
            });
        })
        // В случае провала, дополнительная проверка: если бек вернул ошибку неуникальных данных - подсветятся неуникальные поля. Иначе - сообщение о технической ошибке
        .catch((error) => {
          const e = error.response;
          if (e.data.error && e.data.error.code === 'NOT_UNIQ') {
            e.data.error.fields.forEach((f) =>
              setFormErrors((prevFormErrors) => ({
                ...prevFormErrors,
                patient: {
                  ...prevFormErrors.patient,
                  not_unique: {
                    ...prevFormErrors.patient.not_unique,
                    [f]: true,
                  },
                },
              })),
            );
          } else {
            toast.error('Пациент не был создан по техническим причинам');
            toast.error('Визит не был создан, так как не получилось создать пациента');
          }
          return;
        });
      // Если в объекте с редактируемыми полями есть непустая строка - надо обновлять данные о пациенте (сейчас это только "Важная информация")
    } else {
      const hasNonEmptyFields = Object.values(editiableFieldsValues).some((fieldValue) => fieldValue !== '');
      if (hasNonEmptyFields) {
        const nonEmptyFields = Object.entries(editiableFieldsValues).reduce((acc, [key, value]) => {
          if (value !== '') {
            acc[key] = value;
          }
          return acc;
        }, {});
        updateAddNewPatient({ id: patient.id, ...nonEmptyFields })
          .unwrap()
          .then(() => toast.success('Пациент успешно отредактирован'))
          .catch(() => toast.error('Пацинет не был отредактирован по техническим причинам'));
      }
    }

    // Проверка: нет ли другого визита, который блокирует создание нового визита в нужном кабинете, в нужное время?
    const newAppointmentCreateBlockers = filterBackendAppointments(
      appointments,
      undefined,
      appointment.starts_at,
      appointment.ends_at,
      appointment.cabinet,
    );
    if (newAppointmentCreateBlockers.length > 0) {
      toast.error(`Визит на это время уже был создан другим пользователем`);
      clearSelectedZoneData();
      return;
    }

    if (patient.id) {
      // Создание визита
      createNewAppointment({ id: user.clinic.id, data: appointment })
        .unwrap()
        .then((payload) => {
          schedulerAPI.addEvent({
            id: payload.id,
            resourceId: appointment.cabinet,
            start: appointment.starts_at,
            end: appointment.ends_at,
            doctor: doctors.find((d) => d.id === appointment.doctor),
            status: appointment.status,
            cabinet: appointment.cabinet,
            patient,
            comment,
          });
          // Проверить и дать предупреждения в случае если есть визиты на совпадающее время в других кабинетах
          checkForActionInPast(appointment.starts_at.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
          checkForTimeMatches(payload.id, schedulerAPI);
          handleStartEdit(payload.id);
          handleStopEdit(payload.id);
          addDate({
            patientId: patient.id,
            date: format(new Date(selectedZoneData.selectedZoneTime.start), 'yyyy-MM-dd'),
            appointment: payload.id,
          })
            .unwrap()
            .then(({ id }) => {
              getDates(patient.id);
              addEntry({
                entry: {
                  doctor: user.id,
                  date_id: id,
                  formula_type: FormulaType.Adult,
                  tooth_index: '',
                  tooth_state_mnemonic: '',
                  tooth_treatment_mnemonic: '',
                  tooth_state_label: 'Не указано',
                },
                patientId: patient.id,
              })
                .unwrap()
                .then(({ id, date_id }) => {
                  dispatch(addCurrentDate(date_id));
                  dispatch(pickDate(date_id));

                  addInvoice({ date_id })
                    .unwrap()
                    .then(({ id: invoiceId }) => {
                      addSubInvoice({ invoice: invoiceId, formula_id: id, status: EAccountStatus.NOT_FILLED });
                    });
                });
            });
        })
        .catch(() => {
          toast.error(`Визит не был создан по техническим причинам`);
        });
    }
    clearSelectedZoneData();
  };

  const handleDoctorChange = (doctorId) => {
    // При инициализации, компонент DoctorSelect принудительно пытается сетнуть себе id = 0
    // Потому что врачи ещё не загрузились, у DoctorSelect нет данных о врачах. Сетать id = 0 нельзя
    if (doctorId === 0) return;
    setDoctorId(doctorId);
  };

  const handleFieldEdit = (fieldName, value) => {
    setEditiableFieldsValues((prev) => {
      return { ...prev, [fieldName]: value };
    });
  };

  return (
    <Modal
      id="appointment-modal"
      open
      onClose={() => {
        clearSelectedZoneData();
        setFirstLoad(false);
      }}
      aria-labelledby="appointment-title"
      aria-describedby="appointment-description"
      className={classes.modal}
    >
      <FormErrorsContext.Provider value={{ formErrors, setFormErrors }}>
        <div className={classes.paper}>
          <HeaderContainer>
            <h5 className={classes.title}>Создание записи на прием</h5>
            <Info style={{ marginBottom: '24px' }}>
              <div className={classes.label} style={{ marginRight: '8px' }}>
                Врач
              </div>
              <DoctorSelect doctors={doctors} doctorId={doctorId} setDoctorId={handleDoctorChange} />
              <div className={cn(classes.labelSm, classes.ml16)}>Кабинет:</div>
              <div className={classes.cabinet}>{selectedZoneData.selectedZoneCabinet.title}</div>
              <div>
                <CalendarTodayOutlinedIcon
                  style={{
                    fontSize: 10.94,
                    marginLeft: '17.53px',
                    marginRight: '5.53px',
                  }}
                />
                <span className={classes.labelSm1}>
                  {format(selectedZoneData.selectedZoneTime.start, 'dd MMM yyyy', { locale: ruLocale })}
                </span>
                <ScheduleOutlinedIcon
                  style={{
                    fontSize: 12.25,
                    marginLeft: '16.87px',
                    marginRight: '4.88px',
                  }}
                />
                <span className={classes.labelSm1}>
                  {format(selectedZoneData.selectedZoneTime.start, 'HH:mm')} -{' '}
                  {format(selectedZoneData.selectedZoneTime.end, 'HH:mm')}
                </span>
              </div>
            </Info>
            <Info>
              <div className={classes.label}>Укажите пациента</div>
              <div className={cn(classes.text1, classes.ml12)}>
                Для поиска пациента начните вводить данные в поля таблицы
              </div>
              <InfoTable>
                <div className={classes.text1}>Все поля таблицы</div>
                <Switch className={classes.ml10} checked={areAllFieldsShown} onClick={setAreAllFieldsShown} />
              </InfoTable>
            </Info>
          </HeaderContainer>
          <FormProvider {...methods}>
            <PatientTable
              isFullView={areAllFieldsShown}
              patient={patient}
              setPatient={setPatient}
              modalView={modalView}
              firstLoad={firstLoad}
              setFirstLoad={setFirstLoad}
              editableFields={editableFields}
              handleFieldEdit={handleFieldEdit}
              editiableFieldsValues={editiableFieldsValues}
            />
          </FormProvider>
          <FooterContainer>
            <Footer>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div className={classes.label}>Комментарий</div>
                <TextArea
                  multiline
                  rows={3}
                  style={{ width: 549 }}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <OutlinedButton
                  width={106}
                  style={{ marginRight: '20px' }}
                  onClick={() => {
                    clearSelectedZoneData();
                    setFirstLoad(false);
                  }}
                >
                  Отменить
                </OutlinedButton>
                <BlueButton width={112} onClick={handleSubmit}>
                  Сохранить
                </BlueButton>
              </div>
            </Footer>
          </FooterContainer>
        </div>
      </FormErrorsContext.Provider>
    </Modal>
  );
};

export default AppointmentForm;
