import React, { ChangeEvent, useEffect, useState } from 'react';
import Column from '../../../../PatientPage/components/Column';
import TextInput from '../../../../../uikit/TextInput';
import { getMask } from '../../../../../utils/masks';
import styled from 'styled-components';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TableActionButton, TableActionMenu } from '../../../../../components/Table/styles';
import { DotsIcon } from '../../../../../uikit/Icons';
import { MenuItem } from '@material-ui/core';
import { BorderSelect } from '../../../Template/Select';
import { TextWithPopover } from '../../../Template/TextWithPopover';
import {
  DiscountTypeItems,
  DiscountTypeValues,
  TreatmentPlanItem,
} from '../../../Template/TreatmentPlan/TreatmentPlanJournalField';
import { InvoiceHistory } from './JournalAccountField';
import { useLatestRef } from '../../../../../hooks/useLatestRef';
import { overDiscount, readOnlyDiscount } from './utils';
import { toast } from 'react-hot-toast';

const useStyles = makeStyles(() =>
  createStyles({
    discountSum: {
      borderBottomRightRadius: '0 !important',
      borderTopRightRadius: '0 !important',
    },
    discountType: {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      borderLeft: 0,
    },
  }),
);

const Row = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${({ selected = false }) => (selected ? '#F6F6FA' : 'white')};
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
`;

type Props = {
  item: TreatmentPlanItem;
  invoiceHistoryItem?: InvoiceHistory;
  setItem: (item) => void;
  textColumnWidth: number;
  readOnly?: boolean;
  isAddAccount?: boolean;
  onRowDelete: (template) => void;
  discountDisabled: boolean;
};

export function JournalAccountFieldItem({
  item,
  invoiceHistoryItem,
  setItem,
  textColumnWidth,
  readOnly = false,
  onRowDelete,
  discountDisabled,
}: Props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [countValue, setCountValue] = useState(item.count);

  const [discountValue, setDiscountValue] = useState(0);
  const discountValueRef = useLatestRef(discountValue);

  useEffect(() => {
    // подписываемся на обновление скидки через итоговое поле ввода
    if (item.discount !== discountValueRef.current) {
      setDiscountValue(item.discount);
    }
  }, [item.discount, discountValueRef]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onChangeDiscount = (event: ChangeEvent<HTMLInputElement>) => {
    const discountValue = +getMask('count').resolve(`${+event.target.value}`);
    const amountForPay = item.price * item.count;

    const discountValueExceeded = overDiscount(discountValue, amountForPay, item.discountType);

    if (discountValueExceeded) {
      toast.error('Сумма скидки не может быть больше 100%');
    } else {
      setDiscountValue(discountValue);
    }
  };

  const onBlurCount = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const maskedCount = getMask('count_without_zero').resolve(`${value}`) || 1;
    // сбрасываем скидку, если она превышает новую сумму к оплате
    const discount =
      item.discountType === DiscountTypeValues.RUB && item.discount > maskedCount * item.price ? 0 : item.discount;

    setItem?.({ ...item, count: maskedCount, discount });

    if (!value) {
      setCountValue(1);
    }
  };

  return (
    <Row selected={item.isUsed}>
      <Column name="code" defaultValue={item.code} width={94} fontSize={12} isEditing={false} hasAction>
        {item.code}
      </Column>
      <Column name="text" defaultValue={item.text} width={textColumnWidth} fontSize={12} isEditing={false} hasAction>
        <TextWithPopover text={item.text} width={386} height={70} />
      </Column>
      <Column name="count" defaultValue={item.count} width={55} fontSize={12} isEditing={false} hasAction>
        <>
          {readOnly || invoiceHistoryItem?.count >= item.count ? (
            item.count
          ) : (
            <TextInput
              value={countValue}
              style={{ height: 22, fontSize: 12 }}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setCountValue(getMask('count_without_zero').resolve(`${e.target.value}`))}
              onBlur={onBlurCount}
            />
          )}
        </>
      </Column>
      <Column name="price" defaultValue={item.price} width={66} fontSize={12} isEditing={false} hasAction>
        {getMask('number_format').resolve(`${item.price}`)}
      </Column>
      <Column
        name="discount"
        defaultValue={item.discount}
        width={108}
        fontSize={12}
        isEditing={false}
        hasAction
      >
          <>
            {readOnly ? (
                readOnlyDiscount(item.discount, item.discountType)
            ) : (
              <div style={{ display: 'flex' }}>
                <TextInput
                  className={classes.discountSum}
                  value={discountValue}
                  style={{ height: 22, width: 47, fontSize: 12 }}
                  disabled={discountDisabled}
                  onClick={(e) => e.stopPropagation()}
                  onChange={onChangeDiscount}
                  onBlur={(e) => {
                    const currentDiscount = +getMask('count').resolve(`${+e.target.value}`);

                    // обновляем позицию, если значение изменилось
                    if (currentDiscount !== item.discount) {
                      setItem({ ...item, discount: currentDiscount });
                    }
                  }}
                />
                <BorderSelect
                  className={classes.discountType}
                  value={item.discountType}
                  disabled={discountDisabled}
                  setValue={(selectedDiscountType) => {
                    if (selectedDiscountType !== item.discountType) {
                      setItem({
                        ...item,
                        discountType: selectedDiscountType,
                        // сбрасываем текущее значение скидки при изменении типа
                        discount: 0,
                      });
                    }
                  }}
                  items={DiscountTypeItems}
                />
              </div>
            )}
          </>
      </Column>
      <Column name="text" defaultValue={item.payment} width={72} fontSize={12} isEditing={false} hasAction>
        {getMask('number_format').resolve(`${item.payment - item.paid}`)}
      </Column>
      {!(readOnly || !!invoiceHistoryItem?.paid) && (
        <>
          <TableActionButton
            style={{ position: 'absolute', right: 4 }}
            size="small"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            disableRipple
          >
            <DotsIcon />
          </TableActionButton>
          <TableActionMenu id="action-menu" anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
            <MenuItem
              onClick={() => {
                onRowDelete(item);
                handleMenuClose();
              }}
            >
              Удалить
            </MenuItem>
          </TableActionMenu>
        </>
      )}
    </Row>
  );
}
