import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Card, CardContent, CardHeader, ClickAwayListener } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { DiagnosisMKBList } from './DiagnosisMKBList';
import { SearchHeader } from '../SearchHeader';
import request from '../../../../utils/request';
import { ToothStateType } from '../../Formula/StateMenu';
import { debounce, uniqBy } from 'lodash';
import { toast } from "react-hot-toast";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'absolute',
      border: '1px solid #D3D6DA',
      boxSizing: 'border-box',
      boxShadow: '0 2px 4px rgba(40, 41, 61, 0.04), 0 8px 16px rgba(96, 97, 112, 0.16)',
      borderRadius: 4,
      marginRight: 16,
      marginBottom: 24,
      marginLeft: 8,
      transition: 'all 500ms ease-in-out',
      width: 'calc(100% - 24px)',
      top: 40,
      zIndex: 1,
    },
    header: {
      background: '#F9F9F9',
      padding: 0,
    },
    headerContent: {
      display: 'flex',
      padding: '0 16px',
      borderBottom: '2px solid #7C8590',
    },
    text: {
      color: '#A8AEB5',
      fontWeight: 'normal',
      fontSize: 14,
      marginRight: 8,
    },
    content: {
      padding: '0 16px 12px 16px',
      '&:last-child': {
        paddingBottom: 12,
      },
    },
    noCompleted: {
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '120%',
      color: '#A8AEB5',
      padding: '20px 14px 10px',
    },
  }),
);

type Props = {
  doctorId: number;
  usedTemplates: DiagnosisMKBTemplate[];
  toothState: ToothStateType;
  onAddTemplate: (template: DiagnosisMKBTemplate) => void;
  clearFocus: VoidFunction;
};

type SearchQueryType = { code?: string; text?: string } | null;

export type DiagnosisMKBTemplate = {
  id: number;
  code: string;
  text: string;
  selected: boolean;
};

function getTemplates(templates, usedTemplates) {
  return templates.map((t) => {
    if (usedTemplates.find((u) => u.code === t.code)) {
      return { ...t, selected: true };
    }
    return t;
  });
}

// TODO переписать запросы на RTK Query для кэширования
export function DiagnosisMKBTemplates({ doctorId, usedTemplates, toothState, onAddTemplate, clearFocus }: Props) {
  const classes = useStyles();

  const [dictionaryTemplates, setDictionaryTemplates] = useState<DiagnosisMKBTemplate[]>([]);
  const [templates, setTemplates] = useState<DiagnosisMKBTemplate[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<SearchQueryType>(null);

  const fetchData = useCallback(async (searchQuery: SearchQueryType = null) => {
    try {
      setIsLoading(true);

      const dataTemplates = await request.users.mkb_templates(doctorId, toothState?.mnemo, searchQuery);
      const dataDicTemplates = await request.users.load_dictionary_mkb(toothState?.mnemo, searchQuery);

      setDictionaryTemplates(dataDicTemplates.data);
      setTemplates(
          dataDicTemplates.data.reduce((result, value) => {
            const template = dataTemplates.data.find((d) => value.id === d.mkb_diagnosis.id);
            if (template) {
              result.push({
                ...value,
                id: template.mkb_diagnosis.id,
                templateId: template.id,
              });
            }
            return result;
          }, []),
      );
    } catch(e) {
      toast.error('Ошибка запроса шаблонов');
    } finally {
      setIsLoading(false);
    }
  }, [doctorId, toothState?.mnemo])

  useEffect(() => {
    void fetchData();
  }, [fetchData]);

  const debounceFetch = useMemo(() =>
      debounce(async (query: Exclude<SearchQueryType, null>) => {
        const trimmedQuery = Object.entries(query).reduce((acc, [key, value]) => {
          acc[key] = value.trim();

          return acc
        }, {});

        await fetchData(trimmedQuery);
      }, 500), [fetchData])

  const onSetSearchQuery = async (query: SearchQueryType) => {
    // в query приходит null если пользователь уходит с пустого поля ввода
    if (query) {
      setSearchQuery(query);

      await debounceFetch(query);
    }
  }

  // templates
  const mkbTemplates = useMemo(
      () => (
          getTemplates(templates, usedTemplates)),
      [templates, usedTemplates]
  )

  const onClickTemplateMenu = useCallback(async (template) => {
    try {
      await request.users.delete_mkb_templates(template.templateId);
      setTemplates(
          (prevTemplates) => prevTemplates.filter((t) => t.id !== template.id));
      setDictionaryTemplates(
          (prevTemplates) => uniqBy([...prevTemplates, template], 'id')
      );
    } catch(e) {
      toast.error('Ошибка удаления шаблона')
    }
  }, [])


  // dictionary
  const dictionaryFilteredTemplates = useMemo(
      () => {
        const dicTemplates = dictionaryTemplates.filter((d) => !templates.find((t) => t.id === d.id))
        return getTemplates(dicTemplates, usedTemplates)},
      [dictionaryTemplates, templates, usedTemplates]
  )

  const onClickDictionaryMenu = useCallback(async (template) => {
      try {
        const addTemplate = await request.users.add_mkb_templates(doctorId, toothState?.mnemo, template.id);
        setTemplates(
            (prevTemplates) => [...prevTemplates, { ...template, templateId: addTemplate.data.id }]
        );
      } catch(e) {
        toast.error('Ошибка добавления шаблона')
      }
    },
      [doctorId, toothState?.mnemo]
  )

  return (
    <ClickAwayListener onClickAway={clearFocus}>
      <Card className={classes.root}>
        <CardHeader
          className={classes.header}
          title={
            <div className={classes.headerContent}>
              <SearchHeader
                type="text"
                name="code"
                width={100}
                text="Код"
                searchQuery={searchQuery}
                setSearchQuery={onSetSearchQuery}
                noBorder
              />
              <SearchHeader
                type="text"
                name="text"
                text="Диагноз"
                searchQuery={searchQuery}
                setSearchQuery={onSetSearchQuery}
                noBorder
              />
            </div>
          }
        />
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '397px',
            }}
          >
            <div className="spinner" />
          </div>
        ) : (
          <Scrollbars
            autoHeight
            autoHeightMax={397}
            style={{
              width: '100%',
            }}
          >
            <CardContent className={classes.content}>
              <div style={{ marginBottom: 20 }}>
                <DiagnosisMKBList
                  title="Шаблоны"
                  templates={mkbTemplates}
                  subTitle={toothState?.title}
                  onClickMenu={onClickTemplateMenu}
                  onAddTemplate={onAddTemplate}
                />
              </div>
              <DiagnosisMKBList
                isDictionary
                title="Справочник МКБ"
                templates={dictionaryFilteredTemplates}
                onClickMenu={onClickDictionaryMenu}
                onAddTemplate={onAddTemplate}
              />
            </CardContent>
          </Scrollbars>
        )}
      </Card>
    </ClickAwayListener>
  );
}
