/* eslint-disable react-hooks/exhaustive-deps */
import React, { Ref, useRef, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import ruLocale from '@fullcalendar/core/locales/ru';
import {
  headerToolbar,
  slotLabelFormat,
  constraint,
} from '../../../features/staff/components/StaffScheduleSetup/components/StaffSchedulerTable/settings';
import { views } from './settings';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useUpdateAppointmentMutation, useCreateAppointmentMutation } from '../../../services/appointments';
import {
  eventOverlap,
  select,
  eventClick,
  eventDrop,
  eventResize,
  checkForHideCells,
  eventDragStart,
  eventDragStop,
  eventResizeStart,
  eventResizeStop,
} from './functions';
import { EventContent, ResourceLabelContent } from './components';
import { setSchedulerAPI } from '../reducer';
import { useGetHolidaysQuery, useGetWorkdaysQuery } from '../../../services/dictionaries';

const ScheduleTable = ({ handleStartEdit, handleStopEdit }) => {
  const calendarRef: Ref<FullCalendar> = useRef(null);
  const dispatch = useAppDispatch();

  const { data: workdays = [] } = useGetWorkdaysQuery();
  const { data: holidays = [] } = useGetHolidaysQuery();

  const schedulerAPI = useAppSelector((state) => state.reworkedSchedule.schedulerAPI);

  const [updateAppointment] = useUpdateAppointmentMutation();
  const [createAppointment] = useCreateAppointmentMutation();

  // Помещение API экземпляра таблицы в Redux. Делается один раз, после получения рефа таблицы.
  useEffect(() => {
    if (calendarRef.current) {
      dispatch(setSchedulerAPI(calendarRef?.current?.getApi()));
    }
    return () => dispatch(setSchedulerAPI(null));
  }, [calendarRef]);

  return (
    <FullCalendar
      ref={calendarRef}
      timeZone="local"
      initialView="displayByDay"
      eventOrder="start"
      eventConstraint={constraint}
      selectConstraint={constraint}
      plugins={[interactionPlugin, resourceTimeGridPlugin]}
      locale={ruLocale}
      views={views}
      headerToolbar={headerToolbar}
      slotLabelFormat={slotLabelFormat}
      expandRows={true}
      weekends={true}
      datesAboveResources={true}
      editable={true}
      eventStartEditable={true}
      eventResizableFromStart={true}
      eventDurationEditable={true}
      eventResourceEditable={true}
      selectable={true}
      dragScroll={true}
      stickyHeaderDates={true}
      allDaySlot={false}
      nowIndicator={true}
      slotDuration={'00:15:00'}
      slotLabelInterval={{ hours: 1 }}
      eventContent={(args) => <EventContent args={args} />}
      resourceLabelContent={(args) => <ResourceLabelContent args={args} />}
      eventOverlap={eventOverlap}
      eventResize={(args) => eventResize(args, updateAppointment, schedulerAPI)}
      eventDrop={(args) => eventDrop(args, updateAppointment, schedulerAPI)}
      eventDragStart={(args) => eventDragStart(args, handleStartEdit)}
      eventDragStop={(args) => eventDragStop(args, schedulerAPI, handleStopEdit)}
      eventResizeStart={(args) => eventResizeStart(args, handleStartEdit)}
      eventResizeStop={(args) => eventResizeStop(args, handleStopEdit)}
      dayCellClassNames={(args) => checkForHideCells(args, schedulerAPI, workdays, holidays)}
      resourceLabelClassNames={(args) => checkForHideCells(args, schedulerAPI, workdays, holidays)}
      select={(args) =>
        select(args, schedulerAPI, createAppointment, updateAppointment, handleStartEdit, handleStopEdit, dispatch)
      }
      eventClick={(args) => eventClick(args, handleStartEdit, dispatch)}
    />
  );
};

export default React.memo(ScheduleTable);
