import { operationItemType } from '../../../types';
import { DEFAULT_PAYER, STARTED_AMOUNT } from '../../../constants';
import { Operation } from '../../../../../../services/invoice';

export const rowWithDeletedOperations = (row: operationItemType) => {
  const oldOperations = row.operations.filter((operation) => !operation.isNew);
  const newOperations = row.operations.filter((operation) => operation.isNew);
  const updatedOperations = [...oldOperations, { ...newOperations[0], payment: STARTED_AMOUNT }];

  return { ...row, totalRowPaid: STARTED_AMOUNT, operations: updatedOperations };
};

export const rowWithNewOperation = (row: operationItemType, currentOperation: Operation, paidAmount: number) => {
  const currentAmount = currentOperation?.amountForPay;

  const updatedOperations = row.operations.map((operation) => {
    return operation.id === currentOperation.id
      ? { ...operation, payment: currentAmount < paidAmount ? currentAmount : paidAmount }
      : operation;
  });
  const totalRowPaid = updatedOperations.reduce((sum, el) => sum + (el.isNew ? el.payment : STARTED_AMOUNT), 0);
  const isLastRow = row.operations.findIndex((el) => el.id === currentOperation?.id) === row.operations.length - 1;

  if (currentAmount > paidAmount && isLastRow && paidAmount !== 0) {
    const newOperationRow = {
      id: Math.random().toString(36).substring(2),
      amountForPay: currentAmount - paidAmount,
      payment: STARTED_AMOUNT,
      payer: DEFAULT_PAYER,
      payment_type: null,
      isNew: true,
      rowId: row.invoice_row,
    };
    updatedOperations.push(newOperationRow);
  }

  return { ...row, totalRowPaid, operations: updatedOperations };
};
