import React, { SyntheticEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import TextArea from '../../../uikit/TextArea';
import { TextLong } from '../Template/TextLong';
import { TextEllipsis } from '../Template/TextEllipsis';
import styled from 'styled-components';
import { FIELD_WIDTH } from './JornalItem';

const JournalFieldText = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 120%;
  color: #515d6b;
`;

type Props = {
  value: string;
  readOnly: boolean;
  focused: boolean;
  onFocus: (e: SyntheticEvent) => void;
  onChange: (value: string) => void;
};

export const MAX_SYMBOLS = 372;

export function JornalTextItem({ value, readOnly, focused, onFocus, onChange }: Props) {
  const [textValue, setTextValue] = useState<string>(value);
  const [editMode, setEditMode] = useState<boolean>(false);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const changeTextHeight = useCallback(() => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight + 2}px`;
    }
  }, [textAreaRef]);

  useEffect(() => {
    if (value !== textValue) {
      setTextValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useLayoutEffect(() => {
    if (editMode && textAreaRef.current) {
      const valueLength = textAreaRef.current.value.length;
      textAreaRef.current.focus();
      textAreaRef.current.setSelectionRange(valueLength, valueLength);
      changeTextHeight();
    }
  }, [editMode, changeTextHeight]);

  return readOnly ? (
    <JournalFieldText>
      <TextEllipsis text={textValue} max={MAX_SYMBOLS} />
    </JournalFieldText>
  ) : editMode ? (
    <TextArea
      ref={textAreaRef}
      multiline
      rows={1}
      value={textValue}
      style={{
        display: 'block',
        width: FIELD_WIDTH,
        overflowY: 'hidden',
        padding: 8,
        minHeight: '40px',
        lineHeight: '120%',
        alignContent: 'center',
      }}
      onFocus={onFocus}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        const newValue = e.target.value;
        changeTextHeight();
        setTextValue(newValue);
        onChange(newValue);
      }}
      onBlur={() => {
        setEditMode(false);
      }}
    />
  ) : (
    <TextLong text={textValue} width={FIELD_WIDTH} focused={focused} setEditMode={setEditMode} />
  );
}
