import { operationItemType } from '../../../types';
import { UseTableRowProps } from 'react-table';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';

export const useGetOperationData = (row: UseTableRowProps<operationItemType>) => {
  const operationItems = useSelector((state: RootState) => state.PaymentAccountSlice.operationItems);

  const currentRowId = row.depth === 0 ? row.values.id : row.original.rowId;
  const rowOperations = operationItems.find((rowItem) => currentRowId === rowItem.invoice_row)?.operations;

  const getCurrentOperation = () => {
    if (rowOperations) {
      if (row.depth === 0) return rowOperations[0];
      return rowOperations.find((operation) => operation.id === row.values.id);
    }
  };

  const { id: operationId, amountForPay, ...operation } = getCurrentOperation();

  const returned = Boolean(operation?.return_status);
  const paymentAmount = operation?.payment;
  const payment = returned ? -paymentAmount : paymentAmount;
  // для возвратных операций amountForPay приходит с минусом
  const remainder = payment - Math.abs(amountForPay);

  return {
    currentRowId,
    payment,
    returned,
    remainder,
    operationId,
    amountForPay,
    payment_type: operation?.payment_type,
    payer: operation?.payer,
    isNew: operation?.isNew,
  };
};
