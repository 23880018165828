import React from 'react';
import HeaderWithSearch from './HeaderWithSearch';
import { connect } from 'react-redux';
import { HeaderColumn } from '../style';
import StreamHeaderWrapper from './StreamHeaderWrapper';
import { RootState } from '../../../store';

function HeaderRow() {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
      <HeaderWithSearch type={'text'} placeholder={'Номер карты'} name={'id'} width={80} text={'№ карты'} />
      <HeaderWithSearch type={'text'} placeholder={'Фамилия'} name={'last_name'} width={196} text={'Фамилия'} />
      <HeaderWithSearch type={'text'} placeholder={'Имя'} name={'first_name'} width={130} text={'Имя'} />
      <HeaderWithSearch type={'text'} placeholder={'Отчество'} name={'second_name'} width={140} text={'Отчество'} />
      <HeaderWithSearch
        multiline
        type={'birth_date'}
        placeholder={'дд.мм.гггг'}
        name={'birth_date'}
        width={103}
        text={'Дата рождения'}
        tooltipWidth={120}
      />
      <HeaderWithSearch
        type={'phone'}
        placeholder={'+7(ххх)ххх хх хх'}
        name={'phone'}
        width={174}
        text={'Телефон'}
        tooltipWidth={130}
      />
      <HeaderWithSearch type={'email'} placeholder={'email@gmail.com'} name={'email'} width={150} text={'E-mail'} />
      <StreamHeaderWrapper
        type={'text'}
        placeholder={'Канал привлечения'}
        name={'stream'}
        width={164}
        text={'Канал привлечения'}
      />
      <HeaderWithSearch
        type={'text'}
        placeholder={'Важная информ.'}
        name={'commentary'}
        width={160}
        text={'Важная информация'}
        allowTextSpace
      />
      <HeaderColumn width={68} />
    </div>
  );
}

export default connect(
  (state: RootState) => ({ common: state.common, patientsPage: state.patientsPage }),
  {},
)(HeaderRow);
