import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import DateBlock from './DateBlock';
import { MedcardDate, useGetDatesQuery } from '../../../services/medcard';
import { useAppSelector } from '../../../store/hooks';
import TeethFormula from './state';
import { format, isToday } from 'date-fns';
import { ActionCreatorWithNonInferrablePayload } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { createStyles, makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { TabsActions } from '@material-ui/core/Tabs/Tabs';

const TABS_WIDTH = 1344;
const TAB_WIDTH = 107;

const tabsStyles = makeStyles(() =>
  createStyles({
    indicator: {
      display: 'none',
    },
    root: {
      position: 'relative',
      overflow: 'unset',
      boxSizing: 'border-box',
    },
    scrollButtons: {
      marginTop: 8,
      '&.Mui-disabled': {
        display: 'none',
      },
      '&:last-of-type': {
        marginLeft: 8,
      },
      '&:first-of-type': {
        marginRight: 8,
      },
    },
    scroller: {
      marginRight: -1,
      marginLeft: -1,
      marginBottom: '-1px !important',
    },
    flexContainer: {
      justifyContent: ({ isScroll }) => (isScroll ? 'flex-start' : 'flex-end'),
    },
  }),
);

const tabItemStyles = makeStyles(() =>
  createStyles({
    root: {
      background: '#FAFAFA',
      opacity: 1,
      outline: 'none !important',
      textTransform: 'none',
      minWidth: TAB_WIDTH,
      padding: 0,
      borderBottom: '1px solid #d3d6da',
    },
    selected: {
      borderBottom: 'none',
    },
    wrapper: {
      width: 'max-content',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#515D6B',
      marginTop: 8,
    },
  }),
);

const DatesContainer = styled.div`
  width: ${TABS_WIDTH}px;
`;

type Props = {
  dates: MedcardDate[];
  setDates: ActionCreatorWithNonInferrablePayload;
  pickDate: ActionCreatorWithNonInferrablePayload;
};

function DatesSelect({ dates, setDates, pickDate }: Props) {
  const [isScroll, setIsScroll] = useState(false);

  const tabsActionRef = useRef<TabsActions>(null);

  const tabsClasses = tabsStyles({ isScroll });
  const tabItemClasses = tabItemStyles();

  const { currentDateId } = useAppSelector((state) => state.teethFormulaState);
  const patient = useAppSelector((state) => state.medCardPageSlice.patientDetails);
  const { data = [], isLoading } = useGetDatesQuery(patient.id, { skip: !patient.id });

  useEffect(() => {
    if (!isLoading) {
      let nextId;

      if (data && data.length) {
        const today = !!data.find((d) => isToday(new Date(d.date)));
        nextId = today
          ? data[data.length - 1].id
          : data
              .map((item) => item.id)
              .sort()
              .at(-1) + 1;
        setDates(today ? data : [...data, { id: nextId, date: format(new Date(), 'yyyy-MM-dd') }]);
      } else {
        nextId = 0;
        setDates([{ id: nextId, date: format(new Date(), 'yyyy-MM-dd') }]);
      }
      pickDate(nextId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      setIsScroll(TAB_WIDTH * dates.length > TABS_WIDTH);
    }
  }, [dates, isLoading]);

  useEffect(() => {
    tabsActionRef?.current?.updateScrollButtons();
  });

  const theme = createTheme({
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <DatesContainer>
        <Tabs
          value={currentDateId}
          classes={tabsClasses}
          variant="scrollable"
          scrollButtons="auto"
          action={tabsActionRef}
        >
          {dates.map((date: MedcardDate, index: number) => {
            return (
              <Tab
                key={date.id}
                classes={tabItemClasses}
                value={date.id}
                label={
                  <DateBlock
                    isOnly={
                      dates.filter(
                        (d) => format(new Date(d.date), 'dd.MM.yyyy') === format(new Date(date.date), 'dd.MM.yyyy'),
                      ).length === 1
                    }
                    isScroll={isScroll}
                    date={date}
                    index={index}
                    length={dates.length}
                    activeIndex={dates.findIndex((d) => d.id === currentDateId)}
                  />
                }
              />
            );
          })}
        </Tabs>
      </DatesContainer>
    </MuiThemeProvider>
  );
}
export default connect(
  (state: RootState) => ({
    dates: state.teethFormulaState.dates,
    currentDateId: state.teethFormulaState.currentDateId,
  }),
  { setDates: TeethFormula.actions.setDates, pickDate: TeethFormula.actions.pickDate },
)(DatesSelect);
