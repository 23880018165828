import React, { useState } from 'react';
import styled from 'styled-components';
import { range } from 'ramda';

import { BlueRadioButton } from '../../PricePage/ChangeValueDialog/styles';
import ToothBlockUpper from './ToothBlockUpper';
import ToothBlockLower from './ToothBlockLower';
import DatesSelect from './DatesSelect';
import Jornal from '../Jornal';
import AddIcon from '@material-ui/icons/Add';
import { useAppSelector } from '../../../store/hooks';
import { useAddEntryMutation, useGetAllToothHistoryQuery, useGetDatesQuery } from '../../../services/medcard';
import { useDispatch } from 'react-redux';
import FormulaState from './state';
import { useGetChildFormulaQuery } from '../../../services/childFormula';
import { useAddInvoiceMutation, useAddSubInvoiceMutation } from '../../../services/invoice';
import { EAccountStatus } from '../Account/AccountStatus';

const { setExpanded, pickDate, addCurrentDate, setEmptyToothStateError } = FormulaState.actions;

const RightPatch = styled.div`
  position: absolute;
  top: -10px;
  right: -11px;
  width: 10px;
  height: 10px;
  background-color: rgb(249, 249, 249);
`;

const LeftPatch = styled.div`
  position: absolute;
  top: -1px;
  left: -10px;
  width: 10px;
  height: 10px;
  background-color: rgb(249, 249, 249);
  border-right: 1px solid #d3d6da;
`;

const FormulaContainer = styled.div`
  width: 100vw;
  padding-right: 60px;
  margin-top: 0px;
`;
const TeethFormula = styled.div`
  background: #ffffff;
  border-radius: 0 0 6px 6px;
  border: 1px solid #d3d6da;
  width: 1344px;
  box-sizing: border-box;
`;
const TeethFormulaLayout = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 440px;
  position: relative;
`;
const AlignCenter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UpperJaw = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
`;
const HorrizontalDivider = styled.div`
  position: absolute;
  top: 96px;
  left: 0;
  width: 953px;
  height: 1px;
  background: #d3d6da;
  border-radius: 2px;
`;
const VerticalDivider = styled.div`
  position: absolute;
  top: 0;
  left: 476px;
  background: #d3d6da;
  border-radius: 2px;
  height: 193px;
  width: 1px;
`;

const FormulaButtons = styled.div`
  position: relative;
  width: 0;
  height: 0;
`;

const TypeButtonsContainer = styled.div`
  display: flex;
  flex: 0;
  flex-direction: row;
  align-items: center;
`;

const TypeLabel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #253446;
  width: 120px;
`;

const RadioLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #515d6b;
`;

const NewEntyLink = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #577bf9;
  width: max-content;
  cursor: pointer;
`;

export enum FormulaType {
  Adult = 'ADULT',
  Child = 'CHILD',
}

interface IProps {
  onStartEdit?: (invoiceId: number) => void;
  onStopEdit?: (invoiceId: number) => void;
  onEditJournal?: (data: unknown) => void;
  session: {
    session_user_id: number;
    session_user_name: string;
  };
}
export default function Formula({ onStartEdit, onStopEdit, onEditJournal, session }: IProps) {
  const { currentDateId } = useAppSelector((state) => state.teethFormulaState);
  const patient = useAppSelector((state) => state.medCardPageSlice.patientDetails);
  const doctor = useAppSelector((state) => state.common.user);
  const [addEntry] = useAddEntryMutation();
  const [addInvoice] = useAddInvoiceMutation();
  const [addSubInvoice] = useAddSubInvoiceMutation();

  const { data: dates = [] } = useGetDatesQuery(patient.id, { skip: !patient.id });
  const { data: childFormula = [] } = useGetChildFormulaQuery(patient.id, { skip: !patient.id });
  const { data: allToothHistory = {} } = useGetAllToothHistoryQuery(patient.id, { skip: !patient.id });

  const currentDate = dates.find((d) => d.id === currentDateId);

  const [formulaType, setFormulaType] = useState(FormulaType.Adult);
  const [toothMenuId, openMenuFor] = useState(null);
  const [historyMenuId, setHistoryMenuId] = useState(null);

  const dispatch = useDispatch();
  return (
    <FormulaContainer>
      <AlignCenter>
        <DatesSelect />
        <TeethFormula>
          <FormulaButtons>
            <TypeButtonsContainer style={{ position: 'absolute', top: 26, left: 24 }}>
              <TypeLabel>Зубная формула</TypeLabel>
              <BlueRadioButton
                size={'small'}
                checked={formulaType === FormulaType.Adult}
                onChange={() => {
                  setFormulaType(FormulaType.Adult);
                }}
              />
              <RadioLabel>Постоянная</RadioLabel>
              <BlueRadioButton
                size={'small'}
                checked={formulaType === FormulaType.Child}
                onChange={() => {
                  setFormulaType(FormulaType.Child);
                }}
              />
              <RadioLabel>Молочная</RadioLabel>
            </TypeButtonsContainer>
            <div style={{ position: 'absolute', top: 400, left: 24, zIndex: 1 }}>
              <NewEntyLink
                onClick={() => {
                  dispatch(setEmptyToothStateError(true));
                  addEntry({
                    entry: {
                      doctor: doctor.id,
                      date_id: currentDate?.id,
                      formula_type: formulaType,
                      tooth_index: '',
                      tooth_state_mnemonic: '',
                      tooth_treatment_mnemonic: '',
                    },
                    patientId: patient.id,
                  })
                    .unwrap()
                    .then(({ id, date_id }) => {
                      setTimeout(() => dispatch(setExpanded(id)));
                      if (!currentDate?.id) {
                        dispatch(addCurrentDate(date_id));
                        dispatch(pickDate(date_id));
                        addInvoice({ date_id })
                          .unwrap()
                          .then(({ id: invoiceId }) => {
                            addSubInvoice({ invoice: invoiceId, formula_id: id, status: EAccountStatus.NOT_FILLED });
                          });
                      }
                    });
                }}
              >
                Добавить запись без выбора зуба
                <AddIcon color="inherit" fontSize="small" style={{ marginLeft: 4, marginTop: -2 }} />
              </NewEntyLink>
            </div>
          </FormulaButtons>

          <TeethFormulaLayout>
            <LeftPatch />
            <RightPatch />
            <div style={{ width: 937, position: 'relative' }}>
              <HorrizontalDivider />
              <VerticalDivider />
              <UpperJaw>
                {range(0, 16).map((item, index) => (
                  <ToothBlockUpper
                    key={item}
                    bottom={false}
                    toothMenuId={toothMenuId}
                    index={index}
                    openMenuFor={openMenuFor}
                    formulaType={formulaType}
                    toothIndex={
                      formulaType === FormulaType.Adult
                        ? index <= 7
                          ? `1.${8 - index}`
                          : `2.${index - 7}`
                        : index <= 7
                        ? !!childFormula.find((e) => e === `5.${8 - index}`)
                          ? `5.${8 - index}`
                          : `1.${8 - index}`
                        : !!childFormula.find((e) => e === `6.${index - 7}`)
                        ? `6.${index - 7}`
                        : `2.${index - 7}`
                    }
                    childFormula={childFormula}
                    patientId={patient.id}
                    doctor={doctor}
                    dateId={currentDate?.id}
                    allToothHistory={allToothHistory}
                    historyMenuId={historyMenuId}
                    setHistoryMenuId={setHistoryMenuId}
                  />
                ))}
              </UpperJaw>
              <UpperJaw>
                {range(0, 16).map((item, index) => (
                  <ToothBlockLower
                    key={item}
                    bottom
                    toothMenuId={toothMenuId}
                    index={index}
                    openMenuFor={openMenuFor}
                    formulaType={formulaType}
                    toothIndex={
                      formulaType === FormulaType.Adult
                        ? index <= 7
                          ? `4.${8 - index}`
                          : `3.${index - 7}`
                        : index <= 7
                        ? !!childFormula.find((e) => e === `8.${8 - index}`)
                          ? `8.${8 - index}`
                          : `4.${8 - index}`
                        : !!childFormula.find((e) => e === `7.${index - 7}`)
                        ? `7.${index - 7}`
                        : `3.${index - 7}`
                    }
                    childFormula={childFormula}
                    patientId={patient.id}
                    doctor={doctor}
                    dateId={currentDate?.id}
                    allToothHistory={allToothHistory}
                    historyMenuId={historyMenuId}
                    setHistoryMenuId={setHistoryMenuId}
                  />
                ))}
              </UpperJaw>
            </div>
          </TeethFormulaLayout>
          <Jornal
            formulaType={formulaType}
            currentDate={currentDate}
            onStartEdit={onStartEdit}
            onStopEdit={onStopEdit}
            onEditJournal={onEditJournal}
            session={session}
          />
        </TeethFormula>
      </AlignCenter>
    </FormulaContainer>
  );
}
