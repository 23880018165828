import styled from 'styled-components';

export const CommentIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
`;

export const TableWrapper = styled.div`
  [data-wrapper='wrapper']:not(:last-of-type) {
    position: relative;
    :after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 12px;
      right: 12px;
      height: 1px;
      background: #eff0f3;
    }
  }
`;

export const TableContainer = styled.div`
  display: block;
  max-width: 100%;
  table {
    font-size: 12px;
    button {
      padding: 2px 0 2px 0;
    }
    th,
    td {
      padding: 12px;
      border: 0;
      input {
        font-size: 12px;
      }
    }
    td:last-child {
      padding: 0 44px 0 0;
    }
    tr {
      position: relative;
      :before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 12px;
        right: 12px;
        height: 1px;
        background: #eff0f3;
      }
      &.active {
        :before {
          height: 100%;
          box-shadow: inset 0 0 0 1px #577bf9;
          inset: 0;
          background: transparent;
          z-index: 1;
        }
      }
    }
  }
`;

export const TableTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #253446;
  margin-top: 12px;
  margin-left: 12px;
`;

export const TableSubTitle = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: #a8aeb5;
  margin-top: 16px;
  margin-left: 12px;
`;
