import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Prepayment } from './components/Prepayment';
import { Debt } from './components/Debt';
import OutlinedButton from '../../../uikit/Button/OutlinedButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { EmptyPage } from '../../../widgets/EmptyPage';
import { FooterActions } from './FooterActions';
import { CommonContainer, Footer, Header, InvoiceBlock, MainContainer } from './styles';
import {
  invoiceApi,
  useAbortMutation,
  useGetSubInvoicesQuery,
  useUpdateSubInvoiceMutation,
} from '../../../services/invoice';
import { EAccountStatus } from './AccountStatus';
import { PaymentHeader } from './PaymentHeader';
import { InvoiceOptionsMenu } from './InvoiceOptionsMenu';
import { ROOT_CONTAINER } from '../../../uikit/Modal/constants';
import { useCommentFormHeight, useScroll } from '../../../uikit/Modal/hooks';
import { FooterTotalRow } from './FooterTotalRow';
import { OverPayWarning } from './OverPayWarning';
import { AdvanceOperation, FooterTitles, ScrollHeight } from './enums';
import {
  PayerCodes,
  useAddPayerMutation,
  useGetPayersQuery,
  useGetPaymentTypesQuery,
} from '../../../services/dictionaries';
import { advanceApi, useAddAdvanceMutation } from '../../../services/advance';
import { SubInvoiceStatus, useCalculateInvoiceMutation, useGetDebtAmountQuery } from '../../../services/billing';
import PaymentAccountSlice from './state';
import { useAppDispatch } from '../../../store/hooks';
import request from '../../../utils/request';
import { printPdf } from '../../../utils/print';
import { VisitHeader } from '../../VisitsAndPaymentsPage/components/VisitHeader';
import { VisitComments } from '../../../widgets/VisitComments';
import { InvoiceContainer } from './InvoiceContainer';
import { useGetInvoicesList } from './hooks';
import { groupBy, has } from 'lodash';
import medCardPageSlice from '../../../pages/MedcardPage/state';
import { useGetPermissionsQuery } from '../../../services/users';
import { toast } from 'react-hot-toast';
import { DEFAULT_PAYMENT_TYPE, EMPTY, STARTED_AMOUNT } from './constants';
import { getCurrentPayerType } from './utils';
import { medcardJournalApi } from '../../../services/medcardJournal';

type Props = {
  setPlainView: VoidFunction;
  setAccountVisible: (visible: boolean) => void;
  currentDateId?: number;
  onStopEdit: VoidFunction;
};

export function PaymentAccount({ setAccountVisible, setPlainView, currentDateId, onStopEdit }: Props) {
  const dispatch = useAppDispatch();
  const operationItems = useSelector((state: RootState) => state.PaymentAccountSlice.operationItems);
  const totalPayType = useSelector((state: RootState) => state.PaymentAccountSlice.totalPayType);
  const patient = useSelector((state: RootState) => state.medCardPageSlice.patientDetails);
  const showPayment = useSelector((state: RootState) => state.medCardPageSlice.showPayment);
  const staff = useSelector((state: RootState) => state.staff.responce);
  const user = useSelector((state: RootState) => state.common.user);
  const { setTotalPayType } = PaymentAccountSlice.actions;
  const { updateOperationItems } = PaymentAccountSlice.actions;
  const { cleanupState } = PaymentAccountSlice.actions;

  const { data: debtAmount, refetch: refreshDebt } = useGetDebtAmountQuery(patient.id, {
    refetchOnMountOrArgChange: true,
  });
  const { data: paymentTypes = [] } = useGetPaymentTypesQuery();
  const { data: payers = [] } = useGetPayersQuery();
  const { data: permissions } = useGetPermissionsQuery(user.id);

  const {
    invoiceList: invoiceListData,
    isInvoicesLoading,
    currentInvoiceId,
    refreshInvoiceList,
  } = useGetInvoicesList(patient.id, currentDateId);
  const [invoiceList, setInvoiceList] = useState([]);

  const { data: subInvoices = [] } = useGetSubInvoicesQuery(currentInvoiceId, { skip: !currentInvoiceId });
  const subInvoicesForPay = subInvoices.filter(
    (subInvoice) => subInvoice.status === EAccountStatus.PENDING_PAYMENT || subInvoice.status === EAccountStatus.DEBT,
  );

  const [addAdvance] = useAddAdvanceMutation();
  const [addPayer] = useAddPayerMutation();
  const [abortInvoice] = useAbortMutation();
  const [calculateInvoice] = useCalculateInvoiceMutation();
  const [updateSubInvoice] = useUpdateSubInvoiceMutation();

  const [theadEl, setTheadEl] = useState<null | HTMLElement>(null);
  const [containerMargin, setContainerMargin] = useState<number>(STARTED_AMOUNT);
  const [isBalanceChanging, setIsBalanceChanging] = useState<boolean>(false);
  const [hasBalanceChanged, setHasBalanceChanged] = useState<boolean>(false);
  const [totalPaidInputValue, setTotalPaidInputValue] = useState<number>(STARTED_AMOUNT);
  const [totalPrepayInputValue, setTotalPrepayInputValue] = useState<number>(STARTED_AMOUNT);
  const [invoiceScrollHeight, setInvoiceScrollHeight] = useState<number>(ScrollHeight.MIN);
  const [isPrepayWarning, setIsPrepayWarning] = useState<boolean>(false);
  const [totalPrepayType, setTotalPrepayType] = useState<number>(DEFAULT_PAYMENT_TYPE);
  const [totalPrepayPayer, setTotalPrepayPayer] = useState<string>(EMPTY);
  const [overPayAmount, setOverPayAmount] = useState<number>(STARTED_AMOUNT);
  const [calculateLoading, setCalculateLoading] = useState<boolean>(false);

  // контейнер, по которому позиционируются модальные окна
  const containerRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef(null);
  const scrollTop = useScroll(modalRef);

  const { modalHeight: commentFormHeight, setModalHeight } = useCommentFormHeight(modalRef);
  const { setPaymentVisible } = medCardPageSlice.actions;

  const totalForPay = operationItems.reduce((sum, item) => {
    return sum + item.totalRowDebt;
  }, STARTED_AMOUNT);

  const totalDifference = totalPaidInputValue - totalForPay;

  const getInvoiceAuthor = (employeeId) => {
    return staff.find((employee) => employee.id === employeeId);
  };

  const addAdvanceHandler = async () => {
    let advanceId;
    const newAdvance = {
      operation_type: AdvanceOperation.INCOME,
      payment: totalPrepayInputValue,
      patient: patient.id,
      payment_type: totalPrepayType ? totalPrepayType : totalPayType,
      payer: payers.find((payer) => payer.code === PayerCodes.PATIENT)?.id,
      paymaster: user.id,
    };
    const currentPaymentType = paymentTypes.find((type) => type.id === totalPrepayType)?.code;
    const existedPayer = payers.find((payer) => payer.title === totalPrepayPayer);
    if (!existedPayer) {
      const newPayer = await addPayer({ title: totalPrepayPayer, payer_type: getCurrentPayerType(currentPaymentType) });
      advanceId = await addAdvance({ patientId: patient.id, record: { ...newAdvance, payer: newPayer.data.id } })
        .unwrap()
        .then(({ id }) => id);
      return advanceId;
    }
    advanceId = await addAdvance({ patientId: patient.id, record: { ...newAdvance, payer: existedPayer.id } })
      .unwrap()
      .then(({ id }) => id);
    return advanceId;
  };

  const getCalculatedRows = () => {
    const rowsWithPaymentAmount = [];
    const getRowsForPayment = (arr) =>
      arr.map(({ invoice_row, sub_invoice_id, operations }) => ({ invoice_row, sub_invoice_id, operations }));
    const transformOperations = (arr) =>
      arr.map(({ payment, payment_type, payer }) => ({ payment, payment_type, payer }));

    operationItems.forEach((item) => {
      const rowsWithPayment = item.operations.filter((operation) => operation.payment !== 0 && operation.isNew);
      if (rowsWithPayment.length) {
        return rowsWithPaymentAmount.push({ ...item, operations: transformOperations(rowsWithPayment) });
      }
    });

    if (!rowsWithPaymentAmount.length) return [];

    const groupedRows = groupBy(rowsWithPaymentAmount, 'invoice_id');
    return Object.entries(groupedRows).map(([key, rows]) => {
      return { invoiceId: +key, record: getRowsForPayment(rows) };
    });
  };

  const calculateFinalInvoice = async (print: boolean) => {
    setCalculateLoading(true);
    let advanceId;
    const calculatedRows = getCalculatedRows();

    if (!calculatedRows.length) {
      toast.error('Ошибка! не внесена сумма оплаты');
      setCalculateLoading(false);
      return;
    }

    const calculatedResult = await Promise.all(calculatedRows.map((row) => calculateInvoice(row)));

    if (has(calculatedResult[0], 'error')) {
      toast.error('Ошибка рассчета! не заполнены обязательные поля Плательщик/Способ оплаты');
      setCalculateLoading(false);
      return;
    }

    if (isBalanceChanging && totalPrepayInputValue) {
      advanceId = await addAdvanceHandler();
    }

    dispatch(advanceApi.util.invalidateTags(['AdvancesTotal']));
    dispatch(invoiceApi.util.invalidateTags(['InvoiceCommonRows']));

    if (print) {
      try {
        const calcRows = groupBy(
          calculatedRows.flatMap((row) => row.record),
          'sub_invoice_id',
        );

        const result = await request.clinic.print_invoice(patient.id, calcRows, advanceId);
        printPdf(result.data);
      } catch (_) {
        toast.error('Ошибка печати платежной квитанции');
      }
    }

    if (showPayment) {
      dispatch(setPaymentVisible(false));
    } else {
      setAccountVisible(false);
    }

    dispatch(medcardJournalApi.util.invalidateTags(['MedcardPlanJournalField']));
    setCalculateLoading(false);
    onStopEdit?.();
  };

  const calculatePaidAmount = useCallback(
    (total: number) => {
      let restTotal = total;
      const newItemsRows = operationItems.map((row) => {
        const rowOperations = row.operations.map((operation) => {
          if (!operation.isNew) return operation;
          const rowPayment = restTotal > operation.amountForPay ? operation.amountForPay : restTotal;
          restTotal -= rowPayment;
          return { ...operation, payment: rowPayment };
        });
        return { ...row, operations: rowOperations };
      });
      dispatch(updateOperationItems(newItemsRows));
    },
    [operationItems, dispatch, updateOperationItems],
  );

  const onSetTotalPaymentType = useCallback(
    (typeId: number) => {
      const newItemsRows = operationItems.map((row) => {
        const rowOperations = row.operations.map((operation) => {
          if (!operation.isNew) return operation;
          return { ...operation, payment_type: typeId };
        });
        return { ...row, operations: rowOperations };
      });

      dispatch(updateOperationItems(newItemsRows));
      dispatch(setTotalPayType(typeId));
    },
    [operationItems, dispatch, updateOperationItems, setTotalPayType],
  );

  const handleMakeCashBack = () => {
    setIsPrepayWarning(false);
    setHasBalanceChanged(true);
    setTotalPaidInputValue(totalPaidInputValue - totalDifference);
  };

  const handleDeleteInvoice = useCallback(
    (invoiceId) => {
      abortInvoice(invoiceId);
      if (invoiceList?.length === 1) {
        setInvoiceList([]);
        onStopEdit?.();
      } else {
        setInvoiceList(invoiceList.filter((item) => item.invoice.id !== invoiceId));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invoiceList],
  );

  useEffect(() => {
    return () => {
      dispatch(cleanupState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (containerRef.current && !containerMargin && invoiceList.length) {
      setContainerMargin(containerRef.current.getBoundingClientRect().left);
    }
  }, [containerRef, containerMargin, invoiceList]);

  useEffect(() => {
    scrollTop();
  });

  useEffect(() => {
    if (payers.length) {
      const defaultPrepayPayer = payers.find((payer) => payer.code === PayerCodes.PATIENT)?.title;
      setTotalPrepayPayer(defaultPrepayPayer);
    }
  }, [payers]);

  useEffect(() => {
    if (isBalanceChanging || hasBalanceChanged) {
      setInvoiceScrollHeight(ScrollHeight.MAX);
      setModalHeight(commentFormHeight - (ScrollHeight.MAX - ScrollHeight.MIN));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBalanceChanging, hasBalanceChanged, setModalHeight]);

  useEffect(() => {
    if (!isInvoicesLoading) {
      setInvoiceList(invoiceListData);
    }
  }, [isInvoicesLoading, invoiceListData]);

  const renderContent = () => {
    if (isInvoicesLoading) {
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '330px',
          }}
        >
          <div className="spinner" />
        </div>
      );
    }
    if (!invoiceList.length) {
      return (
        <EmptyPage source="./img/grey/credit-card.svg" marginBottom={17}>
          <button
            onClick={() => {
              dispatch(setPaymentVisible(false));
              if (currentDateId) {
                setPlainView();
              } else {
                setAccountVisible(false);
                onStopEdit?.();
              }
            }}
          >
            Добавьте позицию
          </button>
          для выставления оплаты
        </EmptyPage>
      );
    }
    return (
      <MainContainer ref={containerRef}>
        <div style={{ marginBottom: '14px' }}>
          <Scrollbars
            autoHide
            autoHeight
            autoHeightMax={`calc(100vh - ${invoiceScrollHeight}px)`}
            ref={modalRef}
            style={{
              width: '100%',
            }}
          >
            <div id={ROOT_CONTAINER}>
              {invoiceList.map((visit) => {
                const { appointment, invoice } = visit;
                const invoiceAuthor = getInvoiceAuthor(visit.hasAppointment ? appointment.doctor : invoice.paymaster);
                const enableDeleteAccount =
                  (permissions['delete_account'] || invoice.paymaster === user.id) &&
                  invoice.status !== SubInvoiceStatus.DEBT &&
                  invoice.status !== SubInvoiceStatus.PAID;
                return (
                  <InvoiceBlock key={invoice.id}>
                    <Header backColor={invoiceAuthor?.color}>
                      <VisitHeader
                        isAppointmentHeader={visit.hasAppointment}
                        employee={invoiceAuthor?.fullname}
                        employeeJob={invoiceAuthor?.job}
                        visitStartedAt={visit.hasAppointment ? appointment.starts_at : ''}
                        visitEndedAt={visit.hasAppointment ? appointment.ends_at : ''}
                        invoiceCreatedAt={visit.hasInvoice ? invoice.created_at : ''}
                      />
                      <InvoiceOptionsMenu
                        onDeleteInvoice={handleDeleteInvoice}
                        handleScrollTop={scrollTop}
                        invoiceId={invoice.id}
                        enableDeleteAccount={enableDeleteAccount}
                      />
                      <VisitComments
                        invoiceId={invoice.id}
                        commentFormHeight={commentFormHeight}
                        scrollTop={scrollTop}
                      />
                    </Header>
                    <InvoiceContainer
                      invoiceId={invoice.id}
                      handleSetTheadEl={setTheadEl}
                      handleScrollTop={scrollTop}
                      onSetTotalPaidInputValue={setTotalPaidInputValue}
                      invoicePaymaster={invoice.paymaster}
                      currentUser={user.id}
                      onRefreshInvoiceList={refreshInvoiceList}
                      onRefreshDebtAmount={refreshDebt}
                      containerRef={containerRef}
                      commentFormHeight={commentFormHeight}
                    />
                  </InvoiceBlock>
                );
              })}
            </div>
          </Scrollbars>
        </div>
        <Footer>
          <FooterTotalRow
            theadEl={theadEl}
            containerMargin={containerMargin}
            title={FooterTitles.TOTAL}
            totalForPay={totalForPay}
            totalRemainder={totalDifference}
            setAmountInputValue={setTotalPaidInputValue}
            isFirstRow
            paymentType={totalPayType}
            changePaymentTypeHandler={onSetTotalPaymentType}
            value={totalPaidInputValue}
            onCalculatePaidAmount={calculatePaidAmount}
            containerRef={containerRef}
          />
          {isBalanceChanging && !hasBalanceChanged && (
            <>
              <FooterTotalRow
                theadEl={theadEl}
                containerMargin={containerMargin}
                title={FooterTitles.PREPAY}
                setAmountInputValue={setTotalPrepayInputValue}
                isPrepayRow
                paymentType={totalPrepayType}
                payer={totalPrepayPayer}
                changePaymentTypeHandler={setTotalPrepayType}
                changePayerHandler={setTotalPrepayPayer}
                value={totalPrepayInputValue}
                containerRef={containerRef}
              />
              <FooterTotalRow
                theadEl={theadEl}
                containerMargin={containerMargin}
                title={FooterTitles.PAID}
                isTotalPaidRow
                totalPaidAmount={totalPaidInputValue + totalPrepayInputValue}
              />
            </>
          )}
          {hasBalanceChanged && (
            <FooterTotalRow
              theadEl={theadEl}
              containerMargin={containerMargin}
              title={FooterTitles.CASHBACK}
              totalRemainder={overPayAmount}
              isTotalPaidRow
              hasBalanceChanged
            />
          )}
        </Footer>
      </MainContainer>
    );
  };

  return (
    <>
      <PaymentHeader
        id={patient.id}
        name={patient.first_name}
        middleName={patient.last_name}
        surname={patient.second_name}
      />
      <CommonContainer>
        <div style={{ height: '100%', width: '100%', paddingTop: 24 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24, padding: '0 24px' }}>
            <div style={{ display: 'flex' }}>
              <Prepayment
                editMode={isBalanceChanging}
                patientId={patient.id}
                onSetIsBalanceChanging={setIsBalanceChanging}
              />
              <Debt debtAmount={debtAmount} />
            </div>
            {!!subInvoicesForPay.length ? (
              <OutlinedButton
                width={133}
                height={32}
                style={{ background: 'white' }}
                onClick={async () => {
                  await Promise.all(
                    subInvoicesForPay.map(
                      async (subInvoice) =>
                        await updateSubInvoice({
                          id: subInvoice.id,
                          status: EAccountStatus.EDIT,
                        }).unwrap(),
                    ),
                  );
                  setPlainView();
                }}
              >
                Редактировать
              </OutlinedButton>
            ) : null}
          </div>
          {renderContent()}
        </div>
        {!!invoiceList.length && (
          <FooterActions
            onSetAccountVisible={setAccountVisible}
            handleSetIsPrepayWarning={setIsPrepayWarning}
            overPayAmount={totalDifference}
            onCalculateInvoice={calculateFinalInvoice}
            onStopEdit={onStopEdit}
            calculateLoading={calculateLoading}
          />
        )}
        <OverPayWarning
          open={isPrepayWarning}
          onMakeCashBack={handleMakeCashBack}
          onClose={setIsPrepayWarning}
          totalDifference={totalDifference}
          onSetOverPayAmount={setOverPayAmount}
          onSetIsBalanceChanging={setIsBalanceChanging}
          onSetAddedAdvanceAmount={setTotalPrepayInputValue}
          onSetPaidValue={setTotalPaidInputValue}
          paidAmount={totalPaidInputValue}
        />
      </CommonContainer>
    </>
  );
}
