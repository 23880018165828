import React, { FC } from 'react';
import { Modal } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import TextArea from '../../../../uikit/TextArea';
import { routerConfig } from '../../../../navigation/routerConfig';
import format from 'date-fns/format';
import ruLocale from 'date-fns/locale/ru';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import DoctorSelect from './components/DoctorSelect';
import { useForm } from 'react-hook-form';
import { IVisitCardProps } from './types';
import VisitStatusSelect from './components/VisitStatusSelect';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import medCardPageSlice from '../../../MedcardPage/state';
import { Card, Header, Title, Label, Row, BlueTextButton, BlueButton, Link, useStyles } from './styles';
import { useGetStaffByClinicQuery, UserRole } from '../../../../services/users';
import { useGetAppointmentByIdQuery, useUpdateAppointmentMutation } from '../../../../services/appointments';
import { setCancellingAppointmentData, setClickedAppointmentId } from '../../reducer';
import {
  moveOrCopyAppointment,
  updateTableAppointment,
  checkForTimeMatches,
  getFilteredEvents,
} from '../../ScheduleTable/functions';
import { toast } from 'react-hot-toast';
import { useGetPatientsQuery } from '../../../../services/patients';

const VisitCard: FC<IVisitCardProps> = ({ appointmentId, handleStopEdit }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { register, handleSubmit, setValue } = useForm();
  const navigate = useNavigate();

  const clinicId = useAppSelector((state) => state.common.user.clinic.id);
  const doctors = useSelector((state: RootState) => state.staff.responce).filter((el) => el.role === UserRole.Doctor);

  const { data: patientsDictionary = [], isLoading: patientsAreLoading } = useGetPatientsQuery(clinicId);
  const { data: staffersDictionary = [], isLoading: staffersAreLoading } = useGetStaffByClinicQuery(clinicId);
  const { data: appointment, isLoading: appointmentIsLoading } = useGetAppointmentByIdQuery({
    id: appointmentId,
    clinicId,
  });

  const { id, cabinet, status, comment, ends_at, starts_at } = appointment ?? {};
  const doctor = staffersDictionary.find((el) => el.id === appointment?.doctor);
  const patient = patientsDictionary.find((el) => el.id === appointment?.patient);

  const { permissions } = useSelector((state: RootState) => state.common.user);
  const schedulerAPI = useAppSelector((state) => state.reworkedSchedule.schedulerAPI);

  const { setPatientDetails } = medCardPageSlice.actions;

  const [updateAppointment] = useUpdateAppointmentMutation();

  const onClose = () => {
    dispatch(setClickedAppointmentId(null));
  };

  const onSubmit = async ({ status, doctor, comment }) => {
    if (!(appointment.status === status && appointment.doctor === doctor && appointment.comment === comment)) {
      updateAppointment({
        id,
        clinic: clinicId,
        status: status || 'SCH',
        doctor,
        comment,
      })
        .unwrap()
        .then(() => {
          updateTableAppointment(
            id,
            null,
            null,
            null,
            null,
            comment,
            doctors.find((d) => d.id === doctor),
            status,
            schedulerAPI,
          );

          if (new Date(starts_at).getTime() > new Date().getTime()) {
            checkForTimeMatches(id, schedulerAPI);
          }
        })
        .catch(() => {
          toast.error(`Произошла техническая ошибка, попробуйте отредактировать визит позже`);
        })
        .finally(() => {
          onClose();
          handleStopEdit(id);
        });
    }
  };

  const onDelete = () => {
    dispatch(setCancellingAppointmentData(appointment));
    onClose();
  };

  const onMove = () => {
    moveOrCopyAppointment(
      id,
      starts_at,
      ends_at,
      cabinet,
      status,
      comment,
      patient,
      doctor,
      false,
      schedulerAPI,
      dispatch,
      handleStopEdit,
      false,
    );
    onClose();
  };

  const onCopy = () => {
    const schedulerAppointment =
      id && schedulerAPI ? getFilteredEvents(schedulerAPI.getEvents(), 'appointments', id)[0] : null;
    const isAppointmentIrrelevant = schedulerAppointment?._def.extendedProps.isIrrelevant;

    moveOrCopyAppointment(
      id,
      starts_at,
      ends_at,
      cabinet,
      status,
      comment,
      patient,
      doctor,
      isAppointmentIrrelevant,
      schedulerAPI,
      dispatch,
      handleStopEdit,
      true,
    );
    onClose();
  };

  return (
    <Modal
      open
      onClose={() => {
        onClose();
        handleStopEdit(id);
      }}
      id="visit-card"
      className={classes.modal}
    >
      {appointmentIsLoading || patientsAreLoading || staffersAreLoading ? (
        <div className="spinner" />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <Header>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ScheduleOutlinedIcon style={{ fontSize: 17, marginRight: '5px' }} />
                <span>
                  {format(new Date(starts_at), 'dd MMM yyyy', {
                    locale: ruLocale,
                  })}{' '}
                  {`${format(new Date(starts_at), 'HH:mm')}-${format(new Date(ends_at), 'HH:mm')}`}
                </span>
              </div>
              <VisitStatusSelect visitStatus={status} register={register} setValue={setValue} />
            </Header>
            <Title>{`${patient?.last_name} ${patient?.first_name} ${patient?.second_name ?? ''}`}</Title>
            <div>
              <Row>
                <Label>Медицинская карта</Label>
                <Link
                  onClick={async () => {
                    await dispatch(setPatientDetails(patient));
                    navigate(routerConfig.MEDCARD.path);
                  }}
                >
                  {patient && `№ ${patient?.id}`}
                </Link>
              </Row>
              {permissions.view_patient_contacts && (
                <>
                  <Row>
                    <Label>Телефон</Label>
                    <div>{patient?.phone}</div>
                  </Row>
                  {patient?.additional_phone && (
                    <Row>
                      <Label>Доп. телефон</Label>
                      <div>{patient?.additional_phone}</div>
                    </Row>
                  )}
                  <Row>
                    <Label>Email</Label>
                    <div>{patient?.email}</div>
                  </Row>
                </>
              )}
              <Row>
                <Label>Плательщик</Label>
                <div>{patient && `${patient?.last_name} ${patient?.first_name} ${patient?.second_name}`}</div>
              </Row>
              <Row>
                <Label>Врач</Label>
                {doctor && (
                  <DoctorSelect
                    doctorId={doctor.id}
                    doctorName={doctor.fullname}
                    register={register}
                    setValue={setValue}
                  />
                )}
              </Row>
            </div>
            {permissions['view_payment'] && (
              <Link
                style={{
                  marginBottom: '36px',
                  width: '120px',
                  height: '36px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={async () => {
                  await dispatch(setPatientDetails(patient));
                  navigate(routerConfig.VISITS_AND_PAYMENTS.path);
                }}
              >
                Визиты и оплаты
              </Link>
            )}
            <TextArea
              multiline
              rows={5}
              placeholder="Оставить комментарий..."
              style={{ marginBottom: '24px' }}
              defaultValue={comment}
              {...register('comment')}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex' }}>
                <BlueTextButton onClick={onDelete}>Удалить</BlueTextButton>
                <BlueTextButton onClick={onCopy} id="copyButton">
                  Копировать
                </BlueTextButton>
                <BlueTextButton onClick={onMove} id="moveButton">
                  Перенести
                </BlueTextButton>
              </div>
              <BlueButton type="submit">Сохранить</BlueButton>
            </div>
          </Card>
        </form>
      )}
    </Modal>
  );
};

export default VisitCard;
