import React, { useState } from 'react';
import { connect } from 'react-redux';

import TextInput from '../../../uikit/TextInput';
import PatientState from '../state';
import { HeaderColumn } from '../style';
import { searchForPatient } from '../operations';
import { getMask } from '../../../utils/masks';
import { RootState } from '../../../store';
import { Phone } from '../../../uikit/Phone';

function SearchHeader({
  patientsPage,
  width,
  searchForPatient,
  searchFor,
  type,
  text,
  name,
  multiline = false,
  paddingLeft,
  paddingRight,
}) {
  const [inputValue, setInputValue] = useState('');
  const [isMouseOver, setMouseOver] = useState(false);
  const input_mask = getMask(type);
  const queryIsEmpty =
    // eslint-disable-next-line no-prototype-builtins
    !patientsPage.searchQuery.hasOwnProperty(name) ||
    patientsPage.searchQuery[name].trim().length < 1 ||
    patientsPage.searchQuery[name].trim() === '+7';

  return (
    <HeaderColumn
      style={{ paddingTop: isMouseOver ? 8 : multiline ? 4 : 16 }}
      onClick={() => {
        setMouseOver(true);
      }}
      onMouseOver={() => {
        setMouseOver(true);
      }}
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        if (queryIsEmpty) {
          setMouseOver(false);
        }
      }}
      width={width}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
    >
      {isMouseOver ? (
        <div style={{ height: 32, marginLeft: 0, width: '100%' }}>
          {type === 'phone' ? (
            <Phone
              onBlur={(e) => {
                if (!e.target.value.trim().length) {
                  setMouseOver(false);
                }
              }}
              onChange={(value, code) => () => {
                searchFor({ [name]: `+${code} ${value.trim()}` });
                searchForPatient();
              }}
              underlined
              placeholder="Поиск"
            />
          ) : (
            <TextInput
              value={inputValue}
              onBlur={(e) => {
                const value = input_mask.resolve(e.target.value);
                setInputValue(value);
                if (value.trim().length < 1) {
                  setMouseOver(false);
                }
              }}
              placeholder="Поиск"
              onChange={(e) => {
                const value = input_mask.resolve(e.target.value);
                setInputValue(value);
                searchFor({ [name]: value.trim() });
                searchForPatient();
              }}
              underlined={true}
            />
          )}
        </div>
      ) : (
        <div style={{ paddingLeft: 8 }}>{text}</div>
      )}
    </HeaderColumn>
  );
}

export default connect((state: RootState) => ({ patientsPage: state.patientsPage }), {
  searchFor: PatientState.actions.searchFor,
  searchForPatient,
})(SearchHeader);
