import React, {ChangeEvent, cloneElement, MouseEvent, ReactElement, useState} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import TextInput from '../../../uikit/TextInput';
import PatientsState from '../state';
import EmailText from './EmailText';
import { format } from 'date-fns';
import rulocale from 'date-fns/locale/ru';
import { Controller, useFormContext } from 'react-hook-form';
import ErrorPopup from '../../../components/ErrorPopup';
import { isObject } from 'lodash';
import { RootState } from '../../../store';
import { ActionCreatorWithNonInferrablePayload } from '@reduxjs/toolkit';

type ColumnColProps = {
  width?: number | string;
  fontSize?: number;
  paddingLeft?: number;
  marginRight?: number;
  hasAction?: boolean;
  fontWeightBold?: boolean;
  textAlign?: string;
  color?: string;
  flexGrow?: number;
};

export const ColumnLayout = styled.div<ColumnColProps>`
  display: flex;
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex-direction: column;
  justify-content: center;
  width: ${({ width }) => (width ? (isNaN(+width) ? width : `${width}px`) : '100%')};
  height: 54px;
  font-style: normal;
  font-weight: ${({ fontWeightBold }) => (fontWeightBold ? '500' : 'normal')};
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: 22px;
  color: ${({ color }) => color};
  padding-left: ${({ paddingLeft }) => paddingLeft}px;
  margin-right: ${({ marginRight }) => marginRight}px;
  cursor: ${({ hasAction }) => (hasAction ? 'pointer' : 'auto')};
  text-align: ${({ textAlign }) => textAlign};
  position: relative;
`;
const InputContainer = styled.div`
  height: 32px;
  margin-left: -8px;
  margin-right: 8px;
  position: relative;
`;

function parseDate(date: string | ReactElement = ''): string | ReactElement {
  let value = isObject(date) ? date.props.children : date;
  const date_arr: string[] = value && value.split('-');
  value =
    value &&
    format(new Date(parseInt(date_arr[0]), parseInt(date_arr[1]) - 1, parseInt(date_arr[2])), 'dd.MM.yyyy', {
      locale: rulocale,
    });
  return isObject(date) ? cloneElement(date, { children: value }) : value;
}

interface ColumnBaseProps {
  children: ReactElement;
  name: string;
  width?: number | string;
  isEditing: boolean;
  hasAction?: boolean;
  onClick?: VoidFunction;
  onEdit?: (event: ChangeEvent<HTMLInputElement>) => void;
  setData: ActionCreatorWithNonInferrablePayload;
  patientsPage: RootState['patientsPage'];
  defaultValue?: string;
  fontSize?: number;
  paddingLeft?: number;
  marginRight?: number;
  fontWeightBold?: boolean;
  textAlign?: string;
  color?: string;
  flexGrow?: number;
  value?: string;
  onBlur?: () => void;
  error?: boolean;
  placeholder?: string;
}

// TODO вынести в отдельную папку по компонентам
function ColumnBase({
  children,
  name = '',
  width,
  onClick,
  onEdit = undefined,
  onBlur,
  isEditing,
  hasAction = false,
  error = false,
  defaultValue,
  fontSize = 14,
  paddingLeft = 16,
  fontWeightBold = false,
  textAlign = 'left',
  color = '#515d6b',
  flexGrow = 0,
  value = undefined,
  marginRight,
  placeholder,
}: ColumnBaseProps) {
  const [isOver, setOver] = useState(false);
  const disabled = name === 'id';

  return (
    <ColumnLayout
      hasAction={hasAction && !disabled}
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        if (!isEditing) {
          onClick && onClick();
        } else {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      width={width}
      fontSize={fontSize}
      paddingLeft={paddingLeft}
      marginRight={marginRight}
      fontWeightBold={fontWeightBold}
      textAlign={textAlign}
      color={color}
      flexGrow={flexGrow}
    >
      {isEditing ? (
        <InputContainer
          onMouseEnter={() => {
            setOver(true);
          }}
          onMouseLeave={() => {
            setOver(false);
          }}
        >
          <TextInput
            onBlur={onBlur}
            name={name}
            placeholder={placeholder}
            error={error}
            disabled={disabled}
            defaultValue={name === 'birth_date' ? parseDate(defaultValue) : defaultValue}
            onChange={onEdit}
            value={value}
          />
          <ErrorPopup isShown={error && isOver}  style={{ bottom: -35 }}/>
        </InputContainer>
      ) : name === 'email' ? (
        <div>{isEditing ? <EmailText>{children}</EmailText> : children}</div>
      ) : name === 'birth_date' ? (
        children ? (
          parseDate(children)
        ) : (
          ''
        )
      ) : (
        children
      )}
    </ColumnLayout>
  );
}

function Column({
    name,
    value: valueExternal,
    onEdit,
    maskResolver,
    children,
    ...rest
}: ColumnBaseProps & { maskResolver?: (value: string) => string; }) {
  const formContext = useFormContext();

  // разделяем логику с формой и без для сохранения обратной совместимости
  return !formContext ? (
      <ColumnBase
          name={name}
          value={valueExternal}
          onEdit={onEdit}
          {...rest}
      >
        {children}
        </ColumnBase>
  ) : (
      <Controller
        control={formContext.control}
        name={name}
        render={({ field:{ onChange: onChangeFormValue, value } }) => {
          const error = formContext.formState.errors[name];

          const onChange = (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;

            onChangeFormValue(maskResolver?.(value) ?? value)
          }

            return (
                <ColumnBase
                    name={name}
                    value={value}
                    error={error}
                    onEdit={onChange}
                    onBlur={async () => {
                      await formContext.trigger(name);
                    }}
                    {...rest}
                >
                  {children}
                </ColumnBase>
            )
          }
        }
      />
  )
}

export default connect((state: RootState) => ({ patientsPage: state.patientsPage }), {
  setData: PatientsState.actions.setData,
})(Column);
