import React, { Fragment } from 'react';
import { CategoryName, Row } from '../styles';
import Body from './Body';
import { connect } from 'react-redux';
import { RootState } from '../../../store';

function AllPriceEntries({ priceSlice, sessions, onStartEdit, onStopEdit }) {
  return (
    <>
      {Object.keys(priceSlice.price).map((group, groupIndex: number) => (
        <Fragment key={group}>
          <Row colored={false}>
            <CategoryName>{priceSlice.price[group][0].category.name}</CategoryName>
          </Row>
          <Body
            group={group}
            groupIndex={groupIndex}
            sessions={sessions}
            onStartEdit={onStartEdit}
            onStopEdit={onStopEdit}
          />
        </Fragment>
      ))}
    </>
  );
}

export default connect((state: RootState) => ({ priceSlice: state.priceSlice }), {})(AllPriceEntries);
