import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Column, RowExtendedType, TableAction } from '../../../../components/Table';
import { TotalData } from '../TotalData';
import { Totals } from '../../enums';
import { ACTIVE_CLASS, ROOT_CONTAINER } from '../../../../uikit/Modal/constants';
import { PaymentDetails } from '../PaymentDetails';
import { Modal } from '../../../../uikit/Modal';
import TextInput from '../../../../uikit/TextInput';
import TextInputWithMissclick from '../../../../uikit/TextInputWithMissclick';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { BorderSelect } from '../../../../uikit/BorderSelect';
import { RefundFooter } from '../RefundFooter';
import { useCommentForm } from '../../../../uikit/Modal/hooks';
import { Operation, useGetInvoiceAllRowsByStatusQuery, useGetSubInvoicesQuery } from '../../../../services/invoice';
import { ServicesTable } from '../ServicesTable';
import { SubInvoiceStatus } from '../../../../services/billing';
import { useGetTotals } from '../../utils';
import { ServiceTitle } from '../../../../uikit/ServiceTitle';
import { CommentIcon } from '../../styles';
import { InvoiceProperties } from '../../../MedcardPage/Account/enums';
import { validateValue } from './helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeInvalidFieldName,
  resetRefundData,
  setInvalidFieldsNames,
  setIsRefundPaymentInputDirty,
  setRefundModificator,
  updateSubInvoice,
  setRowDataForReturnOperation,
  addSubInvoiceRowsRefetchCallbacks,
  setIsRefundPaidInputDirty,
  setRefundType,
  RefundTypes,
  setTableDataIsUpdating,
  setUpdatingTableRowId,
} from '../../reducer';

type VisitServicesProps = {
  invoiceId: number;
  handleScrollTop?: () => void;
  commentFormHeight: number;
  isParentAccordionExpanded: boolean;
};

export type VisitDataType = {
  id: number;
  tooth_number: string;
  code: string;
  name: string;
  count: number;
  price: number;
  discount: number;
  payment: number;
  payment_type: string;
  payer: string;
  paid: number;
  remainder: number;
  paymentOperations: Operation[];
};

const useStyles = makeStyles(() =>
  createStyles({
    discountSum: {
      borderBottomRightRadius: '0 !important',
      borderTopRightRadius: '0 !important',
    },
    discountType: {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      borderLeft: 0,
    },
  }),
);

export const DiscountTypeValues = {
  RUB: 'RUB',
  PERCENT: 'PERCENT',
};

export const DiscountTypeItems = [
  {
    value: DiscountTypeValues.RUB,
    name: 'руб.',
  },
  {
    value: DiscountTypeValues.PERCENT,
    name: '%',
  },
];

export const VisitServices: FC<VisitServicesProps> = ({
  handleScrollTop,
  invoiceId,
  commentFormHeight,
  isParentAccordionExpanded = true,
}) => {
  const dispatch = useDispatch();
  const activeStatuses = [
    SubInvoiceStatus.PAID,
    SubInvoiceStatus.DEBT,
    SubInvoiceStatus.PENDING_PAYMENT,
    SubInvoiceStatus.EDIT,
  ];
  const statusList = activeStatuses.join(',');
  const {
    data: invoiceAllRows = [],
    refetch,
    isLoading: invoiceAllRowsAreLoading,
  } = useGetInvoiceAllRowsByStatusQuery(
    {
      invoiceId: invoiceId,
      status: statusList,
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (invoiceAllRows.length) {
      dispatch(addSubInvoiceRowsRefetchCallbacks({ allRowsCallback: refetch }));
      dispatch(setTableDataIsUpdating(false));
      dispatch(setUpdatingTableRowId(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceAllRows]);

  const { data: subInvoices = [] } = useGetSubInvoicesQuery(invoiceId);

  const [activeRowId, setActiveRowId] = useState<number | null>(null);
  const [paymentDetailsForm, setPaymentDetailsForm] = useState<null | HTMLElement>(null);
  const [currentRefundRow, setCurrentRefundRow] = useState<RowExtendedType<VisitDataType> | null>(null);
  const [previousRefundRow, setPreviousRefundRow] = useState<RowExtendedType<VisitDataType> | null>(null);

  const filteredSubInvoices = subInvoices.filter((subInvoice) => activeStatuses.includes(subInvoice.status));
  const paymentValue = useRef(null);
  const discountValue = useRef(null);
  const refundValue = useRef(null);

  const totalsModificator = useSelector((state) => state.visitsAndPayments.refundModificator);
  const invalidFieldsNames = useSelector((state) => state.visitsAndPayments.invalidFieldsNames);
  const isRefundPaymentInputDirty = useSelector((state) => state.visitsAndPayments.isRefundPaymentInputDirty);
  const isRefundPaidInputDirty = useSelector((state) => state.visitsAndPayments.isRefundPaidInputDirty);
  const tableDataIsUpdating = useSelector((state) => state.visitsAndPayments.tableDataIsUpdating);
  const updatingTableRowId = useSelector((state) => state.visitsAndPayments.updatingTableRowId);
  const refundType = useSelector((state) => state.visitsAndPayments.refundType);

  const {
    commentForm,
    onShowCommentFormHandler,
    handleCommentModalClose,
    currentId,
    handleCommentListOpen,
    showComments,
  } = useCommentForm();

  const [totalForPay, totalPaid, totalPaymentType] = useGetTotals(invoiceAllRows);

  const onExpandRefundRowHandler = (row: RowExtendedType<VisitDataType>) => {
    dispatch(resetRefundData());
    const rootContainer = document.getElementById(ROOT_CONTAINER);
    rootContainer.style.height = 'calc(100vh - 280px)';
    setCurrentRefundRow(row);
  };

  const onShowPaymentDetailsHandler = (row: RowExtendedType<VisitDataType>) => {
    const anchor = row.parentRowElement;
    setPaymentDetailsForm(anchor);
    setActiveRowId(row.values.id);
    anchor.classList.add(ACTIVE_CLASS);
  };

  const handleCloseRefundFooter = () => {
    dispatch(resetRefundData());
    const rootContainer = document.getElementById(ROOT_CONTAINER);
    rootContainer.style.height = '';
    setCurrentRefundRow(null);
    setPreviousRefundRow(null);
  };

  const handlePaymentDetailsModalClose = () => {
    paymentDetailsForm.classList.remove(ACTIVE_CLASS);
    setPaymentDetailsForm(null);
    setActiveRowId(null);
  };

  const getCurrentCellValue = (row: RowExtendedType<VisitDataType>, property: string) => {
    const value = row.depth !== 0 ? row.original?.[property] : row.original?.paymentOperations[0]?.[property];
    return value ? value : null;
  };

  const handleLocalRowUpdate = (accessorToUpdate, newValue, subInvoiceRowToUpdate) => {
    const paymentValue =
      subInvoiceRowToUpdate.depth === 0
        ? subInvoiceRowToUpdate.original.payment
        : subInvoiceRowToUpdate.original.amountForPay;

    const rowPaidValue =
      subInvoiceRowToUpdate.depth === 0
        ? subInvoiceRowToUpdate.original.externalEntry
          ? subInvoiceRowToUpdate.original.paid
          : subInvoiceRowToUpdate.original.paymentOperations[0].payment
        : subInvoiceRowToUpdate.original.payment;

    const subInvoiceIdToDispatch =
      subInvoiceRowToUpdate.depth === 0
        ? subInvoiceRowToUpdate.original.subInvoiceId
        : invoiceAllRows.find((row) => row.id === subInvoiceRowToUpdate.original.rowId)?.subInvoiceId;

    const targetOperationId =
      subInvoiceRowToUpdate.depth === 0
        ? subInvoiceRowToUpdate.original.paymentOperations.find((o) => o.rowId === subInvoiceRowToUpdate.original.id)
            ?.id
        : subInvoiceRowToUpdate.original.id;

    const paymentMinValForValidation =
      subInvoiceRowToUpdate.depth === 0
        ? subInvoiceRowToUpdate.original.payment - rowPaidValue
        : subInvoiceRowToUpdate.original.amountForPay - rowPaidValue;

    const refundPaidMaxValueForValidation = rowPaidValue;

    switch (accessorToUpdate) {
      case 'payment':
        if (!validateValue(newValue, paymentMinValForValidation, paymentValue)) {
          dispatch(setInvalidFieldsNames(['payment']));
          return;
        } else {
          const refundValue = paymentValue - newValue;
          dispatch(setIsRefundPaymentInputDirty(true));
          dispatch(removeInvalidFieldName('payment'));
          dispatch(setRefundType('noDebt'));
          dispatch(
            updateSubInvoice({
              rowId: targetOperationId,
              subInvoiceId: subInvoiceIdToDispatch,
              subRowId: subInvoiceRowToUpdate.depth === 0 ? null : subInvoiceRowToUpdate.original.id,
              payment: Number(newValue),
              remainder: refundValue,
            }),
          );
          dispatch(
            setRefundModificator({
              invoiceToRefundId: subInvoiceIdToDispatch,
              value: refundValue,
            }),
          );
          dispatch(
            setRowDataForReturnOperation({
              id: targetOperationId,
              subInvoiceId: subInvoiceIdToDispatch,
              payment: refundValue,
              return_status: 'NODEBT',
            }),
          );
        }
        break;
      case 'refundPaid':
        if (!validateValue(newValue, 1, refundPaidMaxValueForValidation)) {
          dispatch(setInvalidFieldsNames(['refundPaid']));
          return;
        } else {
          dispatch(setIsRefundPaidInputDirty(true));
          dispatch(removeInvalidFieldName('refundPaid'));
          dispatch(setRefundType('withDebt'));
          dispatch(
            setRefundModificator({
              invoiceToRefundId: subInvoiceIdToDispatch,
              value: Number(newValue),
            }),
          );
          dispatch(
            setRowDataForReturnOperation({
              id: targetOperationId,
              subInvoiceId: subInvoiceIdToDispatch,
              payment: Number(newValue),
              return_status: 'DEBT',
            }),
          );
        }
        break;
      default:
        console.log('Такой accessor не найден!');
    }
  };

  const actions: TableAction<VisitDataType>[] = useMemo(
    () => [
      {
        label: 'Детали оплаты',
        onClick: onShowPaymentDetailsHandler,
        isHidden: (row) => row.depth !== 0,
      },
      {
        label: 'Добавить комментарий',
        onClick: onShowCommentFormHandler,
        isHidden: (row) => row.depth !== 0,
      },
      {
        label: 'Возврат',
        onClick: onExpandRefundRowHandler,
        isHidden: (row) => {
          return (
            // Если сейчас уже оформляется возврат - в других строках нельзя начать делать возврат
            Boolean(currentRefundRow) ||
            // Если эта строка и есть возврат - нельзя сделать на неё возврат
            Boolean(row.original.return_status) ||
            // Если эта строка пустая и в ней нет операций - нельзя делать возврат. Ибо не на что делать возврат
            Boolean(row.depth === 0 && !row.original.paymentOperations?.length)
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onShowCommentFormHandler, currentRefundRow?.id],
  );

  const columns: Column[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Зуб',
        accessor: 'tooth_number',
        width: 44,
        disableFilters: true,
        Cell: ({ row }) => row.original.externalEntry?.tooth_index || null,
        SubCell: () => null,
      },
      {
        Header: 'Код услуги',
        accessor: 'code',
        width: 108,
        disableFilters: true,
        SubCell: () => null,
      },
      {
        Header: 'Наименование услуги',
        accessor: 'text',
        disableFilters: true,
        width: 344,
        Cell: ({ value }) => <ServiceTitle value={value} width={328} />,
        SubCell: () => null,
      },
      {
        Header: 'Кол-во',
        accessor: 'count',
        width: 60,
        disableFilters: true,
        SubCell: () => null,
      },
      {
        Header: 'Цена',
        accessor: 'price',
        width: 75,
        disableFilters: true,
        SubCell: () => null,
      },
      {
        Header: 'Скидка',
        accessor: 'discount',
        width: 120,
        disableFilters: true,
        Cell: ({ row, value }) => {
          const classes = useStyles();
          const [discountType, setDiscountType] = useState<string>(DiscountTypeValues.PERCENT);

          if (row.original.return_status) {
            return <span style={{ fontWeight: 500 }}>Возврат:</span>;
          }

          if (row.depth !== 0) return null;
          if (row.original.id === currentRefundRow?.id) {
            return (
              <div style={{ display: 'flex' }}>
                <TextInput
                  className={classes.discountSum}
                  ref={discountValue}
                  defaultValue={value}
                  style={{ height: 22, width: 47, fontSize: 12 }}
                />
                <BorderSelect
                  value={discountType}
                  className={classes.discountType}
                  setValue={(value) => setDiscountType(value)}
                  items={DiscountTypeItems}
                />
              </div>
            );
          }
          return row.original.discount;
        },
        SubCell: () => null,
      },
      {
        Header: 'К оплате',
        accessor: 'payment',
        width: 72,
        disableFilters: true,
        Cell: ({ row }) => {
          //Проверка на обновление строки в данный момент, с возвратом прелоадера если она обновляется
          const rowId = row.depth === 0 ? row.original.paymentOperations[0]?.id : row.original.id;
          if (tableDataIsUpdating && rowId === updatingTableRowId) {
            return <div className="spinner small" />;
          }

          //Проверка на возвратную строку, с возвратом значений для возвратной строки
          if (row.depth !== 0 && Boolean(row.original.return_status)) {
            if (row.original.return_status === 'NODEBT') {
              return <span style={{ color: '#c3000a' }}>{-row.original.payment}</span>;
            } else {
              return null;
            }
          }

          const value = row.depth === 0 ? row.values.payment : row.original.amountForPay;

          //Проверка: если на текущую строку оформляется возврат, и если инпут в поле "Оплачено" не тронут - будет инпут для ввода новых "К оплате"
          const currentRefundRowId = currentRefundRow
            ? currentRefundRow.depth === 0
              ? currentRefundRow.original.paymentOperations[0].id
              : currentRefundRow.original.id
            : null;
          if (rowId === currentRefundRowId && !isRefundPaidInputDirty) {
            const localModificator = totalsModificator ? totalsModificator.value : 0;
            const inputValue = value - localModificator;

            return (
              <TextInputWithMissclick
                style={{ height: 24, fontSize: 12 }}
                error={invalidFieldsNames.includes('payment')}
                ref={paymentValue}
                defaultValue={inputValue}
                onMissclick={(inputValue) => handleLocalRowUpdate('payment', inputValue, row)}
              />
            );
          }

          //Если это не загрузка, не возврат и не инпут - выводится значение ячейки
          return value;
        },
        SubCell: () => <span style={{ fontWeight: 500 }}>Возврат:</span>,
      },
      {
        Header: 'Способ оплаты',
        accessor: 'paymentType',
        width: 172,
        disableFilters: true,
        Cell: ({ row }) => {
          //Проверка на возвратную строку, с возвратом значений для возвратной строки
          if (row.depth !== 0) {
            const isRefundRow = Boolean(row.original.return_status);
            if (isRefundRow) {
              return null;
            }
          }

          return getCurrentCellValue(row, InvoiceProperties.PAYMENT_TYPE);
        },
      },
      {
        Header: 'Плательщик',
        accessor: 'payer',
        width: 132,
        disableFilters: true,
        Cell: ({ row }) => {
          //Проверка на возвратную строку, с возвратом значений для возвратной строки
          if (row.depth !== 0) {
            const isRefundRow = Boolean(row.original.return_status);
            if (isRefundRow) {
              return null;
            }
          }

          return getCurrentCellValue(row, InvoiceProperties.PAYER);
        },
        SubCell: () => null,
      },
      {
        Header: 'Оплачено',
        accessor: 'paid',
        width: 76,
        disableFilters: true,
        Cell: ({ row }) => {
          const { paymentOperations, return_status } = row.original;
          //Проверка на обновление строки в данный момент, с возвратом прелоадера если она обновляется
          const rowId = row.depth === 0 ? paymentOperations[0]?.id : row.original.id;
          if (tableDataIsUpdating && rowId === updatingTableRowId) {
            return <div className="spinner small" />;
          }

          // Если подстрока
          if (row.depth !== 0) {
            //Проверка на возвратную строку, с возвратом значений для возвратной строки
            const isRefundRow = Boolean(return_status);
            if (isRefundRow) {
              return <span style={{ color: '#c3000a' }}>{-row.original.payment}</span>;
            }

            return row.original.payment;
          }

          //Если это не загрузка, и не возврат - выводится значение ячейки первой операции, или 0 если нет операций
          return paymentOperations[0]?.payment ?? 0;
        },
        SubCell: ({ row }) => {
          //Это значение отображается только тогда, когда оформляется возврат

          //Если при оформлении возврата был изменён инпут "К оплате" - выводится модификатор ("К оплате" минус "Оплачено")
          if (isRefundPaymentInputDirty) {
            return <span>&minus; {totalsModificator ? totalsModificator.value : undefined}</span>;
          }

          //Если инпут "К оплате" не трогали - выводится инпут для ввода новой информации о поле "Оплачено" (сумма, которя будет отниматься от изначального "Оплачено")
          const defaultValue =
            refundType && totalsModificator && refundType === RefundTypes.WITHDEBT
              ? totalsModificator.value
              : undefined;
          return (
            <>
              <span>&minus; </span>
              <TextInputWithMissclick
                style={{ height: 24, fontSize: 12 }}
                ref={refundValue}
                defaultValue={defaultValue}
                onMissclick={(inputValue) => handleLocalRowUpdate('refundPaid', inputValue, row)}
                error={invalidFieldsNames.includes('refundPaid')}
              />
            </>
          );
        },
      },
      {
        Header: 'Остаток',
        accessor: 'remainder',
        width: 72,
        disableFilters: true,
        Cell: ({ row }) => {
          const { paymentOperations, return_status } = row.original;
          //Проверка на обновление строки в данный момент, с возвратом прелоадера если она обновляется
          const rowId = row.depth === 0 ? paymentOperations[0]?.id : row.original.id;
          if (tableDataIsUpdating && rowId === updatingTableRowId) {
            return <div className="spinner small" />;
          }

          //Проверка на возвратную строку, с возвратом значений для возвратной строки
          if (row.depth !== 0) {
            const isRefundRow = Boolean(return_status);
            if (isRefundRow) {
              if (return_status === 'DEBT') {
                return <span style={{ color: '#c3000a' }}>{-row.original.payment}</span>;
              } else {
                return null;
              }
            }
          }

          const getAmountValues = () => {
            if (row.depth !== 0) {
              return [row.original.payment, row.original.amountForPay];
            }

            const rowRemainder = paymentOperations.length ? paymentOperations[0]?.amountForPay : row.original.remainder;

            return [paymentOperations[0]?.payment ?? 0, rowRemainder];
          };

          const [paidAmount, amountForPay] = getAmountValues();

          //Если это не загрузка, и не возврат - выводится значение ячейки
          const value = paidAmount - amountForPay;
          return <span style={{ color: value < 0 ? '#c3000a' : '#515D6B' }}>{value}</span>;
        },
        SubCell: () => {
          //Это значение отображается только тогда, когда оформляется возврат

          //Если в один из возвратных инпутов было что-то введено и оно было валидным, и если оформляемый возврат - долгообразующий, выводится введённое значение
          if (refundType && totalsModificator && refundType === RefundTypes.WITHDEBT) {
            return <span style={{ color: '#c3000a' }}>&minus; {totalsModificator.value}</span>;
          }

          //Иначе не выводится ничего
          return null;
        },
      },
      {
        Header: '',
        accessor: 'comments',
        width: 1,
        disableFilters: true,
        Cell: ({ row, value }) => {
          return (
            <>
              {value?.length ? (
                <CommentIcon onClick={(event) => handleCommentListOpen(event, row.values.id)}>
                  <img src="./img/grey/comment-icon.svg" alt="comment" />
                </CommentIcon>
              ) : null}
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      handleCommentListOpen,
      currentRefundRow?.id,
      isRefundPaidInputDirty,
      isRefundPaymentInputDirty,
      totalsModificator,
      tableDataIsUpdating,
    ],
  );

  useEffect(() => {
    if (currentRefundRow && !previousRefundRow) {
      setPreviousRefundRow(currentRefundRow);
    }
    if (currentRefundRow && previousRefundRow) {
      handleCloseRefundFooter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRefundRow]);

  // Если аккордеон-родитель этого компонента закрылся, сбрасываются все данные о возврате
  useEffect(() => {
    if (!isParentAccordionExpanded) {
      setCurrentRefundRow(null);
      setPreviousRefundRow(null);
      dispatch(resetRefundData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParentAccordionExpanded]);

  return (
    <div>
      {invoiceAllRowsAreLoading && <div className="spinner centered" />}
      {filteredSubInvoices.map((subInvoice, index) => {
        return (
          <div key={subInvoice.id}>
            <ServicesTable
              subInvoiceId={subInvoice.id}
              createdDate={subInvoice.created_at}
              subIndex={index}
              onScrollTop={handleScrollTop}
              subInvoicesQuantity={filteredSubInvoices.length}
              actions={actions}
              columns={columns}
              currentRowId={currentId}
              commentForm={commentForm}
              onCloseCommentForm={handleCommentModalClose}
              showComments={showComments}
              commentFormHeight={commentFormHeight}
              currentRefundRow={currentRefundRow}
            />
          </div>
        );
      })}
      {invoiceAllRows.length ? (
        <TotalData
          totalName={Totals.TOTAL_VISIT}
          paymentType={totalPaymentType}
          paid={totalsModificator ? totalPaid - totalsModificator.value : totalPaid}
          remainder={
            refundType === 'withDebt' && totalsModificator
              ? totalPaid - totalForPay - totalsModificator.value
              : totalPaid - totalForPay
          }
          payment={
            currentRefundRow &&
            totalsModificator &&
            refundType === 'noDebt' &&
            invoiceAllRows.some(
              (row) =>
                row.id ===
                (currentRefundRow.depth === 0 ? currentRefundRow.original.id : currentRefundRow.original.rowId),
            )
              ? totalForPay - totalsModificator.value
              : totalForPay
          }
          handleScrollTop={handleScrollTop}
          invoiceId={invoiceId}
          commentFormHeight={commentFormHeight}
          isDataLoading={tableDataIsUpdating}
        />
      ) : null}
      <Modal anchor={paymentDetailsForm}>
        <PaymentDetails
          handleCloseForm={handlePaymentDetailsModalClose}
          scrollTop={handleScrollTop}
          rowId={activeRowId}
        />
      </Modal>
      {currentRefundRow && (
        <RefundFooter
          onCloseFooter={handleCloseRefundFooter}
          rowRefund={currentRefundRow}
          disableSubmit={
            Boolean(invalidFieldsNames.length) || !Boolean(totalsModificator) || totalsModificator.value === 0
          }
        />
      )}
    </div>
  );
};
