import React, { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { isString } from 'lodash';

import { CommentIcon, TableContainer, TableSubTitle, TableTitle, TableWrapper } from './styles';
import PaymentAccountSlice from '../state';
import {
  useDeleteInvoiceRowMutation,
  useUpdateInvoiceRowMutation,
  useUpdateSubInvoiceMutation,
} from '../../../../services/invoice';
import { anchorMenuPositionType, Column, Table, TableAction } from '../../../../components/Table';
import { BorderAutocomplete } from '../components/Autocomplete/Autocomplete';
import {
  PaymentTypeCodes,
  useAddPayerMutation,
  useAddPaymentTypeMutation,
  useDeletePayerMutation,
  useDeletePaymentTypeMutation,
  useEditPayerMutation,
  useEditPaymentTypeMutation,
  useGetPayersQuery,
  useGetPaymentTypesQuery,
} from '../../../../services/dictionaries';
import { useCommentForm } from '../../../../uikit/Modal/hooks';
import { CommentsForm } from '../../../../uikit/CommentsForm';
import { InvoiceProperties } from '../enums';
import { DiscountInputs } from '../components/DiscountInputs';
import { getMask } from '../../../../utils/masks';
import { EAccountStatus } from '../AccountStatus';
import { ServiceTitle } from '../../../../uikit/ServiceTitle';
import { RootState } from '../../../../store';
import { useAppDispatch } from '../../../../store/hooks';
import { DiscountTypeItems, DiscountTypeValues, ROWS_BY_PRICE, STARTED_AMOUNT } from '../constants';
import { operationItemType, PaymentTableProps } from '../types';
import { renderJournalEntryTitle } from '../utils/render-journal-entry-title';
import { useGetTableRows } from './hooks';
import { Modal } from '../../../../uikit/Modal';
import { AmountInput, Paid, useGetOperationData } from './cells';

const anchorMenuPosition = {
  anchor: {
    vertical: 'top',
    horizontal: 'left',
  },
  transform: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export const PaymentTable: FC<PaymentTableProps> = ({
  invoicePaymaster,
  currentUser,
  handleSetTheadEl,
  handleScrollTop,
  currentSubInvoiceId,
  currentSubInvoiceStatus,
  onSetTotalPaidInputValue,
  onRefreshInvoiceList,
  onRefreshDebtAmount,
  containerRef,
  commentFormHeight,
}) => {
  const dispatch = useAppDispatch();
  const { updateOperationItems } = PaymentAccountSlice.actions;
  const { patchOperations } = PaymentAccountSlice.actions;
  const { setTotalPayType } = PaymentAccountSlice.actions;
  const operationItems = useSelector((state: RootState) => state.PaymentAccountSlice.operationItems);
  const [emptyPaymentTypeRow, setEmptyPaymentTypeRow] = useState<number>(null);

  const { data: paymentTypes = [] } = useGetPaymentTypesQuery();
  const { data: payers = [] } = useGetPayersQuery();

  const [addPaymentType] = useAddPaymentTypeMutation();
  const [editPaymentType] = useEditPaymentTypeMutation();
  const [deletePaymentType] = useDeletePaymentTypeMutation();
  const [addPayer] = useAddPayerMutation();
  const [editPayer] = useEditPayerMutation();
  const [deletePayer] = useDeletePayerMutation();

  const tableRows = useGetTableRows(currentSubInvoiceId, operationItems);

  const [updateSubInvoice] = useUpdateSubInvoiceMutation();
  const [updateInvoiceRow] = useUpdateInvoiceRowMutation();
  const [deleteInvoiceRow] = useDeleteInvoiceRowMutation();

  const {
    commentForm,
    onShowCommentFormHandler,
    handleCommentModalClose,
    showComments,
    currentId,
    handleCommentListOpen,
  } = useCommentForm();

  const getUpdatedRowData = (editedRow: operationItemType, inputValue: string | number, property: string) => {
    const totalPrice = editedRow.count * editedRow.price;
    const value = isString(inputValue) ? +inputValue : inputValue;

    if (property === InvoiceProperties.PAYMENT) {
      const paymentDifference = totalPrice - value;
      const discountAmount =
        editedRow.discountType === DiscountTypeValues.PERCENT
          ? paymentDifference / (totalPrice / 100)
          : paymentDifference;
      return {
        id: editedRow.id,
        payment: value,
        discount: Math.round(discountAmount),
      };
    }
    if (property === InvoiceProperties.DISCOUNT_SUM) {
      const discountSum = editedRow.discountType === DiscountTypeValues.PERCENT ? (totalPrice / 100) * value : value;
      return {
        id: editedRow.id,
        payment: totalPrice - Math.round(discountSum),
        discount: value,
      };
    }
    if (property === InvoiceProperties.DISCOUNT_TYPE) {
      const discountType = DiscountTypeItems.find((item) => item.name === inputValue).value;

      if (editedRow.discountType === discountType) return;

      return {
        id: editedRow.id,
        payment: totalPrice,
        discount: 0,
        discount_type: discountType,
      };
    }
  };

  const updatePaymentState = (rows: operationItemType[]) => {
    const totalPaidValue = rows.reduce((sum, row) => sum + row.totalRowPaid, 0);
    onSetTotalPaidInputValue(totalPaidValue);
    dispatch(updateOperationItems(rows));
  };

  const updateInvoice = useCallback(
    (inputValue: string | number, rowId: number, property: string) => {
      if (inputValue === '') return;
      const editedRow = operationItems.find((item) => item.invoice_row === rowId);
      const updatedRow = getUpdatedRowData(editedRow, inputValue, property);

      updateInvoiceRow({
        record: updatedRow,
        subInvoiceId: editedRow.subInvoiceId,
      }).then(({ data }) => {
        const updatedRowsState = operationItems.map((row) => {
          if (row.invoice_row !== rowId) return row;
          return {
            ...row,
            discount: data.discount,
            discountType: data.discount_type,
            payment: +data.payment,
            totalRowPaid: 0,
            totalRowDebt: +data.payment,
            operations: [{ ...row.operations[0], amountForPay: +data.payment, payment: STARTED_AMOUNT }],
          };
        });
        onRefreshDebtAmount();
        updatePaymentState(updatedRowsState);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateInvoiceRow, operationItems],
  );

  const setTotalPaymentType = (typeId: number | string, operationId: number | string) => {
    const combinedPaymentType = paymentTypes?.find((type) => type.code === PaymentTypeCodes.COMBINED);
    const paymentTypesByRows = [typeId];

    operationItems.forEach((row) => {
      const originalTypes = row.operations
        .filter((operation) => operation.isNew && operation.id !== operationId)
        .map((operation) => operation.payment_type)
        .filter(Boolean);
      paymentTypesByRows.push(...originalTypes);
    });

    const originalPaymentTypes = Array.from(new Set(paymentTypesByRows));

    if (originalPaymentTypes.length === 1) {
      dispatch(setTotalPayType(paymentTypesByRows[0]));
    }
    if (originalPaymentTypes.length > 1) {
      dispatch(setTotalPayType(combinedPaymentType?.id));
    }
  };

  const columns: Column[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Зуб',
        accessor: 'tooth_index',
        width: 44,
        Cell: ({ row }) => row.original.externalEntry?.tooth_index ?? null,
        disableFilters: true,
      },
      {
        Header: 'Код услуги',
        accessor: 'code',
        width: 108,
        disableFilters: true,
      },
      {
        Header: 'Наименование услуги',
        accessor: 'text',
        width: 392,
        Cell: ({ value }) => <ServiceTitle value={value} />,
        disableFilters: true,
      },
      {
        Header: 'Кол-во',
        accessor: 'count',
        width: 60,
        disableFilters: true,
      },
      {
        Header: 'Цена',
        accessor: 'price',
        width: 76,
        disableFilters: true,
      },
      {
        Header: 'Скидка',
        accessor: 'discount',
        width: 110,
        Cell: ({ row, value }) => {
          if (row.depth !== 0) return null;

          const isNotEditable = Boolean(!row.original.operations[0]?.isNew || invoicePaymaster !== currentUser);

          if (isNotEditable) return <div style={{ minWidth: '97px' }}>{value}</div>;

          return (
            <DiscountInputs
              totalPrice={row.values.count * row.values.price}
              discountType={row.original.discountType}
              value={value}
              onUpdateInvoice={updateInvoice}
              rowId={row.values.id}
              subInvoiceId={row.original.subInvoiceId}
            />
          );
        },
        disableFilters: true,
      },
      {
        Header: 'К оплате',
        accessor: 'payment',
        width: 72,
        Cell: ({ row, value }) => {
          const { returned } = useGetOperationData(row);

          const [inputValue, setInputValue] = useState<number>(value);

          //Если подстрока выводим статичную сумму к оплате либо null если возврат
          if (row.depth !== 0) {
            return returned ? <span style={{ fontWeight: 500 }}>Возврат:</span> : row.original.amountForPay;
          }

          //Если у строки есть операции или оплаченная сумма - делаем нередактируемой;
          const isNotEditable = Boolean(!row.original.operations[0]?.isNew || invoicePaymaster !== currentUser);

          const onUpdate = () => {
            if (value !== inputValue) {
              updateInvoice(inputValue, row.values.id, InvoiceProperties.PAYMENT);
            }
          };

          if (isNotEditable) {
            return value;
          }

          return <AmountInput value={inputValue} onChange={setInputValue} onBlur={onUpdate} />;
        },
        disableFilters: true,
      },
      {
        Header: 'Способ оплаты',
        accessor: 'paymentType',
        Cell: ({ row }) => {
          const { currentRowId, payment, operationId, payment_type, isNew } = useGetOperationData(row);
          const errorCondition = (payment > 0 && !payment_type) || currentRowId === emptyPaymentTypeRow;
          if (!isNew) return payment_type || null;

          const updatePaymentTypes = (typeId: number) => {
            setTotalPaymentType(typeId, operationId);
            dispatch(patchOperations({ invoice_row: currentRowId, payment_type: typeId, id: operationId }));
          };

          return (
            <BorderAutocomplete
              editable
              maxLength={21}
              placeholder="Способ оплаты"
              baseValue={payment_type}
              items={paymentTypes}
              onAddItem={addPaymentType}
              onEditItem={editPaymentType}
              onDeleteItem={deletePaymentType}
              rowId={currentRowId}
              property={InvoiceProperties.PAYMENT_TYPE}
              paidAmount={payment}
              operationId={operationId}
              onSetValue={updatePaymentTypes}
              errorCondition={errorCondition}
              onSetEmptyPaymentTypeRow={setEmptyPaymentTypeRow}
              containerRef={containerRef}
            />
          );
        },
        width: 172,
        disableFilters: true,
      },
      {
        Header: 'Плательщик',
        accessor: 'payer',
        Cell: ({ row }) => {
          const { currentRowId, operationId, payment_type, payer, isNew } = useGetOperationData(row);
          const rowPaymentType = paymentTypes.find((payType) => payType.id === payment_type);
          if (!isNew) return payer || null;
          const setPayer = (typeId: number) => {
            dispatch(patchOperations({ invoice_row: currentRowId, payer: typeId, id: operationId }));
          };

          return (
            <BorderAutocomplete
              placeholder="Плательщик"
              baseValue={payer}
              items={payers}
              onAddItem={addPayer}
              onEditItem={editPayer}
              onDeleteItem={deletePayer}
              rowId={currentRowId}
              property={InvoiceProperties.PAYER}
              listBoxWidth={132}
              isPayerInput
              rowPaymentType={rowPaymentType?.code}
              paymentTypeId={payment_type}
              operationId={operationId}
              onSetValue={setPayer}
              onSetEmptyPaymentTypeRow={setEmptyPaymentTypeRow}
              containerRef={containerRef}
            />
          );
        },
        disableFilters: true,
      },
      {
        Header: 'Оплачено',
        accessor: 'paid',
        width: 76,
        Cell: ({ row }) => <Paid row={row} updatePaymentState={updatePaymentState} />,
        disableFilters: true,
      },
      {
        Header: 'Остаток',
        accessor: 'remainder',
        width: 72,
        disableFilters: true,
        Cell: ({ row }) => {
          const { remainder } = useGetOperationData(row);

          return (
            <span>
              {remainder < STARTED_AMOUNT && <span>-</span>}
              {getMask('number_format').resolve(`${remainder}`)}
            </span>
          );
        },
      },
      {
        Header: '',
        accessor: 'comments',
        width: 1,
        Cell: ({ row, value }) => {
          if (row.depth !== 0) return null;

          return (
            <>
              {value?.length ? (
                <CommentIcon onClick={(event) => handleCommentListOpen(event, row.values.id)}>
                  <img src="./img/grey/comment-icon.svg" alt="comment" />
                </CommentIcon>
              ) : null}
            </>
          );
        },
        disableFilters: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentTypes, payers, emptyPaymentTypeRow, operationItems],
  );

  const actions: TableAction<operationItemType>[] = useMemo(
    () => [
      {
        label: 'Добавить комментарий',
        onClick: onShowCommentFormHandler,
        isHidden: (row) => row.depth !== 0,
      },
      {
        label: 'Удалить позицию',
        onClick: async ({ values }) => {
          await deleteInvoiceRow({
            subInvoiceId: currentSubInvoiceId,
            invoiceRowId: values.id,
          });

          // общие оставшиеся позиции
          const updatedOperationItemsList = operationItems.filter((item) => item.invoice_row !== values.id);
          // оставшиеся позиции по текущему субсчету
          const currentSubInvoiceRows = updatedOperationItemsList.filter(
            (item) => item.subInvoiceId === currentSubInvoiceId,
          );

          if (updatedOperationItemsList.length) {
            const deletedRow = operationItems.find((item) => item.invoice_row === values.id);
            onSetTotalPaidInputValue((prevTotal) => prevTotal - deletedRow?.totalRowPaid);
          }
          dispatch(updateOperationItems(updatedOperationItemsList));

          if (!currentSubInvoiceRows.length) {
            await updateSubInvoice({
              id: currentSubInvoiceId,
              status: EAccountStatus.NOT_FILLED,
            });
            await onRefreshInvoiceList();
          }
          await onRefreshDebtAmount();
        },
        isHidden: ({ values }) => currentSubInvoiceStatus !== EAccountStatus.PENDING_PAYMENT || !values.code,
      },
    ],
    [
      dispatch,
      updateOperationItems,
      onShowCommentFormHandler,
      currentSubInvoiceId,
      currentSubInvoiceStatus,
      deleteInvoiceRow,
      updateSubInvoice,
      onSetTotalPaidInputValue,
      operationItems,
      onRefreshDebtAmount,
      onRefreshInvoiceList,
    ],
  );

  return (
    <div>
      {!operationItems.length ? null : (
        <>
          <TableTitle>{renderJournalEntryTitle(operationItems[0]?.entry)}</TableTitle>
          <TableWrapper>
            {Object.entries(tableRows)?.map(([type, rows], index) => {
              if (rows && rows.length) {
                const doctorName = rows[0].externalEntry?.doctor.first_name[0];
                const doctorSurname = rows[0].externalEntry?.doctor.last_name;
                const doctorSecondName = rows[0].externalEntry?.doctor.second_name[0];

                return (
                  <div key={`${type} + ${index}`} data-wrapper="wrapper">
                    <TableSubTitle>
                      {type === ROWS_BY_PRICE
                        ? 'Прейскурант'
                        : `План лечения: ${doctorSurname} ${doctorName}. ${doctorSecondName}.
                                             (${renderJournalEntryTitle(rows[0]?.externalEntry)})`}
                    </TableSubTitle>
                    <TableContainer>
                      <Table<operationItemType>
                        columns={columns}
                        anchorMenuPosition={anchorMenuPosition as anchorMenuPositionType}
                        data={rows}
                        actions={actions}
                        canFocusRow
                        changeColumnOrder
                        isAllRowsExpanded
                        hiddenColumns={['id']}
                        // isLoading={isLoading}
                        visibleRowCount={12}
                        canAutoHeight={false}
                        getHeaderProps={() => ({
                          style: {
                            background: 'white',
                          },
                        })}
                        getHeadRef={(ref) => handleSetTheadEl(ref)}
                      />
                    </TableContainer>
                  </div>
                );
              }
            })}
          </TableWrapper>
        </>
      )}
      <Modal anchor={commentForm}>
        <CommentsForm
          handleSetOpenForm={handleCommentModalClose}
          scrollTop={handleScrollTop}
          showComments={showComments}
          positionId={currentId}
          subInvoiceId={currentSubInvoiceId}
          formHeight={commentFormHeight}
        />
      </Modal>
    </div>
  );
};
