import { createApi } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosRequestConfig, AxiosError } from 'axios';
import { baseURL, HttpMethod } from '../utils/request';
import { Clinic } from './clinics';
import { fetchAxios } from '../utils/interceptors';

export interface User {
  clinic: Partial<Clinic>;
  color: string;
  date_joined: string;
  email: string;
  first_name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  groups: any[];
  id: number;
  is_active: boolean;
  is_staff: boolean;
  is_superuser: boolean;
  is_password_reset: boolean;
  job: string;
  last_login: string | null;
  last_name: string;
  password: string;
  permissions: UserPermissions;
  phone: string;
  role: UserRole;
  second_name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user_permissions: any[];
  username: string;
}

export interface UserCredentials {
  new_username: string;
  new_password: string;
  id: string;
}

export interface Staffer {
  clinic: number;
  color: string;
  email: string;
  first_name: string;
  id: number;
  is_active: boolean;
  is_superuser: boolean;
  job: string;
  last_name: string;
  phone: string;
  role: UserRole;
  second_name: string;
  username: string;
  schedule: null | StaffSchedule[];
}

export interface StafferWithFullName extends Staffer {
  fullname: string;
}

export interface StaffSchedule {
  cabinet: string;
  workDays: { start: string | Date; end: string | Date }[];
}

export enum UserRole {
  Administrator = 'ADM',
  Doctor = 'DOC',
}

export interface UserPermissions {
  [key: string]: boolean;
}

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await fetchAxios({
        url: `${baseUrl}/${url}`,
        headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
        method,
        data,
        params,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const userApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: axiosBaseQuery({ baseUrl: baseURL }),
  tagTypes: ['Users', 'Permissions'],
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    login: builder.mutation<{ data: any; error: any }, { username: string; password: string }>({
      query: (credentials) => {
        const { username, password } = credentials;
        return {
          url: 'users/login',
          method: HttpMethod.POST,
          data: {
            login: username,
            password: password,
          },
        };
      },
    }),
    getStaffByClinic: builder.query<StafferWithFullName[], number>({
      query: (clinicId) => {
        return {
          url: `clinics/${clinicId}/staff`,
          method: HttpMethod.GET,
        };
      },
      transformResponse: (response: { items: Staffer[] }) => {
        return response.items.map((el) => {
          return {
            ...el,
            fullname: `${[el.last_name, el.first_name, el?.second_name].filter(Boolean).join(' ')}`,
          };
        });
      },
      providesTags: [{ type: 'Users', id: 'LIST' }],
    }),
    searchStaff: builder.query<Staffer[], Record<string, string>>({
      query: (params) => {
        return {
          url: `clinics/search/staffers?${new URLSearchParams(params)}`,
          method: HttpMethod.GET,
        };
      },
      providesTags: [{ type: 'Users', id: 'LIST' }],
    }),
    getUser: builder.query<User, number | string>({
      query: (userId) => {
        return {
          url: `users/${userId}`,
          method: HttpMethod.GET,
        };
      },
      providesTags: (result, error, id) => [{ type: 'Users', id }],
    }),
    addUser: builder.mutation<User, Partial<User>>({
      query: (body) => ({
        url: `users`,
        method: HttpMethod.POST,
        data: body,
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),
    updateUser: builder.mutation<User, Partial<User>>({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `users/${id}`,
          method: HttpMethod.PATCH,
          data: body,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Users', id: arg.id },
        { type: 'Users', id: 'LIST' },
      ],
    }),
    updateCredentials: builder.mutation<UserCredentials, Partial<UserCredentials>>({
      query(data) {
        const { id, ...body } = data;

        return {
          url: `users/${id}/credentials`,
          method: HttpMethod.POST,
          data: body,
        };
      },
    }),
    getPermissions: builder.query<UserPermissions, number>({
      query: (userId) => {
        return {
          url: `users/${userId}/permissions`,
          method: HttpMethod.GET,
        };
      },
      transformResponse: (response: { permissions: UserPermissions }) => {
        return response.permissions;
      },
      providesTags: (result, error, id) => [{ type: 'Permissions', id }],
    }),
    setPermissions: builder.mutation<void, { permissions: UserPermissions; userId: number }>({
      query(data) {
        const { permissions, userId } = data;
        return {
          url: `users/${userId}/permissions`,
          method: HttpMethod.POST,
          data: { permissions },
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Permissions', id: arg.userId }],
    }),
  }),
});

export const {
  endpoints,
  useLoginMutation,
  useGetUserQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useUpdateCredentialsMutation,
  useGetStaffByClinicQuery,
  useGetPermissionsQuery,
  useSetPermissionsMutation,
  useSearchStaffQuery,
} = userApi;
