import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-hot-toast';

import PatientState from '../state';
import { RootState } from '../../../store';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { updatePatientInfo } from '../operations';

const DotsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

function Dots({ isEditing, setEditing, updatePatientInfo, id, dropInputFields, onStopEdit }) {
  const { getValues, reset, trigger } = useFormContext();

  return isEditing ? (
    <DotsContainer>
      <img
        onClick={async (e: MouseEvent<HTMLImageElement>) => {
          e.stopPropagation();

          await trigger();

          const values = getValues();

          try {
            await updatePatientInfo(id, values);
            await dropInputFields();
            await setEditing(false);
            onStopEdit(id);

              // обновляем дефолтные значения формы
              reset(values);
          } catch (e) {
            toast.error('Ошибка редактирования');
          }
        }}
        src={'./img/green/check.svg'}
        style={{ marginRight: 5 }}
      />
      <img
        onClick={async (e: MouseEvent<HTMLImageElement>) => {
          e.stopPropagation();
          await dropInputFields();
          await setEditing(false);
          onStopEdit(id);
          reset();
        }}
        src={'./img/black/close.svg'}
      />
    </DotsContainer>
  ) : (
    <DotsContainer>
      <Tooltip title="Настройте пациента" placement={'bottom-end'} enterDelay={500} enterNextDelay={500} dark>
        <img src={'./img/black/ellipsis.svg'} alt={'dots'} />
      </Tooltip>
    </DotsContainer>
  );
}

export default connect((state: RootState) => ({ patientsPage: state.patientsPage }), {
  updatePatientInfo,
  dropInputFields: PatientState.actions.dropInputFields,
})(Dots);
