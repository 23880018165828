import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import Column from './Column';
import MenuColumn from './MenuColumn';
import SuggestionsMenu from '../../../components/Suggestions/state';
import ColumnWithStream from './ColumnWithStream';
import WithHoverTip from './WithHoverTip';
import { useGetPermissionsQuery } from '../../../services/users';
import { RootState } from '../../../store';
import { isValid, format, parse } from 'date-fns';
import { ru } from 'date-fns/locale';
import { patientFormSchema } from '../utils';
import { getMask } from "../../../utils/masks";

type RowProps = {
  isColored: boolean;
};
const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${({ isColored }) => (isColored ? '#F9F9F9' : 'white')};
  border-bottom: 1px solid #d3d6da;
`;

// TODO вынести в utils
const parseDateFormat = (value: string) => {
  const parsedDate = parse(value, 'yyyy-MM-dd', new Date());

  return isValid(parsedDate) ? format(parsedDate, 'dd.MM.yyyy', { locale: ru }) : '';
}

const transformInitials = (value) =>
    `${value.length > 1 ? value[0].toUpperCase().concat(value.slice(1)) : value}`.trim();

function dropNull(obj) {
  const keys = Object.keys(obj);
  const notEmptyKeys = [];
  keys.forEach((key: string) => {
    if (!!obj[key]) {
      notEmptyKeys.push(key);
    }
  });
  return notEmptyKeys.reduce((acc: object, item: string) => ({ ...acc, [item]: obj[item] }), {});
}

function Patient({
  id,
  isColored,
  patientsPage,
  handleClick,
  common,
  editor,
  onStartEdit,
  onStopEdit,
}: {
  id: number;
  isColored: boolean;
  patientsPage: RootState['patientsPage'];
  openMenu: VoidFunction;
  closeMenu: VoidFunction;
  index: number;
  common: RootState['common'];
  editor: string;
  handleClick: VoidFunction;
  onStartEdit: (patientId: number) => void;
  onStopEdit: (patientId: number) => void;
}) {
  const {
    first_name = '',
    second_name = '',
    last_name = '',
    phone = '',
    email = '',
    birth_date = '',
    stream = '',
    commentary = '',
  } = dropNull(patientsPage.patients.byId[id]);

  const { data: permissions } = useGetPermissionsQuery(common.user.id);

  const methods = useForm({
    defaultValues: {
      id,
      first_name,
      second_name,
      last_name,
      phone: permissions['view_patient_contacts'] ? phone : '*'.repeat(phone.length),
      email: permissions['view_patient_contacts'] ? email : '*'.repeat(email.length),
      birth_date: parseDateFormat(birth_date),
      stream,
      commentary,
    },
    resolver: yupResolver(patientFormSchema({
      phone: /^(?:\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}|\+374 \d{2}-\d{3}-\d{3}|\+375 \d{2} \d{3}-\d{2}-\d{2}|\+86 \d{3}-\d{4}-\d{4})$/
    })),
    shouldUnregister: true,
  });

  const [isEditing, setEditing] = useState(false);
  const { isCreating } = patientsPage;

  return (
    <FormProvider {...methods}>
      <Row key={id} isColored={isColored}>
        <Column
          onClick={handleClick}
          name={'id'}
          isEditing={!isCreating && isEditing}
          width={80}
          hasAction
        >
          {id}
        </Column>
        <Column
          onClick={handleClick}
          name={'last_name'}
          placeholder={'Фамилия'}
          isEditing={!isCreating && isEditing}
          width={196}
          hasAction
          maskResolver={transformInitials}
        >
          {last_name}
        </Column>
        <Column
          onClick={handleClick}
          name={'first_name'}
          placeholder={'Имя'}
          isEditing={!isCreating && isEditing}
          width={130}
          hasAction
          maskResolver={transformInitials}
        >
          {first_name}
        </Column>
        <Column
          onClick={handleClick}
          name={'second_name'}
          placeholder={'Отчество'}
          isEditing={!isCreating && isEditing}
          width={140}
          hasAction
          maskResolver={transformInitials}
        >
          {second_name}
        </Column>
        <Column
          onClick={handleClick}
          name={'birth_date'}
          placeholder={'дд.мм.гггг'}
          isEditing={!isCreating && isEditing}
          width={103}
          hasAction
          maskResolver={(value) => getMask('birth_date').resolve(value)}
        >
          {birth_date}
        </Column>
        <Column
          onClick={handleClick}
          name={'phone'}
          isEditing={!isCreating && isEditing}
          width={174}
          hasAction
          maskResolver={(value) => getMask('combinedPhone').resolve(value)}
        >
          {permissions['view_patient_contacts'] ? phone : '*'.repeat(phone.length)}
        </Column>
        <Column
          hasAction
          onClick={handleClick}
          name={'email'}
          placeholder={'email@gmail.com'}
          isEditing={!isCreating && isEditing}
          width={150}
        >
          {permissions['view_patient_contacts'] ? email : '*'.repeat(email.length)}
        </Column>
        <Controller
            control={methods.control}
            name={'stream'}
            render={({field: {onChange, value}}) => (
                <ColumnWithStream
                    onClick={handleClick}
                    name={'stream'}
                    placeholder={'Канал привлечения'}
                    isEditing={!isCreating && isEditing}
                    width={164}
                    hasAction
                    value={value}
                    onChange={onChange}
                >
                  {stream}
                </ColumnWithStream>
            )
            }
        />
        <Column
          onClick={handleClick}
          name={'commentary'}
          placeholder={'Важная информ.'}
          isEditing={!isCreating && isEditing}
          width={160}
          hasAction
        >
          <WithHoverTip
            isOverflown={commentary && commentary.length > 20 && !isEditing}
            isColored={isColored}
            defaultValue={commentary}
          >
            {commentary}
          </WithHoverTip>
        </Column>
        <MenuColumn
          id={id}
          isEditing={!isCreating && isEditing}
          setEditing={setEditing}
          editor={editor}
          onStartEdit={onStartEdit}
          onStopEdit={onStopEdit}
        />
      </Row>
    </FormProvider>
  );
}

export default connect((state: RootState) => ({ common: state.common, patientsPage: state.patientsPage }), {
  openMenu: SuggestionsMenu.actions.openMenu,
  closeMenu: SuggestionsMenu.actions.closeMenu,
})(Patient);
