import PriceSlice from './state';
import request from '../../utils/request';
import { groupBy } from 'ramda';
import * as R from 'ramda';
import { omitEmptyKeys, getTextWidth } from '../../utils';
import { CATEGORY_ROW_WIDTH, CATEGORY_RIGHT_MARGIN } from './styles';
const {
  loadCategories,
  loadPrice,
  initCategoryPaginator,
  changeCategoryPage,
  loadResponse,
  setSearching,
  startEditingEntry,
  animateEntry,
  pickCategory,
} = PriceSlice.actions;

const NEXT_ORDER_STEP = 10;

const parseOrderToFloat = R.map((item) => ({
  ...item,
  order: parseFloat(item.order),
}));

const sortPriceByOrder = R.compose(R.sortBy(R.prop('order')), parseOrderToFloat);

function prepPriceData(res, categories, unordered) {
  const categoriesById = groupBy((cat) => cat.id, categories);
  const sortedPrice = unordered ? res : sortPriceByOrder(res);
  const priceArray = sortedPrice.map((priceRow) => ({
    ...priceRow,
    category: {
      name: categoriesById[priceRow.category]?.[0]?.name,
      id: priceRow.category,
    },
  }));

  return groupBy((item) => item.category.id, priceArray);
}

export function getPrice() {
  return async (dispatch, getState) => {
    const {
      common,
      priceSlice: { categories },
    } = getState();
    const res = await request.clinic.load_price({
      id: common.user.clinic.id,
    });
    const sortedPrice = sortPriceByOrder(res.data.items);
    dispatch(loadResponse(sortedPrice));
    dispatch(loadPrice(prepPriceData(res.data.items, categories)));
  };
}

function computeNumberOfCategories(categories) {
  let counter = 0;
  let width = 0;
  for (const i in categories) {
    width = width + getTextWidth(categories[i].name) + CATEGORY_RIGHT_MARGIN;
    if (width >= CATEGORY_ROW_WIDTH) {
      break;
    }
    counter += 1;
  }
  return counter;
}

export function getPriceCategories() {
  return async (dispatch, getState) => {
    const { common } = getState();
    const response = await request.clinic.load_price_categories({
      id: common.user.clinic.id,
    });
    const categories = [
      {
        id: 'default',
        name: 'Весь прейскурант',
      },
      ...response.data.items,
    ];
    const lastCategory = categories[categories.length - 1];
    dispatch(pickCategory({ id: lastCategory.id, name: lastCategory.name }));
    const firstPageLength = computeNumberOfCategories(categories);
    dispatch(
      initCategoryPaginator({
        firstPageLength: firstPageLength,
        length: categories.length,
      }),
    );
    dispatch(loadCategories(categories));
  };
}
export function updatePriceEntry(data, entry) {
  return async (dispatch) => {
    const patch = omitEmptyKeys(data);
    await request.clinic.update_price_item(patch, entry);
    await dispatch(getPrice());
  };
}
export function changePriceValue(amount, type, money, category) {
  return async (dispatch, getState) => {
    const { common } = getState();
    await request.clinic.update_price({
      amount,
      clinic: common.user.clinic.id,
      category,
      type,
      by: money,
    });
    await dispatch(getPrice());
  };
}
export function createPriceEntry(data, entry) {
  return async (dispatch, getState) => {
    const { common } = getState();
    const response = await request.clinic.add_price(common.user.clinic, {
      ...entry,
      ...data,
      value: parseInt(data.value),
      order: Math.round(parseFloat(entry.order) * 10000) / 10000,
      clinic: common.user.clinic.id,
      id: Math.random(),
      category: entry.category.id,
    });
    await dispatch(getPrice());
    await dispatch(animateEntry(response.data.id));
    setTimeout(() => dispatch(animateEntry(null)), 1000);
  };
}
export function deletePriceEntry(id, callback) {
  return async (dispatch) => {
    await request.clinic.delete_price(id);
    await dispatch(getPrice());
    callback();
  };
}

export function deleteCategory(id) {
  return async (dispatch, getState) => {
    const { priceSlice } = getState();
    // eslint-disable-next-line no-prototype-builtins
    if (!priceSlice.price.hasOwnProperty(id)) {
      await request.clinic.delete_price_category(id);
      await dispatch(getPriceCategories());
    }
  };
}

function doesCategoriesFitToScreen(categories) {
  let result = true;
  let width = 0;
  for (const i in categories) {
    width = width + getTextWidth(categories[i].name) + CATEGORY_RIGHT_MARGIN;
    if (width >= CATEGORY_ROW_WIDTH) {
      result = false;
      break;
    }
  }
  return result;
}

function getNextPageBoundaries(categories, start, end) {
  const isValidBoundaries = doesCategoriesFitToScreen(categories.slice(start, end));
  if (isValidBoundaries || start >= end) {
    return { start, end };
  } else {
    return getNextPageBoundaries(categories, start + 1, end);
  }
}
function getPrevPageBoundaries(categories, start, end) {
  const isValidBoundaries = doesCategoriesFitToScreen(categories.slice(start, end));
  if (isValidBoundaries || end <= start) {
    return { start, end };
  } else {
    return getPrevPageBoundaries(categories, start, end - 1);
  }
}

export function nextCategoryPage() {
  return (dispatch, getState) => {
    const { priceSlice } = getState();
    const { categories, category } = priceSlice;
    const { start, end } = getNextPageBoundaries(categories, category.paginator.start + 1, category.paginator.end + 1);
    dispatch(changeCategoryPage({ start, end }));
  };
}
export function previousCategoryPage() {
  return (dispatch, getState) => {
    const { priceSlice } = getState();
    const { categories, category } = priceSlice;
    const { start, end } = getPrevPageBoundaries(categories, category.paginator.start - 1, category.paginator.end - 1);
    dispatch(
      changeCategoryPage({
        start,
        end,
      }),
    );
  };
}
const computeOrderOfFirstEntry = R.compose(
  R.add(NEXT_ORDER_STEP),
  R.prop('order'),
  R.last,
  R.sortBy(R.prop('order')),
  R.flatten,
  R.values,
);

const findNextEntryIndex = (entry, price) => R.findIndex((item) => R.equals(entry.order, item.order), price);

export function addRowToPrice(category, index, position) {
  return async (dispatch, getState) => {
    const { priceSlice } = getState();
    // eslint-disable-next-line no-prototype-builtins
    if (priceSlice.price.hasOwnProperty(category)) {
      const entriesInCategory = priceSlice.price[category];
      const indexInFullPrice = findNextEntryIndex(entriesInCategory[index], priceSlice.response);
      const indexToSliceWith = position === 'before' ? indexInFullPrice : indexInFullPrice + 1;

      const chunkBefore = priceSlice.response.slice(0, indexToSliceWith);
      const chunkAfter = priceSlice.response.slice(indexToSliceWith);
      let nextEntryOrder = 0;
      let prevEntryOrder = 0;
      if (chunkBefore.length === 0) {
        nextEntryOrder = R.head(chunkAfter).order;
      } else if (chunkAfter.length === 0) {
        prevEntryOrder = R.last(chunkBefore).order;
        nextEntryOrder = prevEntryOrder + NEXT_ORDER_STEP;
      } else if (chunkBefore.length !== 0 && chunkAfter.length !== 0) {
        prevEntryOrder = R.last(chunkBefore).order;
        nextEntryOrder = R.head(chunkAfter).order;
      } else {
        nextEntryOrder = NEXT_ORDER_STEP;
      }
      let updated_array = [
        ...priceSlice.price[category],
        {
          id: 'new entry',
          title: '',
          value: '',
          code: '',
          order: (prevEntryOrder + nextEntryOrder) / 2.0,
          category: {
            id: category,
            name: priceSlice.categories.find((cat) => cat.id === category).name,
          },
        },
      ];
      updated_array = updated_array.sort((entry1, entry2) => entry1.order - entry2.order);
      await dispatch(startEditingEntry('new entry'));
      await dispatch(loadPrice({ ...priceSlice.price, [category]: updated_array }));
    } else {
      await dispatch(startEditingEntry('new entry'));
      await dispatch(
        loadPrice({
          ...priceSlice.price,
          [category]: [
            {
              id: 'new entry',
              title: '',
              value: '',
              code: '',
              order: priceSlice.response.length > 0 ? computeOrderOfFirstEntry(priceSlice.price) : 1,
              category: {
                id: category,
                name: priceSlice.categories.find((cat) => parseInt(cat.id) === parseInt(category)).name,
              },
            },
          ],
        }),
      );
    }
  };
}

export function updateCategory(data, id) {
  return async (dispatch) => {
    const patch = omitEmptyKeys(data);
    await request.clinic.update_price_category(patch, id);
    await dispatch(getPriceCategories());
  };
}

export function createNewCategory(data) {
  return async (dispatch, getState) => {
    const { common } = getState();
    await request.clinic.add_price_category({ id: common.user.clinic.id }, data);
    dispatch(getPriceCategories());
  };
}

export function searchForPriceItem(queries) {
  return async (dispatch, getState) => {
    const {
      common,
      priceSlice: { categories },
    } = getState();
    await dispatch(setSearching(true));
    const res = await request.clinic.search_price_item({
      ...queries,
      clinic: common.user.clinic.id,
    });

    dispatch(loadPrice(prepPriceData(res.data.items, categories, true)));
  };
}

export function addNewRowFromSearch(category, entryId, position) {
  return async (dispatch, getState) => {
    await dispatch(getPrice());
    const { priceSlice } = getState();
    // eslint-disable-next-line no-prototype-builtins
    if (priceSlice.price.hasOwnProperty(category)) {
      const index = priceSlice.price[category].findIndex((item) => item.id === entryId);
      await dispatch(addRowToPrice(category, index, position));
      await dispatch(setSearching(false));
    }
  };
}
