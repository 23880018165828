import React, { useState } from 'react';
import IMask from 'imask';
import { Row, Code, Name, Value, Edit, EditContainer } from '../styles';
import TextInput from '../../../uikit/TextInput';
import { connect } from 'react-redux';
import { createPriceEntry, getPrice, updatePriceEntry } from '../operations';
import * as R from 'ramda';
import PriceSlice from '../state';
import { OnTopTooltip } from '../../../components/DoctorFilter/OnTopTooltip';
import { RootState } from '../../../store';

const codeMask = IMask.createMask({ mask: /^[A-Za-z0-9]+$/ });
const valueMask = IMask.createMask({ mask: /^\d+?$/ });

function format(str) {
  const chars = str.replaceAll('.', '').split('');
  const strWithPoints = chars.reduce((acc, char, i) => {
    const spaceOrNothing = (i + 1) % 3 === 0 ? '.' : '';
    return acc + char + spaceOrNothing;
  }, '');

  return strWithPoints[strWithPoints.length - 1] === '.' ? strWithPoints.slice(0, -1) : strWithPoints;
}

function NewItemRow({
  item,
  index,
  updatePriceEntry,
  showMenuFor,
  startEditingEntry,
  getPrice,
  createPriceEntry,
  priceSlice,
  onStopEdit,
}) {
  const [error, setError] = useState(false);
  const [code, setCode] = useState(item.code);
  const [title, setTitle] = useState(item.title);
  const [value, setValue] = useState(item.value);

  return (
    <Row key={item.id} colored={!!(index % 2)}>
      <Code>
        <EditContainer>
          <OnTopTooltip
            title={
              <div style={{ display: 'flex', columnGap: '4px' }}>
                <img src="./img/red/attention.svg" />
                {'Код уже существует'}
              </div>
            }
            isShown={error}
            light={true}
            arrow
          >
            <TextInput
              autoFocus
              error={error}
              value={code}
              onChange={(e) => {
                const maskedValue = codeMask.resolve(e.target.value);
                const formattedValue = format(maskedValue);
                const codeArr = R.pluck('code')(priceSlice.response);
                setError(codeArr.filter((code) => code === formattedValue).length > 0);

                if (formattedValue.length <= 30) {
                  setCode(formattedValue);
                }
              }}
              placeholder={item.id === 'new entry' ? '000.000.000' : undefined}
            />
          </OnTopTooltip>
        </EditContainer>
      </Code>
      <Name>
        <EditContainer>
          <TextInput
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder={item.id === 'new entry' ? 'Название позиции' : undefined}
          />
        </EditContainer>
      </Name>
      <Value>
        <EditContainer>
          <TextInput
            value={value}
            onChange={(e) => {
              const maskedValue = valueMask.resolve(e.target.value);

              if (maskedValue.length <= 10) {
                setValue(maskedValue);
              }
            }}
            placeholder={item.id === 'new entry' ? '0' : undefined}
            textAlign={'left'}
          />
        </EditContainer>
      </Value>
      <Edit>
        <img
          onClick={async () => {
            const entries = { code, title, value };

            if (!error) {
              if (item.id === 'new entry') {
                await createPriceEntry(entries, item, index);
              } else {
                await updatePriceEntry(entries, item);
                onStopEdit(item.id);
              }
              showMenuFor(null);
              startEditingEntry(null);
            }
          }}
          style={{ marginRight: 15 }}
          src={'./img/green/check.svg'}
          alt={'save'}
        />
        <img
          onClick={async () => {
            if (item.id === 'new entry') {
              await getPrice();
            } else {
              onStopEdit(item.id);
            }
            showMenuFor(null);
            startEditingEntry(null);
          }}
          src={'./img/black/close.svg'}
          alt={'cancel'}
        />
      </Edit>
    </Row>
  );
}

export default connect((state: RootState) => ({ priceSlice: state.priceSlice }), {
  getPrice,
  createPriceEntry,
  updatePriceEntry,
  showMenuFor: PriceSlice.actions.showMenuFor,
  startEditingEntry: PriceSlice.actions.startEditingEntry,
})(NewItemRow);
