import { DiscountTypeValues, TreatmentPlanItem } from '../../../../Template/TreatmentPlan/TreatmentPlanJournalField';
import { toNumberFormat } from "./to-number-format";

export const getItemDiscount = (item: TreatmentPlanItem) =>
  item.discountType === DiscountTypeValues.RUB ? item.discount : item.count * item.price * item.discount * 0.01;

export const getDiscountSum = (data: TreatmentPlanItem[]) =>
  data.reduce((prev, current) => prev + getItemDiscount(current), 0);

export const readOnlyDiscount = (discount: number, discountType: keyof typeof DiscountTypeValues) =>
    `${toNumberFormat(discount)} ${discountType === DiscountTypeValues.PERCENT ? '%' : 'руб.'}`
