import React, { ChangeEvent, forwardRef, memo, useImperativeHandle, useRef } from 'react';
import TextInput from '../../../../../../uikit/TextInput';
import { getMask } from '../../../../../../utils/masks';
import { Modal } from '../../../../../../uikit/Modal';
import { ValidationMessage } from '../../../../../../uikit/ValidationMessage';

export type AmountInputProps = {
  value: number;
  onChange: (value: number) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  error?: boolean;
  errorMessage?: string;
};

const AmountInputComponent = forwardRef<{ blur: () => void }, AmountInputProps>(
  ({ value, onChange, onBlur, onFocus, errorMessage, error = false }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
      const currentValue = +getMask('count').resolve(`${+e.target.value}`);
      onChange(currentValue);
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          blur: () => {
            inputRef.current.blur();
          },
        };
      },
      [],
    );

    return (
      <>
        <TextInput
          ref={inputRef}
          style={{ height: 24, fontSize: 12, padding: '4px' }}
          value={value}
          error={error}
          onChange={handleChangeValue}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        <Modal
          open={error}
          anchor={inputRef.current}
          placementModal={'bottom-start'}
          unSetContainer={true}
          zIndex={1400}
        >
          <ValidationMessage arrowMarginLeft={16} arrowMarginRight={196}>
            {errorMessage}
          </ValidationMessage>
        </Modal>
      </>
    );
  },
);

AmountInputComponent.displayName = 'AmountInputComponent';

export const AmountInput = memo(AmountInputComponent);

AmountInput.displayName = 'AmountInput';
