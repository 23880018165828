import React, { FC, ReactElement } from 'react';
import Popper from '@mui/material/Popper';
import { PopperPlacementType } from '@mui/base/PopperUnstyled/PopperUnstyled';
import styled from 'styled-components';

import { ROOT_CONTAINER, MODAL } from './constants';

type ModalType = {
  anchor: HTMLElement | null;
  children: ReactElement;
  unSetContainer?: boolean;
  placementModal?: string;
  zIndex?: number;
  flip?: boolean;
  open?: boolean;
};

const PopperContainer = styled(Popper)`
  &[data-popper-reference-hidden] {
    visibility: hidden;
  }
`;

export const Modal: FC<ModalType> = ({
  anchor,
  unSetContainer,
  placementModal,
  zIndex,
  flip = false,
  open,
  children,
}) => {
  const modalRootContainer = document.getElementById(ROOT_CONTAINER);

  return (
    <PopperContainer
      id={MODAL}
      open={open ?? Boolean(anchor)}
      anchorEl={anchor}
      placement={placementModal ? (placementModal as PopperPlacementType) : 'bottom-end'}
      container={!unSetContainer ? modalRootContainer : null}
      style={{ zIndex: zIndex ? zIndex : 100, paddingTop: '4px' }}
      modifiers={[
        {
          name: 'flip',
          enabled: flip,
          options: {
            altBoundary: false,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
    >
      {children}
    </PopperContainer>
  );
};
