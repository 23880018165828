import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { baseURL, HttpMethod } from '../utils/request';
import { ToothMenuType } from '../pages/MedcardPage/Formula/StateMenu';

export enum PaymentTypeCodes {
  CASH = 'CASH',
  CARD = 'CARD',
  BANK = 'BANK',
  PREPAID = 'PREPAID',
  INS_REQ = 'INS_REQ',
  INS_VOL = 'INS_VOL',
  COMBINED = 'COMB',
}

export enum PayerCodes {
  PATIENT = 'PATIENT',
  INDIVIDUAL = 'INDIVIDUAL',
  INSURANCE = 'INSURANCE',
}

export type CabinetDictionaryItem = {
  is_medical: boolean;
  created_at: string;
} & DictionaryType;

export type ToothStateDictionaryItem = {
  id: number | null;
  type?: string;
  mnemonic: string;
  title_russian: string;
  title_latin: string;
  icon_letter: string;
  sort_order: number;
  icon_type_mnemonic: string | null;
  is_editable: boolean;
};

export type DictionaryType = {
  id: number;
  code: string;
  title: string;
  payer_type: string;
};

type WorkDay = {
  id: number;
  time_start: string;
  time_end: string;
  weekday: 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN';
  is_workday: boolean;
};

type Holiday = {
  id: number;
  date: string;
  time_start: string | null;
  time_end: string | null;
  is_workday: boolean;
};

export const dictionaryApi = createApi({
  reducerPath: 'dictionaryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      headers.set('authorization', `Token ${getState().common.access_token}`);
      return headers;
    },
  }),
  tagTypes: ['ToothState', 'Payment', 'Payer', 'Cabinet', 'Workdays', 'Holidays'],
  endpoints: (builder) => ({
    getToothStateDictionary: builder.query<ToothStateDictionaryItem[], ToothMenuType>({
      query: (type) => `dictionary/tooth/states?type=${type}`,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'ToothState', id } as const)), { type: 'ToothState', id: 'LIST' }]
          : [{ type: 'ToothState', id: 'LIST' }],
    }),
    addToothState: builder.mutation<ToothStateDictionaryItem, Partial<ToothStateDictionaryItem>>({
      query: (data) => {
        return {
          url: 'dictionary/tooth/states',
          method: HttpMethod.POST,
          body: data,
        };
      },
      invalidatesTags: [{ type: 'ToothState', id: 'LIST' }],
    }),
    editToothState: builder.mutation<ToothStateDictionaryItem, ToothStateDictionaryItem>({
      query: (data) => {
        return {
          url: `dictionary/tooth/states/${data.id}`,
          method: HttpMethod.PATCH,
          body: data,
        };
      },
      invalidatesTags: [{ type: 'ToothState', id: 'LIST' }],
    }),
    deleteToothState: builder.mutation<ToothStateDictionaryItem, number>({
      query: (id) => {
        return {
          url: `dictionary/tooth/states/${id}`,
          method: HttpMethod.DELETE,
        };
      },
      invalidatesTags: [{ type: 'ToothState', id: 'LIST' }],
    }),

    getPayers: builder.query<DictionaryType[], void>({
      query: () => 'dictionary/payer',
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Payer', id } as const)), { type: 'Payer', id: 'LIST' }]
          : [{ type: 'Payer', id: 'LIST' }],
    }),
    addPaymentType: builder.mutation<DictionaryType, Partial<DictionaryType>>({
      query: (data) => {
        return {
          url: 'dictionary/payment_types',
          method: HttpMethod.POST,
          body: data,
        };
      },
      invalidatesTags: [{ type: 'Payment', id: 'LIST' }],
    }),
    editPaymentType: builder.mutation<DictionaryType, DictionaryType>({
      query: ({ id, title }) => {
        return {
          url: `dictionary/payment_types/${id}`,
          method: HttpMethod.PATCH,
          body: { title },
        };
      },
      invalidatesTags: [{ type: 'Payment', id: 'LIST' }],
    }),

    getPaymentTypes: builder.query<DictionaryType[], void>({
      query: () => 'dictionary/payment_types',
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Payment', id } as const)), { type: 'Payment', id: 'LIST' }]
          : [{ type: 'Payment', id: 'LIST' }],
    }),
    deletePaymentType: builder.mutation<DictionaryType, number>({
      query: (id) => {
        return {
          url: `dictionary/payment_types/${id}`,
          method: HttpMethod.DELETE,
        };
      },
      invalidatesTags: [{ type: 'Payment', id: 'LIST' }],
    }),
    addPayer: builder.mutation<DictionaryType, Partial<DictionaryType>>({
      query: (data) => {
        return {
          url: 'dictionary/payer',
          method: HttpMethod.POST,
          body: data,
        };
      },
      invalidatesTags: [{ type: 'Payer', id: 'LIST' }],
    }),
    editPayer: builder.mutation<DictionaryType, DictionaryType>({
      query: ({ id, title }) => {
        return {
          url: `dictionary/payer/${id}`,
          method: HttpMethod.PATCH,
          body: { title },
        };
      },
      invalidatesTags: [{ type: 'Payer', id: 'LIST' }],
    }),
    deletePayer: builder.mutation<DictionaryType, number>({
      query: (id) => {
        return {
          url: `dictionary/payer/${id}`,
          method: HttpMethod.DELETE,
        };
      },
      invalidatesTags: [{ type: 'Payer', id: 'LIST' }],
    }),

    getCabinetsDictionary: builder.query<CabinetDictionaryItem[], void>({
      query: () => 'dictionary/cabinet',
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Cabinet', id } as const)), { type: 'Cabinet', id: 'LIST' }]
          : [{ type: 'Cabinet', id: 'LIST' }],
    }),
    addCabinet: builder.mutation<CabinetDictionaryItem, Partial<CabinetDictionaryItem>>({
      query: (data) => {
        return {
          url: 'dictionary/cabinet',
          method: HttpMethod.POST,
          body: data,
        };
      },
      invalidatesTags: [{ type: 'Cabinet', id: 'LIST' }],
    }),
    editCabinet: builder.mutation<CabinetDictionaryItem, Partial<CabinetDictionaryItem>>({
      query: ({ id, title }) => {
        return {
          url: `dictionary/cabinet/${id}`,
          method: HttpMethod.PATCH,
          body: { title },
        };
      },
      invalidatesTags: [{ type: 'Cabinet', id: 'LIST' }],
    }),
    deleteCabinet: builder.mutation<CabinetDictionaryItem, number>({
      query: (id) => {
        return {
          url: `dictionary/cabinet/${id}`,
          method: HttpMethod.DELETE,
        };
      },
      invalidatesTags: [{ type: 'Cabinet', id: 'LIST' }],
    }),

    getWorkdays: builder.query<WorkDay[], void>({
      query: () => '/dictionary/workdays',
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Workdays', id } as const)), { type: 'Workdays', id: 'LIST' }]
          : [{ type: 'Workdays', id: 'LIST' }],
    }),
    addWorkday: builder.mutation<WorkDay, Partial<WorkDay>>({
      query: (data) => {
        return {
          url: 'dictionary/workdays',
          method: HttpMethod.POST,
          body: data,
        };
      },
      invalidatesTags: [{ type: 'Workdays', id: 'LIST' }],
    }),
    editWorkday: builder.mutation<WorkDay, Partial<WorkDay>>({
      query: ({ id, time_start, time_end, is_workday }) => {
        return {
          url: `dictionary/workdays/${id}`,
          method: HttpMethod.PATCH,
          body: { time_start, time_end, is_workday },
        };
      },
      invalidatesTags: [{ type: 'Workdays', id: 'LIST' }],
    }),
    deleteWorkday: builder.mutation<WorkDay, number>({
      query: (id) => {
        return {
          url: `dictionary/workdays/${id}`,
          method: HttpMethod.DELETE,
        };
      },
      invalidatesTags: [{ type: 'Workdays', id: 'LIST' }],
    }),

    //Dictionary: holidays
    getHolidays: builder.query<Holiday, void>({
      query: () => 'dictionary/holidays',
      providesTags: [{ type: 'Holidays', id: 'LIST' }],
    }),
    addHoliday: builder.mutation<Holiday, Partial<Holiday>>({
      query: (data) => {
        return {
          url: 'dictionary/holidays',
          method: HttpMethod.POST,
          body: data,
        };
      },
      invalidatesTags: [{ type: 'Holidays', id: 'LIST' }],
    }),
    deleteHoliday: builder.mutation<Holiday, number>({
      query: (id) => {
        return {
          url: `dictionary/holidays/${id}`,
          method: HttpMethod.DELETE,
        };
      },
      invalidatesTags: [{ type: 'Holidays', id: 'LIST' }],
    }),
    editHoliday: builder.mutation<Holiday, Partial<Holiday>>({
      query: ({ id, date, time_start, time_end, is_workday }) => {
        return {
          url: `dictionary/holidays/${id}`,
          method: HttpMethod.PATCH,
          body: { date, time_start, time_end, is_workday },
        };
      },
      invalidatesTags: [{ type: 'Holidays', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetToothStateDictionaryQuery,
  useAddToothStateMutation,
  useEditToothStateMutation,
  useDeleteToothStateMutation,
  useGetPaymentTypesQuery,
  useAddPaymentTypeMutation,
  useEditPaymentTypeMutation,
  useDeletePaymentTypeMutation,
  useGetPayersQuery,
  useAddPayerMutation,
  useEditPayerMutation,
  useDeletePayerMutation,
  useGetCabinetsDictionaryQuery,
  useAddCabinetMutation,
  useEditCabinetMutation,
  useDeleteCabinetMutation,
  useGetWorkdaysQuery,
  useAddWorkdayMutation,
  useEditWorkdayMutation,
  useDeleteWorkdayMutation,
  useGetHolidaysQuery,
  useAddHolidayMutation,
  useDeleteHolidayMutation,
  useEditHolidayMutation,
} = dictionaryApi;
