import styled from 'styled-components';

type HeaderProps = {
  backColor?: string;
};

type InvoiceBlockProps = {
  visitIndex?: number;
};

type ColumnFooterProps = {
  width: number;
  marginLeft?: number;
};

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #253446;
  margin-right: 32px;
`;

export const MainContainer = styled.div`
  padding-right: 7px;
  padding-left: 24px;
  max-width: 1442px;
  @media (min-width: 1460px) {
    margin: 0 auto;
  }
`;

export const InvoiceWrapper = styled.div`
  padding: 12px 0 0 0;
  background: #fff;
  position: relative;
`;

export const SubTitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #7c8590;
  margin-right: 16px;
`;

export const Header = styled.div<HeaderProps>`
  position: relative;
  background: ${({ backColor }) => (backColor ? backColor : '#ebeffe')};
  border-radius: 4px 4px 0 0;
  border: 1px solid transparent;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #253446;
  padding: 12px 56px 12px 12px;
  display: flex;
  &.active {
    border: 1px double #577bf9;
`;

export const Footer = styled.div`
  width: calc(100% - 17px);
  background: #ffffff;
  border: 1px solid #e5e6ea;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #253446;
  padding: 0 12px;
`;

export const ColumnFooter = styled.div<ColumnFooterProps>`
  font-size: 12px;
  font-weight: 500;
  width: ${({ width }) => width}px;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : 24)}px;
`;

export const InvoiceBlock = styled.div<InvoiceBlockProps>`
  border: 1px solid #e5e6ea;
  border-radius: 4px;
  margin-right: 17px;
  overflow: hidden;
  box-sizing: border-box;
  margin-top: ${({ visitIndex }) => (visitIndex !== 0 ? 16 : 0)}px;
`;

export const CommonContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f9f9;
`;
